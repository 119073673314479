import ThermalPrinterPlugin from "thermal-printer-cordova-plugin/www/thermal-printer";
import * as htmlToImage from 'html-to-image';
import { toast } from "react-hot-toast";
import { ORDER_DETAILS_BUTTON } from "common/constant";
import configData from "config";
import { isElectron } from "react-device-detect";
import { checkIfElectronLoaded, checkPrinterJson, isJson, getShopSettingFromLocalStorage } from "utility/helpers";

export const tcpPrinter = (
  ipAddress,
  port,
  printerEndSpaceStr,
  printerModel,
) => {
  ThermalPrinterPlugin.printFormattedTextAndCut(
    {
      type: "tcp",
      address: ipAddress,
      port: port,
      text: billPrintReceipt(printerEndSpaceStr, printerModel),
      id: "tcp-printer-001",
    },
    function () {
      console.log("Successfully printed!");
    },
    function (error) {
      console.error("Printing error", JSON.stringify(error));
    },
  );
};

export const bluetoothPrinter = (
  blutoothDevice,
  printerEndSpaceStr,
  printerModel,
) => {
  ThermalPrinterPlugin.printFormattedText(
    {
      type: "bluetooth",
      id: blutoothDevice, // You can also use the identifier directly i. e. 00:11:22:33:44:55 (address) or name
      text: billPrintReceipt(printerEndSpaceStr, printerModel), // new lines with "\n"
    },
    function () {
      console.log("Successfully printed!");
    },
    function (error) {
      console.error("Printing error", JSON.stringify(error));
    },
  );
};

export const usbPrinter = (usbDevice, printerEndSpaceStr, printerModel) => {
  // Permission granted - We can print!
  ThermalPrinterPlugin.printFormattedTextAndCut(
    {
      type: "usb",
      id: usbDevice, // Use an unique identifier for each printer i. e. address:port or name
      text: billPrintReceipt(printerEndSpaceStr, printerModel), // new lines with "\n"
    },
    function () {
      console.log("Successfully printed!");
    },
    function (error) {
      alert(JSON.stringify(error));
    },
  );
};

export const orderPrintReceipt = (endSpace) => {
  let print = "";
  print += "[C]<font size='big'><b>ServoFeat Demo Print</b></font>\n";
  print +=
    "[C]<font size='normal'>Opp. Vijay Park BRTS, Krishna nagar Ahmedabad - 382350</font>\n\n";
  print += "[L]<font size='normal'><b>Name: </b>Dharmik Bhai</font>\n";
  print +=
    "[L]<font size='normal'><b>Date: </b>25/06/2022, 5:16:22 PM</font>\n";
  print += "[L]<font size='normal'><b>Bill Token: </b>1182 (Parcel)</font>\n";
  print += "[C]--------------------------------\n";
  print +=
    "[C]<font size='normal'><b>Items</b></font>[R]<font size='normal'><b>Qty</b></font>\n";
  print += "[C]<font size='normal'>--------------------------------</font>\n";
  print += "[L]<b>Panner Tikka Masala</b>[R]Rs 130.00\n";
  print += "[L]<b>Margherita Pizza</b>[R]Rs. 244.99\n";
  print += "[C]--------------------------------\n";
  print += "[R]SUB TOTAL :[R]Rs. 374.99\n";
  print += "[R]TAX(5%) :[R]Rs 18.74\n";
  print += "[R]DICSOUNT(%) :[R]Rs. 10.00\n";
  print += "[R]TOTAL :[R]Rs. 358.75\n";
  print += "[C]================================\n";
  print += "[C]<font size='normal'><b>THANK YOU VISIT AGAIN...</b></font>\n";
  Array(parseInt(endSpace) || 1).fill(1).forEach((item, i) => {
    print += "\n"
  });
  return print;
};


const getBase64StringFromDataURL = (dataURL) =>
    dataURL.replace('data:', '').replace(/^.+,/, '');


export const getHTMLImage = () => { 
  var node = document.getElementById('invoice-POS');

  htmlToImage.toJpeg(node,  { quality: 1, cacheBust: true })
  
  .then(function (dataUrl) {
    const base64 = getBase64StringFromDataURL(dataUrl);
    // console.log(base64);
    var link = document.createElement('a');
    link.download = 'my-image-name.jpeg';
    link.href = dataUrl;
    link.click();

    // var img = new Image();
    // img.src = dataUrl;
    // document.body.appendChild(img);
  })
  .catch(function (error) {
    console.error('oops, something went wrong!', error);
  });
}


export const smallBase64 = (data) => {
  return ("[C]<img>"+data+"</img>\n")
}

export const billPrintReceipt = (endSpace, printerModel) => {
  let print = "";
  if (printerModel?.shopName?.trim()) {
    print +=
      "[C]<font size='big'><b>" + printerModel.shopName + "</b></font>\n";
  }
  if (printerModel?.shopAddress?.trim()) {
    print +=
      "[C]<font size='normal'>" + printerModel.shopAddress + "</font>\n\n";
  }
  if (printerModel?.customerName?.trim()) {
    print +=
      "[L]<font size='normal'><b>Name: </b>" +
      printerModel.customerName +
      "</font>\n";
  }
  if (printerModel?.date) {
    print +=
      "[L]<font size='normal'><b>Date: </b>" + printerModel.date + "</font>\n";
  }
  if (printerModel?.orderNo) {
    print +=
      "[L]<font size='normal'><b>OrderNo: </b>" + printerModel?.orderNo + "</font>\n";
  }
  if (printerModel?.orderType) {
    print +=
      "[L]<font size='normal'><b>Type: </b>" + printerModel?.orderType + "</font>\n";
  }
  if (printerModel?.tokenNo) {
    print +=
      "[L]<font size='normal'><b>Token No: </b>" +
      printerModel?.tokenNo +
      "</font>\n";
  }
  if (printerModel?.tableName) {
    print +=
      "[L]<font size='normal'><b>TableNo: </b>" +
      printerModel?.tableName +
      "</font>\n";
  }
  print += "[C]--------------------------------\n";
  print +=
    "[C]<font size='normal'><b>Items</b></font>[R]<font size='normal'><b>Amt.</b></font>\n";
  print += "[C]<font size='normal'>--------------------------------</font>\n";

  printerModel?.printFoodItemArray?.forEach((printFood) => {
    print +=
      "[L]<b>" +
      printFood.foodName +
      " (" +
      printFood.qty +
      ")</b>[R]Rs " +
      printFood.price +
      "\n";
  });
  // print += "[L]<b>AWESOME HAT</b>[R]Rs. 24.99\n";

  print += "[C]--------------------------------\n";
  print += "[R]SUB TOTAL :[R]Rs. " + printerModel.subTotal + "\n";
  if (printerModel?.tax?.trim()) {
    print += "[R]Tax (CGST + SGST): [R]Rs. " + printerModel.tax + "\n";
  }
  if (printerModel?.discount) {
    print += "[R]" + printerModel.discount + "\n";
  }
  if (printerModel?.additionalCharge) {
    print += "[R]" + printerModel?.additionalChargeReason + ": " + printerModel.additionalCharge + "\n";
  }
  print += "[R]TOTAL :[R]Rs. " + printerModel.netTotal + "\n";
  print += "[C]================================\n";
  print += "[C]<font size='normal'><b>THANK YOU VISIT AGAIN...</b></font>\n";
  Array(parseInt(endSpace) || 1).fill(1).forEach((item, i) => {
    print += "\n"
  });
  return print;
};

// Section for KOT print

export const kotPrintReceipt = (endSpace, printerModel) => {
  let print = "";
  if (printerModel?.shopName?.trim()) {
    print +=
      "[C]<font size='big'><b>" + printerModel.shopName + "</b></font>\n";
  }
  if (printerModel?.shopAddress?.trim()) {
    print +=
      "[C]<font size='normal'>" + printerModel.shopAddress + "</font>\n\n";
  }
  if (printerModel?.customerName?.trim()) {
    print +=
      "[L]<font size='normal'><b>Name: </b>" +
      printerModel.customerName +
      "</font>\n";
  }
  if (printerModel.date) {
    print +=
      "[L]<font size='normal'><b>Date: </b>" + printerModel.date + "</font>\n";
  }
  if (printerModel?.orderNo) {
    print +=
      "[L]<font size='normal'><b>Order No: </b>" + printerModel?.orderNo + "</font>\n";
  }
  if (printerModel?.orderType) {
    print +=
      "[L]<font size='normal'><b>Order Type: </b>" + printerModel?.orderType + "</font>\n";
  }
  if (printerModel?.tokenNo) {
    print +=
      "[L]<font size='normal'><b>Token No: </b>" +
      printerModel?.tokenNo +
      "</font>\n";
  }
  if (printerModel?.tableName) {
    print +=
      "[L]<font size='normal'><b>TableNo: </b>" +
      printerModel?.tableName +
      "</font>\n";
  }
  print += "[C]--------------------------------\n";
  print +=
    "[C]<font size='normal'><b>Items</b></font>[R]<font size='normal'><b>Qty</b></font>\n";
  print += "[C]<font size='normal'>--------------------------------</font>\n";

  printerModel?.printFoodItemArray?.forEach((printFood) => {
    print +=
      "[L]" +
      printFood.foodName +
      "[R]" +
      printFood.qty +
      "\n";
  });

  print += "[C]--------------------------------\n";
  Array(parseInt(endSpace) || 1).fill(1).forEach((item, i) => {
    print += "\n"
  });
  
  return print;
};

export const getHtmlTemplate = (printData) => {
  if(!printData)
  {
    return ""
  }

  const currencyCode = printData?.currencyCode ? printData?.currencyCode : '₹'
  return   <>
        <div id="mid">
          <div className="info">
            {printData?.shopName &&  <h2 className="text-center"> {printData?.shopName}</h2>} 
            {printData?.shopAddress && <p className="text-center">   {printData?.shopAddress} </p>}
            {printData?.customerName && <p>Name: {printData?.customerName}</p>}
            {printData?.date &&  <p>Date: {printData?.date}</p>}
            {printData?.orderNo &&  <p>OrderNo: {printData?.orderNo}</p>}
            {printData?.orderType &&  <p>Type: {printData?.orderType}</p>}
            {printData?.tokenNo &&  <p>Token: {printData?.tokenNo}</p>}
            {printData?.tableName &&  <p>Table: {printData?.tableName}</p>}
          </div>
        </div>
        <div id="bot">
          <div id="table">
            <table>
              <tbody>
                <tr className="tabletitle">
                  <td className="item"><h2>Items</h2></td>
                  <td className="rate"><h2>Sub Total</h2></td>
                </tr>
                {
                  printData?.printFoodItemArray?.map(data => 
                    <tr className="service">
                      <td className="tableitem"><p className="itemtext">{data?.foodName} - ({data?.qty})</p></td>
                      <td className="tableitem"><p className="itemtext">{currencyCode} {data?.price}</p></td>
                    </tr>
                    )
                }
              </tbody></table>
              <div className="footer-bill">
                 {printData?.subTotal &&  <p>Sub Total: {} {parseFloat(printData?.subTotal)}</p> }
                 {printData?.tax?.trim()  && (<p>Tax: {currencyCode} {parseFloat(printData?.tax)}</p>)}
                 {printData?.discount && <p> {parseFloat(printData?.discount)}</p> }
                 {printData?.additionalCharge && <p>{printData?.additionalChargeReason}: {currencyCode} {parseFloat(printData?.additionalCharge)}</p> }
                 {printData?.netTotal && <p>Total: {currencyCode} {parseFloat(printData?.netTotal)}</p> }
              </div>
              <div>
                {
                  Array(parseInt(printData?.endSpace) || 1).fill(1).map((item, i) => {
                    return <br></br>
                  })
                }
              </div>
            
          </div>
        </div>
      </>
}

export const getKOTHtmlTemplate = (printData) => {
  if(!printData)
  {
    return ""
  }
  return   <>
        <div id="mid">
          <div className="info">
            {printData?.shopName &&  <h2 className="text-center"> {printData?.shopName}</h2>} 
            {printData?.shopAddress && <p className="text-center">   {printData?.shopAddress} </p>}
            {printData?.customerName && <p>Name: {printData?.customerName}</p>}
            {printData?.date &&  <p>Date: {printData?.date}</p>}
            {printData?.orderNo &&  <p>OrderNo: {printData?.orderNo}</p>}
            {printData?.orderType &&  <p>Type: {printData?.orderType}</p>}
            {printData?.tokenNo &&  <p>Token: {printData?.tokenNo}</p>}
            {printData?.tableName &&  <p>Table: {printData?.tableName}</p>}
          </div>
        </div>
        <div id="bot">
          <div id="table">
            <table>
              <tbody><tr className="tabletitle">
                  <td className="item"><h2>Items</h2></td>
                  <td className="rate"><h2>Qty</h2></td>
                </tr>
                {
                  printData?.printFoodItemArray?.map(data => 
                    <tr className="service">
                      <td className="tableitem"><p className="itemtext">{data?.foodName}</p></td>
                      <td className="tableitem"><p className="itemtext">{data?.qty}</p></td>
                    </tr>
                    )
                }
              </tbody></table>
          </div>
        </div>
        <div>
            {
              Array(parseInt(printData?.endSpace) || 1).fill(1).map((item, i) => {
                return <br></br>
              })
            }
          </div>
      </>
}

export const hexaToPrintCode = (connectedPrinterType, printerId, imageData) => {
  ThermalPrinterPlugin.printFormattedTextAndCut(
        {
          type: connectedPrinterType,
          id: printerId,
          text: imageData,
        },
        function (data) {
          console.log(JSON.stringify(data));
          toast.success("Successfully printed!");
        },
        function (error) {
          toast.error(JSON.stringify(error));
          alert(JSON.stringify(error));
        },
      );
}

export const mobileBillPrintViaImage = async (connectedPrinterType, printerId, printData, base64String) => {

  // const htmlBillFormat = await getHtmlTemplate(printData);
  // html2canvas(htmlBillFormat).then(function(canvas) {
  //   const dataURL = canvas.toDataURL('image/jpeg', 1);
  //   console.log(dataURL);
  //   const base64String = getBase64StringFromDataURL(dataURL);
  //   console.log(base64String);
console.log('%cposPrinterReceipt.js line:340 object', 'color: #007acc;',connectedPrinterType, printerId, base64String);
    if(base64String)
    {
      await ThermalPrinterPlugin.bitmapToHexadecimalString(
        {
          type: connectedPrinterType,
          id: printerId,
          base64: base64String,
          height: ((201 * 5.6 / 2.54)),
          width: 383
        },
        function (data) {
          console.log("Image Data")
          console.log(data);
          hexaToPrintCode(connectedPrinterType, printerId, data);
          console.log(JSON.stringify(data));
          toast.success("Successfully printed!");
        },
        function (error) {
          console.log(JSON.stringify(error))
          console.error("Printing error", JSON.stringify(error));
        },
      )
    }
// });
}

export const usbPrinterForKOT = (usbDevice, printerEndSpaceStr, printerModel) => {
  // Permission granted - We can print!
  ThermalPrinterPlugin.printFormattedTextAndCut(
    {
      type: "usb",
      id: usbDevice, // Use an unique identifier for each printer i. e. address:port or name
      text: kotPrintReceipt(printerEndSpaceStr, printerModel), // new lines with "\n"
    },
    function () {
      console.log("Successfully printed!");
    },
    function (error) {
      alert(JSON.stringify(error));
    },
  );
};

export const bluetoothPrinterForKOT = (
  blutoothDevice,
  printerEndSpaceStr,
  printerModel,
) => {
  ThermalPrinterPlugin.printFormattedText(
    {
      type: "bluetooth",
      id: blutoothDevice, // You can also use the identifier directly i. e. 00:11:22:33:44:55 (address) or name
      text: kotPrintReceipt(printerEndSpaceStr, printerModel), // new lines with "\n"
    },
    function () {
      console.log("Successfully printed!");
    },
    function (error) {
      console.error("Printing error", JSON.stringify(error));
    },
  );
};

export const tcpPrinterForKOT = (
  ipAddress,
  port,
  printerEndSpaceStr,
  printerModel,
) => {
  ThermalPrinterPlugin.printFormattedTextAndCut(
    {
      type: "tcp",
      address: ipAddress,
      port: port,
      text: kotPrintReceipt(printerEndSpaceStr, printerModel),
      id: "tcp-printer-001",
    },
    function () {
      console.log("Successfully printed!");
    },
    function (error) {
      console.error("Printing error", JSON.stringify(error));
    },
  );
};

export const getPrintFormatJSONFromData = (shopSettingsData, billingData, printData, billPrintType, printFoodItemArray) => {

   //let printFoodItemArray = [];
    let isKOT = (billPrintType === ORDER_DETAILS_BUTTON.KOT_ORDER_BUTTON) ? true : false;

    // if (isKOT) {
    //   const printOBJItems = printData?.print_obj?.length > 0 ? printData?.print_obj : printData?.order_meal_items;
    //   printFoodItemArray = printOBJItems?.length > 0 ? printOBJItems?.map(item => {

    //     let foodFullName = "";
    //     let foodItemName = item?.fooditem?.name ? item.fooditem?.name : item?.food_item_id?.label
    //     let foodVariantName = "";
    //     let foodAddOnObj = isJson(item?.addons) ? JSON.parse(item?.addons)?.map(addOn => addOn.name).join(', ') : ""
    //     if (item?.varient_id > 0 && item?.fooditem?.food_variants?.length > 0) {
    //       foodVariantName = item?.fooditem?.food_variants.find(({ id }) => id === item?.varient_id)?.name
    //     }

    //     // foodFullName = `<span style=text-decoration:${item?.kot_print?.type === 'DELETE' ? 'line-through' : ''}>${foodItemName + (foodVariantName ? "( " + foodVariantName + ") " : "") + foodAddOnObj}</span>`;
    //     foodFullName = `${item?.kot_print?.type === 'DELETE' ? '(DELETE)' : ''}${foodItemName + (foodVariantName ? "( " + foodVariantName + ")" : '') + foodAddOnObj}`;
    //     return {
    //       foodName: foodFullName,
    //       qty: item?.kot_print?.qty || item?.qty,
    //       price: `${parseFloat(item?.sub_total).toFixed(2)}`,
    //     }
    //   }) : [];
    // } else {
    //   printFoodItemArray =
    //     printData?.order_meal_items?.length > 0 &&
    //     printData?.order_meal_items?.map((item) => {
    //       let foodFullName = "";
    //       let foodItemName = item?.fooditem?.name
    //         ? item.fooditem?.name
    //         : item?.food_item_id?.label;
    //       let foodVariantName = "";
    //       let foodAddOnObj = isJson(item?.addons)
    //         ? JSON.parse(item?.addons)
    //           ?.map((addOn) => addOn.name)
    //           .join(", ")
    //         : "";
    //       if (item?.varient_id > 0 && item?.fooditem?.food_variants?.length > 0) {
    //         foodVariantName = item?.fooditem?.food_variants.find(
    //           ({ id }) => id === item?.varient_id,
    //         )?.name;
    //       }

    //       foodFullName = `${foodItemName +
    //         (foodVariantName ? "( " + foodVariantName + ") " : "") +
    //         foodAddOnObj
    //         }`;

    //       return {
    //         foodName: foodFullName,
    //         qty: item?.qty,
    //         price: `${parseFloat(item?.sub_total).toFixed(2)}`,
    //       };
    //     });
    // }

    const billing = billingData && billingData.length > 0 ? billingData[0] : {};

    let subTotalWithTax = parseFloat(printData?.sub_total) + parseFloat(printData?.tax);
    let discountInRs = printData?.discount_sign === "%" ? (subTotalWithTax * parseFloat(printData?.discount)) / 100 : parseFloat(printData?.discount);

    const printModal = {
      isKOT: isKOT,
      currencyCode: shopSettingsData?.CURRENCY_CODE,
      endSpace: shopSettingsData?.mobile_printing_setting?.PRINTER_END_SPACE,
      header: shopSettingsData?.printing_setting?.PRINT_BILL_HEADER ? shopSettingsData?.printing_setting?.PRINT_BILL_HEADER : "",
      footer: shopSettingsData?.printing_setting?.PRINT_BILL_FOOTER ? shopSettingsData?.printing_setting?.PRINT_BILL_FOOTER : "",
      printingTemplate: shopSettingsData?.printing_setting?.PRINT_BILL_TEMPLATE,
      logo: billing?.logo ? configData?.S3_BUCKET + billing?.logo : "",
      shopTagLine: billing?.about,
      shopName: billing?.name,
      shopAddress: billing?.address ? billing?.address + "- " + billing?.phone : (billing?.phone ? billing?.phone : ""),
      customerName: printData?.customer ? (printData?.customer?.name + " " + printData?.customer?.phone) : " ",
      date: new Date().toDateString() + " " + new Date().toLocaleTimeString(),
      orderNo: parseInt(printData?.id || 0),
      tokenNo: parseInt(printData?.token_no) ? parseInt(printData?.token_no || printData?.token) : "",
      orderType: " (" + printData?.order_type + ")",
      tableName: printData?.diningtable?.name_of_table || "",
      printFoodItemArray: printFoodItemArray,
      subTotal: parseFloat(printData?.sub_total).toFixed(2),
      tax: parseFloat(printData?.tax) > 0 ? `${parseFloat(printData?.tax).toFixed(2)}` : " ",
      CGST: parseFloat(printData?.tax) / 2,
      SGST: parseFloat(printData?.tax) / 2,
      discount: printData?.discount ? `Discount (${printData?.discount_sign == null || printData?.discount_sign === "INR" ? shopSettingsData?.CURRENCY_CODE
        : printData?.discount_sign + " " + printData?.discount
        }): ${shopSettingsData?.CURRENCY_CODE}` + parseFloat(discountInRs).toFixed(2)
        : "",
      netTotal: parseFloat(printData?.total_bill).toFixed(2),
      additionalCharge: printData?.additional_charge ? `${shopSettingsData?.CURRENCY_CODE} ` +  parseFloat(printData?.additional_charge).toFixed(2) : "",
      additionalChargeReason: printData?.additional_charge_reason || "Additional Charge",
      bill_type: printData?.bill_type
    };

    if(isElectron)
    {
      printModal.shopAddress = (!shopSettingsData?.printing_setting?.PRINT_BILL_HEADER && billingData?.address) ?  printModal.shopAddress : "";
      printModal.shopTagLine = (!shopSettingsData?.printing_setting?.PRINT_BILL_HEADER && billingData?.about) ? printModal?.about : "";
      printModal.shopName = (!shopSettingsData?.printing_setting?.PRINT_BILL_HEADER && billingData?.name) ?  billingData?.name : "";
    }


    return printModal;
}

// TRIGGER FUNCTION TO ELECTRON //

export const printSettleStructureData = (printModal, printerSize) => {
  const currencyCode = printModal?.currencyCode ? printModal?.currencyCode : '₹'

  return [
    // {
    //   type: "image",
    //   path: `https://servofeatproduction.s3.ap-south-1.amazonaws.com/images/J6x8H4TbK-images-(1).jpg`, //printModal?.logo? printModal?.logo : "", // file path
    //   position: "center", // position of image: 'left' | 'center' | 'right'
    //   width: "auto", // width of image in px; default: auto
    //   height: "60px", // width of image in px; default: 50 or '50px'
    // },
    {
      type: "text", // 'text' | 'barCode' | 'qrCode' | 'image' | 'table
      value: printModal?.header,
      style: `text-align:center;font-family: 'Calibri', serif;`,
      css: { "font-weight": "700", "font-size": printerSize > 180 ? "18px" : "15px"},
    },
    {
      type: "text", // 'text' | 'barCode' | 'qrCode' | 'image' | 'table
      value: printModal?.shopName,
      style: `text-align:center;font-family: 'Calibri', serif;`,
      css: { "font-weight": "700", "font-size": printerSize > 180 ? "18px" : "15px"},
    },
    {
      type: "text", // 'text' | 'barCode' | 'qrCode' | 'image' | 'table
      value: printModal?.shopTagLine,
      style: `text-align:center;font-family: 'Calibri', serif; font-style: italic`,
      css: { "font-weight": "500", "font-size": printerSize > 180 ? "16px" : "13px"},
    },
    {
      type: "text", // 'text' | 'barCode' | 'qrCode' | 'image' | 'table
      value: printModal?.shopAddress,
      style: `text-align:center;border-bottom: 1px solid black;font-family: 'Calibri', serif;`,
      css: { "font-size": printerSize > 180 ? "15px" : "12px" },
    },
    {
      type: "text", // 'text' | 'barCode' | 'qrCode' | 'image' | 'table
      value: `Name : ${printModal?.customerName}`,
      style: `text-align:left;font-family: 'Calibri', serif;`,
      css: { "font-size": printerSize > 180 ? "15px" : "12px" },
    },
    {
      type: "text", // 'text' | 'barCode' | 'qrCode' | 'image' | 'table
      value: `Date : ${printModal?.date}`,
      style: `text-align:left;font-family: 'Calibri', serif;`,
      css: { "font-size": printerSize > 180 ? "15px" : "12px" },
    },
    {
      type: "text", // 'text' | 'barCode' | 'qrCode' | 'image' | 'table
      value:  `Token No: ${printModal?.tokenNo + "  " + printModal?.orderType}`,
      style: `text-align:center; font-weight:900;font-family: 'Calibri', serif;`,
      css: { "font-size": printerSize > 180 ? "15px" : "12px" },
    },
    {
      type: "text", // 'text' | 'barCode' | 'qrCode' | 'image' | 'table
      value:  `Order No: ${printModal?.orderNo}`,
      style: `text-align:center; font-weight:900;font-family: 'Calibri', serif;`,
      css: { "font-size": printerSize > 180 ? "15px" : "12px" },
    },
    {
      type: "text", // 'text' | 'barCode' | 'qrCode' | 'image' | 'table
      value:  printModal?.tableName ? `Table No: ${printModal?.tableName}` : '',
      style: `text-align:center; font-weight:900;font-family: 'Calibri', serif;`,
      css: { "font-size": printerSize > 180 ? "15px" : "12px" },
    },
    {
      type: "table",
      style: "border: 1px solid #ddd;font-family: 'Calibri', serif;", // style the table
      // list of the columns to be rendered in the table header
      tableHeader: [
        { type: "text", value: "ITEM" },
        { type: "text", value: "QTY" },
        { type: "text", value: "AMT" },
      ],
      // multi dimensional array depicting the rows and columns of the table body
      tableBody: printModal?.printFoodItemArray?.length > 0 &&
      printModal?.printFoodItemArray?.flatMap((item, index) => {
        const addOns = item?.addOns || []; 
        const baseStyle = `text-align:left;font-family: 'Calibri', serif;`;
        const textSize = { "font-size": printerSize > 180 ? "15px" : "12px" };

        let rows = [
          [
            {
              type: "text",
              value: item?.foodName,
              style: baseStyle,
              css: textSize
            },
            {
              type: "text",
              value: item?.qty,
              style: `text-align:center;font-family: 'Calibri', serif;`,
              css: textSize
            },
            {
              type: "text",
              value: item?.price,
              style: `text-align:center;font-family: 'Calibri', serif;`,
              css: textSize
            }
          ]
        ];

        addOns.forEach((addOne, addIndex) => {
          rows.push([
            {
              type: "text",
              value: `${addOne?.name} (AddOn)`,
              style: baseStyle,
              css: textSize
            },
            {
              type: "text",
              value: "-",
              style: `text-align:center;font-family: 'Calibri', serif;`,
              css: textSize
            },
            {
              type: "text",
              value: addOne?.price,
              style: `text-align:center;font-family: 'Calibri', serif; ${addIndex === addOns.length - 1 ? "border-bottom: 1px solid black;" : ""}`,
              css: textSize
            }
          ]);
        });

        if (addOns.length === 0) {
          const lastColumnIndex = rows[0].length - 1;
          if (rows[0][lastColumnIndex]) {
            rows[0][lastColumnIndex].style += "border-bottom: 1px solid black;";
          }
        }

        return rows;
      }),


      // list of columns to be rendered in the table footer
      // tableFooter: [{ type: "text", value: "Total Amount" }],
      // custom style for the table header
      tableHeaderStyle: "color: #000; font-weight: 'bold'; font-family: 'Calibri', serif;",
      // custom style for the table body
      tableBodyStyle: "border: 0.5px solid #ddd;font-family: 'Calibri', serif;",
      // custom style for the table footer
      // tableFooterStyle: "background-color: #000; color: white;",
    },
    {
      type: "text", // 'text' | 'barCode' | 'qrCode' | 'image' | 'table
      value: `Sub Total: ${currencyCode} ${printModal?.subTotal}`,
      style: `text-align:right;font-family: 'Calibri', serif;`,
      css: { "font-size": printerSize > 180 ? "15px" : "12px" },
    },
    {
      type: "text", // 'text' | 'barCode' | 'qrCode' | 'image' | 'table
      value: `<div>Tax: ${currencyCode} ${printModal?.tax}</div>`,
      style: `text-align:right;font-family: 'Calibri', serif;`,
      css: { "font-size": printerSize > 180 ? "13px" : "12px" },
    },
    {
      type: "text", // 'text' | 'barCode' | 'qrCode' | 'image' | 'table
      value: printModal?.discount,
      style: `text-align:right;font-family: 'Calibri', serif;`,
      css: { "font-size": printerSize > 180 ? "13px" : "12px" }
    },
    {
      type: "text", // 'text' | 'barCode' | 'qrCode' | 'image' | 'table
      value: printModal?.additionalCharge ? printModal?.additionalChargeReason + ": " + printModal?.additionalCharge : "",
      style: `text-align:right;font-family: 'Calibri', serif;`,
      css: { "font-size": printerSize > 180 ? "13px" : "12px" }
    },
    {
      type: "text", // 'text' | 'barCode' | 'qrCode' | 'image' | 'table
      value: `Payment Method: ${printModal?.bill_type ? printModal?.bill_type : ''}`,
      style: `text-align:right;font-family: 'Calibri', serif;`,
      css: { "font-size": printerSize > 180 ? "13px" : "12px" }
    },
    {
      type: "text", // 'text' | 'barCode' | 'qrCode' | 'image' | 'table
      value: `Net Total : ${currencyCode} ${printModal?.netTotal}`,
      style: `text-align:right;background-color: #eee;padding: 2px 4px;font-family: 'Calibri', serif;`,
      css: { "font-size": printerSize > 180 ? "16px" : "13px"},
    },
    {
      type: "text", // 'text' | 'barCode' | 'qrCode' | 'image' | 'table
      value: printModal?.footer,
      style: `text-align:center;border-top: 1px solid black;margin-bottom: 2px;font-family: 'Calibri', serif;`,
      css: { "font-size": printerSize > 180 ? "15px" : "12px" },
    },
  ];
};

export const kotPrintStructureData = (printModal, printerSize) => {
  console.log(printModal, "kotPrintStructureData")
  return [
    {
      type: "text", // 'text' | 'barCode' | 'qrCode' | 'image' | 'table
      value: printModal?.shopName,
      style: `text-align:center;font-family: 'Calibri', serif;`,
      css: { "font-weight": "700", "font-size":printerSize > 180 ? "18px" : "15px" },
    },
    {
      type: "text", // 'text' | 'barCode' | 'qrCode' | 'image' | 'table
      value: `Name : ${printModal?.customerName}`,
      style: `text-align:left;font-family: 'Calibri', serif;`,
      css: { "font-size": printerSize > 180 ? "15px" : "12px" },
    },
    {
      type: "text", // 'text' | 'barCode' | 'qrCode' | 'image' | 'table
      value: `Date : ${printModal?.date}`,
      style: `text-align:left;font-family: 'Calibri', serif;`,
      css: { "font-size":printerSize > 180 ? "15px" : "12px" },
    },
    {
      type: "text", // 'text' | 'barCode' | 'qrCode' | 'image' | 'table
      value:  `Token No: ${printModal?.tokenNo + "  " + printModal?.orderType}`,
      style: `text-align:center; font-weight:900;font-family: 'Calibri', serif;`,
      css: { "font-size": printerSize > 180 ? "15px" : "12px" },
    },
    {
      type: "text", // 'text' | 'barCode' | 'qrCode' | 'image' | 'table
      value:  `Order No: ${printModal?.orderNo}`,
      style: `text-align:center; font-weight:900;font-family: 'Calibri', serif;`,
      css: { "font-size": printerSize > 180 ? "15px" : "12px" },
    },
    {
      type: "text", // 'text' | 'barCode' | 'qrCode' | 'image' | 'table
      value:  printModal?.tableName ? `Table No: ${printModal?.tableName}` : '',
      style: `text-align:center; font-weight:900;font-family: 'Calibri', serif;`,
      css: { "font-size": printerSize > 180 ? "15px" : "12px" },
    },
    {
      type: "table",
      style: "border: 1px solid #ddd;font-family: 'Calibri', serif;width: 100%", // style the table
      // list of the columns to be rendered in the table header
      tableHeader: [
        { type: "text", value: "ITEM" },
        { type: "text", value: "QTY" },
      ],
      // multi dimensional array depicting the rows and columns of the table body
      tableBody: printModal?.printFoodItemArray?.length > 0 &&
      printModal?.printFoodItemArray?.flatMap((item, index) => {
        const addOns = item?.addOns || []; 
        const baseStyle = `text-align:left;font-family: 'Calibri', serif;`;
        const textSize = { "font-size": printerSize > 180 ? "15px" : "12px" };

        let rows = [
          [
            {
              type: "text",
              value: item?.foodName,
              style: baseStyle,
              css: textSize
            },
            {
              type: "text",
              value: item?.qty,
              style: `text-align:center;font-family: 'Calibri', serif;`,
              css: textSize
            }
          ]
        ];

        addOns.forEach((addOne) => {
          rows.push([
            {
              type: "text",
              value: `${addOne?.name} (AddOn)`,
              style: baseStyle,
              css: textSize
            }
          ]);
        });

        rows[rows.length - 1][0].style += "border-bottom: 1px solid black;";

        return rows;
      }),

      // list of columns to be rendered in the table footer
      // tableFooter: [{ type: "text", value: "Total Amount" }],
      // custom style for the table header
      tableHeaderStyle: "color: #000; font-weight: 'bold'; color: #000;font-family: 'Calibri', serif;",
      // custom style for the table body
      tableBodyStyle: "border: 0.5px solid #ddd;font-family: 'Calibri', serif;",
      // custom style for the table footer
      // tableFooterStyle: "background-color: #000; color: white;",
    }
  ];
};


export const triggerPrintToElectron = async (printType, printModal, shopSettingsData) => {

  let billPrinterName = shopSettingsData?.printing_setting?.PRINTER_NAME || checkPrinterJson(getShopSettingFromLocalStorage("printing_setting"))?.PRINTER_NAME;
  let billPrinterSize = shopSettingsData?.printing_setting?.PRINTER_SIZE || checkPrinterJson(getShopSettingFromLocalStorage("printing_setting"))?.PRINTER_SIZE;
  let kotPrinterName = shopSettingsData?.printing_setting?.KITCHEN_PRINTER_NAME || checkPrinterJson(getShopSettingFromLocalStorage("printing_setting"))?.KITCHEN_PRINTER_NAME || billPrinterName;
  let kotPrinterSize = shopSettingsData?.printing_setting?.KITCHEN_PRINTER_SIZE || checkPrinterJson(getShopSettingFromLocalStorage("printing_setting"))?.KITCHEN_PRINTER_SIZE || billPrinterSize;

  let billFormatStructure = printSettleStructureData(printModal, billPrinterSize);
  let kotBillFormat = kotPrintStructureData(printModal, kotPrinterSize);

  if (typeof window?.printData != "undefined" && isElectron) {

    if(printType === ORDER_DETAILS_BUTTON.BILL_ORDER_BUTTON) {
      await window?.printData(
          billPrinterName,
          billPrinterSize ? billPrinterSize + "px" : "200px",
          billFormatStructure,
          shopSettingsData?.printing_setting
        );  
    }

    if(printType === ORDER_DETAILS_BUTTON.KOT_ORDER_BUTTON || printType === ORDER_DETAILS_BUTTON.BILL_AND_KOT_TOGETHER_PRINT)
      {
        window?.printData(
            kotPrinterName,
            kotPrinterSize ? kotPrinterSize + "px" : "200px",
            kotBillFormat,
            shopSettingsData?.printing_setting
        );
      }
      return "successfully trigger"
  }
  else {
    return "printer main not loaded properly, please rebuilt it"
  }

}