import React, { Fragment, useEffect, useState, useRef, useCallback } from "react";
import { styled } from '@mui/material/styles';
import moment from "moment";
import MaterialTable, { MTableToolbar } from "material-table";
import jsPDF from "jspdf";
import InvoiceAnalytic from "./InvoiceAnalytic";
import { toast } from "react-hot-toast";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import html2canvas from "html2canvas";
import configData from "config";
import PageLoaderBlock from "components/PageLoaderBlock";
import {
  usbPrinter,
  bluetoothPrinter,
  tcpPrinter,
  usbPrinterForKOT,
  bluetoothPrinterForKOT,
  mobileBillPrintViaImage,
  tcpPrinterForKOT,
  getKOTHtmlTemplate,
  getHtmlTemplate
} from "utility/posPrinterReceipt";
import {
  Chip,
  IconButton,
  TextField,
  Tab,
  MenuItem,
  Tabs,
  Divider,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
  Stack,
  FormControl,
  Button,
  Card
} from "@mui/material";

import {
  ViewList,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Visibility as VisibilityIcon,
  FilterList as FilterListIcon,
  Delete,
  Cancel,
  CachedSharp,
  Done,
} from "@mui/icons-material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPrint } from "@fortawesome/free-solid-svg-icons";
import { updateSettingData } from "services/setting.service";
import { useTheme } from "@mui/material/styles";
import "assets/css/order.css";
import "./order.scss";
// API Calls
import {
  deleteOrderById,
  settleAllOrder,
} from "services/order.service";
import OrderViewPopup from "./OrderViewPopup";
import AddEditOrderHistory from "./AddEditOrderHistory";
import TextInputMui from "components/TextInputMui";
import NotFoundComponent from "components/NotFoundComponent";
import noFoundImg from "../../assets/img/no-data-found.jpg";

import SkeletonLoader from "components/Loader/SkeletonLoader";
import { DeskTopContainer } from "themes/Device";
import { MobileContainer } from "themes/Device";
// import MobileTitle from "components/MobileTitle";
import SearchBarInput from "components/SearchBarInput";
import MobileSalesHistory from "components/MobileSalesHistory";
import { useSelector, useDispatch } from "react-redux";
import RoleBasedGuard from "guards/RoleBasedGuard";
import { RESOURCE, PRINTER_TYPE, ORDER_DETAILS_BUTTON, ACTION  } from "common/constant";
import PopUpModel from "components/Popup/PopUpModel";
import Label from "components/Label";
import useTabs from "hooks/useTabs";
import Scrollbar from "components/Scrollbar";
//context
import {
  useLayoutDispatch,
  addCurrentScreenTitle,
} from "context/LayoutContext";
import Swal from "sweetalert2";
import Iconify from "components/Iconify";
import { onlineOrderAccepted } from "services/order.service";
import { onlineOrderDecline } from "services/order.service";
import { refreshShopToken } from "services/shop.service";
import { fetchOrderHistoryList, fetchShopSettingInfo } from "state/thunk-async";
import { useTranslation } from "react-i18next";
import { getOrderDateBasedOnTimeZone } from "utility/helpers";
import CommonOrderItemSummeryModel from "./CommonOrderItemSummeryModel";

const PREFIX = 'OrderHistory';
const INPUT_WIDTH = 250;

const classes = {
  mobileviewdate: `${PREFIX}-mobileviewdate`,
  customerHeaderAccordion: `${PREFIX}-customerHeaderAccordion`,
  searchBarInput: `${PREFIX}-searchBarInput`,
  cardTitleWhite: `${PREFIX}-cardTitleWhite`,
  root: `${PREFIX}-root`,
  tabel: `${PREFIX}-tabel`,
  searchfilterSection: `${PREFIX}-searchfilterSection`,
  mobileCard: `${PREFIX}-mobileCard`,
  searchBarSection: `${PREFIX}-searchBarSection`,
  sdateStyle: `${PREFIX}-sdateStyle`,
  edateStyle: `${PREFIX}-edateStyle`,
  searchBox: `${PREFIX}-searchBox`,
  parentDiv: `${PREFIX}-parentDiv`,
  dineinbadge: `${PREFIX}-dineinbadge`,
  dinintable: `${PREFIX}-dinintable`,
  parcelbadge: `${PREFIX}-parcelbadge`,
  orderTypeMobileCard: `${PREFIX}-orderTypeMobileCard`,
  errorStyle: `${PREFIX}-errorStyle`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.mobileviewdate}`]: {
    display: "flex",
    padding: "6px 0px 16px",
  },
  

  [`& .${classes.customerHeaderAccordion}`]: {
    backgroundColor: "#e8deec",
    borderRadius: "9px",
    borderColor: "#d7d7d7 !important",
    borderWidth: "1px",
    boxShadow: "0px !important",
    display: "flex",
    width: "10%",
    marginLeft: "auto!important",
    height: "40px",
    minHeight: "40px !important",
    "& .MuiAccordionSummary-content": {
      margin: "0px",
    },
    "& .MuiAccordionSummary-root.Mui-expanded": {
      height: "45px",
    },
  },

  [`& .${classes.searchBarInput}`]: {
    border: "unset!important",
    height: "2.8rem!important",
  },

  [`& .${classes.cardTitleWhite}`]: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    // fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontFamily: '"Oxygen", sans-serif',
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },

  [`& .${classes.root}`]: {
    width: "100%",
  },

  [`& .${classes.tabel}`]: {
    "& tr": {
      "& .MuiTableCell-root": {
        padding: "10px",
        fontSize: "14px !important",
      },
    },
    "& th": {
      height: 50,
      "& .MuiTableCell-root": {
        padding: "0px",
        fontSize: "14px !important",
      },
    },
  },

  [`& .${classes.searchfilterSection}`]: {
    position: "relative",
  },

  [`& .${classes.mobileCard}`]: {
    // padding: "5px",
    marginTop: '5px'
  },

  [`& .${classes.searchBarSection}`]: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "92%",
  },

  [`& .${classes.sdateStyle}`]: {
    [theme.breakpoints.down('sm')]: {
      padding: "10px 0px",
      display: "flex",
    },

    [theme.breakpoints.down('md')]: {
      padding: "10px 0px",
      display: "flex",
    },

    [theme.breakpoints.up("sm")]: {
      padding: "10px 0px",
      display: "flex",
    },
  },

  [`& .${classes.edateStyle}`]: {
    [theme.breakpoints.down('sm')]: {
      
    },
    [theme.breakpoints.down('md')]: {
      width: "82%"
    }
  },

  [`& .${classes.searchBox}`]: {
    [theme.breakpoints.up('md')]: {
      width: "400px"
    },
    [theme.breakpoints.down('md')]: {
      flexWrap: 'wrap'
    }
  },

  [`& .${classes.parentDiv}`]: {
    display: "flex",
    justifyContent: "start !important",
    [theme.breakpoints.up("sm")]: {
      display: "flex",
      justifyContent: "start",
    },
    [theme.breakpoints.down('md')]: {
      width:'100%',
      display: "flex",
      flexWrap: 'wrap'
    },
  },

  [`& .${classes.dineinbadge}`]: {
    display: "flex",
  },

  [`& .${classes.dinintable}`]: {
    marginRight: "5px",
    fontSize: "inherit",
  },

  [`& .${classes.parcelbadge}`]: {
    fontSize: "inherit",
  },

  [`& .${classes.orderTypeMobileCard}`]: {
    flex: "1 0 auto",
    marginLeft: "5px",
  },

  [`& .${classes.errorStyle}`]: {
    color: "red"
  }
}));

const ORDER_FILTERS = {
  ALL: "ALL",
  IN_PROGRESS: "IN_PROGRESS",
  DINE_IN: "DINE_IN",
  PARCEL: "PARCEL",
  ONLINE: "ONLINE",
  COMPLETED: "COMPLETED",
};

const OrderHistory = (props) => {

  const dispatch = useDispatch();
  const {
    currentTab: filterStatus,
    onChangeTab: onFilterStatus,
    setCurrentTab: setCustomCurrentTab,
  } = useTabs("IN_PROGRESS");
  const { t } = useTranslation();
  const [orderHistoryDataList, setOrderHistoryDataList] = useState([]);
  const [orderHistory, setOrderHistory] = useState([]);
  const [orderSummeryModelShow, setOrderSummeryModelShow] = useState(false);
  const [orderViewData, setOrderViewData] = useState({});
  const [dataLoading, setDataLoading] = useState(false);
  const [deletePopupshow, setDeletePopupshow] = useState(false);
  const [deleteId, setDeleteId] = useState(0);
  const theme = useTheme();
  const [searchHistory, setSearchHistory] = useState("");
  const [filterHistory, setFilterHistory] = useState([]);
  // const [selectValue, setSelectValue] = useState("");
  const [activeFilterNoDataMessage, setActiveFilterNoDataMessage] = useState([]);
  const [addEditOrderHistoryModelShow, setAddEditOrderHistoryModelShow] =
    useState(false);
  const [addEditOrderHistoryId, setAddEditOrderHistoryId] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [activeFilterType, setActiveFilterType] = useState(
    ORDER_FILTERS.IN_PROGRESS,
  );

  const shopSettingListData = useSelector(
    (state) => state.shopSettingReducer.shopSettingData,
  );
  const [isDineInEnabled, setIsDineInEnabled] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [count, setCount] = useState([]);
  const [validationData, setValidationData] = useState({});
  const layoutDispatch = useLayoutDispatch();
  const [isOrderTokenTimeUpdating, setIsOrderTokenTimeUpdating] = useState(false);
  const [selectValue, setSelectValue] = useState("TODAY");
  const [initialReportDate, setInitialReportDate] = useState({
    startDate: moment().format("YYYY-MM-DD"),
    endDate: moment().add(1, "days").format("YYYY-MM-DD"),
  });

  const countDisplay = (type) => {
    if (count && count.length > 0) {
      const allCount = count.find((countItem) => countItem.type === type);
      return allCount;
    }
    return 0;
  };

  const orderHistoryDataListSelector = useSelector((state) => state?.api?.orderHistoryData);

  const TABS = [
    {
      value: "IN_PROGRESS",
      label: t("sales-history-in-progress-label"),
      color: "success",
      count: countDisplay("INPROGRESS")?.count || 0,
    },
    {
      value: "DINE_IN",
      label: t("dine-in-label"),
      color: "warning",
      count: countDisplay("DINE_IN")?.count || 0,
    },
    {
      value: "PARCEL",
      label: t("sales-history-parcel-label"),
      color: "error",
      count: countDisplay("PARCEL")?.count || 0,
    },
    {
      value: "ONLINE",
      label: t("sales-history-online-label"),
      color: "secondary",
      count: countDisplay("ONLINE")?.count || 0,
    },
    {
      value: "COMPLETED",
      label: t("sales-history-completed-label"),
      color: "info",
      count: countDisplay("COMPLETED")?.count || 0,
    },
    {
      value: "ALL",
      label: t("all-label"),
      color: "info",
      count: countDisplay("ALL")?.count || 0,
    },
  ];

  useEffect(() => {
    addCurrentScreenTitle(layoutDispatch, {
      currentScreenTitle: t("order-history-title"),
    });
    return () => {
      addCurrentScreenTitle(layoutDispatch, { currentScreenTitle: "" });
    };
  }, []);

  useEffect(() => {
    if (shopSettingListData?.DINE_IN_SERVICE) {
      setIsDineInEnabled(true);
    }
  }, [shopSettingListData]);


  useEffect(() => {
    if (shopSettingListData?.ORDER_TOKEN_RESET_TIME && !parseInt(shopSettingListData?.DAY_VALUE_FOR_CUSTOM_DATE_FILTER)) {
      const currentDate = getOrderDateBasedOnTimeZone(shopSettingListData?.ORDER_TOKEN_RESET_TIME);
      const orderDateBody = {
        startDate: currentDate, //  "endDate": "2021-03-30",
        endDate: moment(currentDate).add(1, "days").format("YYYY-MM-DD") // "startDate": "YYYY-MM-DD"
      };
      setInitialReportDate(orderDateBody)
    }
  }, [shopSettingListData?.ORDER_TOKEN_RESET_TIME])


  useEffect(() => {
    if (parseInt(shopSettingListData?.DAY_VALUE_FOR_CUSTOM_DATE_FILTER) < 0) {
      const orderDateBody = {
        startDate: moment().subtract(parseInt(shopSettingListData?.DAY_VALUE_FOR_CUSTOM_DATE_FILTER), "days").format("YYYY-MM-DD"),
        endDate: moment().add(1, "days").format("YYYY-MM-DD")
      }
      setInitialReportDate(orderDateBody)
    }
    else if(parseInt(shopSettingListData?.DAY_VALUE_FOR_CUSTOM_DATE_FILTER) > 0)
    {
      const orderDateBody = {
        startDate: moment().add(parseInt(shopSettingListData?.DAY_VALUE_FOR_CUSTOM_DATE_FILTER), "days").format("YYYY-MM-DD"),
        endDate: moment().add(parseInt(shopSettingListData?.DAY_VALUE_FOR_CUSTOM_DATE_FILTER) + 1, "days").format("YYYY-MM-DD")
      }
      setInitialReportDate(orderDateBody)
    }
  }, [shopSettingListData?.DAY_VALUE_FOR_CUSTOM_DATE_FILTER])

  useEffect(() => {
    if (moment(initialReportDate?.startDate).format("YYYY-MM-DD") > moment(initialReportDate?.endDate).format("YYYY-MM-DD")) {
      const tempData = { ...validationData };
      tempData['startDate'] = 'From date should be less than the To date.'
      setValidationData(tempData);
    } else {
      const tempData = { ...validationData };
      tempData['startDate'] = ''
      setValidationData(tempData);
      getAllOrderHistoryList();
    }
  }, [initialReportDate]);

  const getAllOrderHistoryList = async () => {
    setDataLoading(true);
    console.log('%cOrderHistory.js line:436 initialReportDate', 'color: #007acc;', initialReportDate);
    await dispatch(fetchOrderHistoryList(initialReportDate));
    setDataLoading(false);
  };

  useEffect(() => {
    if (orderHistoryDataListSelector) {
      setCount(orderHistoryDataListSelector?.counts || 0);
      const dataList = orderHistoryDataListSelector?.data?.filter((order) => !order?.is_delete);
      if (dataList) {
        setOrderHistory(dataList);
      }
      else {
        setOrderHistory([]);
      }
    }
    setDataLoading(false);

  }, [orderHistoryDataListSelector])


  const generateInvoicePdf = () => {
    var doc = new jsPDF("p", "pt");

    doc.text(20, 20, "This is the first title.");

    doc.addFont("helvetica", "normal");
    doc.text(20, 60, "This is the second title.");
    doc.text(20, 100, "This is the thrid title.");

    // doc.autoPrint();
    doc.autoPrint({ variant: "non-conform" });
    doc.output("dataurlnewwindow");
    // doc.save('demo.pdf')
  };

  const FilterButtonClick = (type) => {
    let orderHistorydata;
    switch (type) {
      case ORDER_FILTERS.ALL:
        orderHistorydata = orderHistory;
        setActiveFilterType(ORDER_FILTERS.ALL);
        setActiveFilterNoDataMessage("No order's available in given date range");
        setCustomCurrentTab(ORDER_FILTERS.ALL);
        break;
      case ORDER_FILTERS.IN_PROGRESS:
        orderHistorydata = orderHistory.filter((item) => !item?.is_complete && (item?.is_online_accepted !== "DECLINE"));
        setActiveFilterType(ORDER_FILTERS.IN_PROGRESS);
        setActiveFilterNoDataMessage("No orders are in progress.");
        setCustomCurrentTab(ORDER_FILTERS.IN_PROGRESS);
        break;
      case ORDER_FILTERS.DINE_IN:
        orderHistorydata = orderHistory.filter(
          (item) => item?.order_type === "DINE_IN",
        );
        setActiveFilterType(ORDER_FILTERS.DINE_IN);
        setActiveFilterNoDataMessage("No orders are in Dine In.");
        setCustomCurrentTab(ORDER_FILTERS.DINE_IN);
        break;
      case ORDER_FILTERS.PARCEL:
        orderHistorydata = orderHistory.filter(
          (item) => item?.order_type === "PARCEL",
        );
        setActiveFilterType(ORDER_FILTERS.PARCEL);
        setActiveFilterNoDataMessage("No orders are in Parcel.");
        setCustomCurrentTab(ORDER_FILTERS.PARCEL);
        break;
      case ORDER_FILTERS.COMPLETED:
        orderHistorydata = orderHistory.filter((item) => item?.is_complete);
        setActiveFilterType(ORDER_FILTERS.COMPLETED);
        setActiveFilterNoDataMessage("No orders are in Completed.");
        setCustomCurrentTab(ORDER_FILTERS.COMPLETED);
        break;
      case ORDER_FILTERS.ONLINE:
        orderHistorydata = orderHistory.filter((item) => item?.is_online || item?.order_type === "SWIGGY" || item?.order_type === "ZOMATO" || item?.order_type === "QR_DINEIN" || item?.order_type === "QR_SHOP");
        setActiveFilterType(ORDER_FILTERS.ONLINE);
        setActiveFilterNoDataMessage("No orders are in Online.");
        setCustomCurrentTab(ORDER_FILTERS.ONLINE);
        break;
      default:
        orderHistorydata = orderHistory;
        break;
    }
    setOrderHistoryDataList(orderHistorydata);
  };

  useEffect(() => {
    FilterButtonClick(filterStatus);
  }, [filterStatus, orderHistory]);

  const deleteOrder = () => {
    if (deleteId > 0) {
      setIsDeleting(true);
      deleteOrderById(deleteId)
        .then((res) => {
          setIsDeleting(false);
          setDeletePopupshow(false);
          if (res?.data) {
            toast.success(res?.message);
          } else {
            toast.success(res?.message);
          }
          getAllOrderHistoryList();
        })
        .catch((err) => {
          setIsDeleting(false);
          setDeletePopupshow(false);
          toast.success(err?.message);
        });
    }
  };

  const deleteDataConfirm = (id) => {
    setDeleteId(id);
    setDeletePopupshow(true);
  };

  const acceptOnlineOrder = (orderID) => {
    try {
      onlineOrderAccepted(orderID)
        .then((res) => {
          if (res.status == 200) {
            toast.success(res?.message);
          }
          else {
            toast.error(res?.message);
          }
          dispatch(fetchOrderHistoryList(initialReportDate));
        })
        .catch((err) => {
          toast.error(err?.message);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const declineOnlineOrder = (orderID) => {
    try {
      onlineOrderDecline(orderID)
        .then((res) => {
          if (res.status == 200) {
            toast.success(res?.message);
          }
          else {
            toast.error(res?.message);
          }
          dispatch(fetchOrderHistoryList(initialReportDate));
        })
        .catch((err) => {
          toast.error(err?.message);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const deleteData = (deleteid) => {
    setDeleteId(deleteid);
    setDeletePopupshow(true);
  };

  const printData = () => generateInvoicePdf();
  const editData = (editid) => {
    setAddEditOrderHistoryId(editid);
    setAddEditOrderHistoryModelShow(true);
  };

  const viewData = (orderDetail) => {
    setOrderViewData(orderDetail);
    setOrderSummeryModelShow(true);
  };

  const hidePreviewPopup = () => {
    setOrderViewData({});
    setOrderSummeryModelShow(false);
  }

  useEffect(() => {
    setFilterHistory(orderHistoryDataList);
  }, [orderHistoryDataList]);

  useEffect(() => {
    if (searchHistory) {
      let filterHistoryData = orderHistoryDataList.filter(
        (item) =>
          item.name &&
          item.name?.toLowerCase()?.includes(searchHistory.toLowerCase()),
      );
      setFilterHistory(filterHistoryData);
    } else {
      setFilterHistory(orderHistoryDataList);
    }
  }, [searchHistory]);

  const getLengthByStatus = (status) =>
    tableData.filter((item) => item.status === status).length;

 
  const getPercentByStatus = (status) =>
    (getLengthByStatus(status) / tableData.length) * 100;

  const settleAllOrderClick = () => {
    Swal.fire({
      title: t("swal-title"),
      text: t("order-history-swal-text"),
      icon: "warning",
      cancelButtonText: t("popup-cancel-button-label"),
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: t("swal-order-settle-confirmation-text"),
    }).then((result) => {
      if (result?.isConfirmed) {
        let object = {
          startDate: moment(initialReportDate?.startDate).format("YYYY-MM-DD"), //  "endDate": "2021-03-30",
          endDate: moment(initialReportDate?.endDate).format("YYYY-MM-DD"),
        };
        settleAllOrder(object)
          .then((res) => {
            if (res?.message) {
              toast.success(res?.message);
            }
            getAllOrderHistoryList();
          })
          .catch((err) => {
            console.log(err);
            toast.error(err);
            getAllOrderHistoryList();
          });
      }
    });
  };

  const startCounterAtCurrentTime = async () => {

    Swal.fire({
      title: "Start Token",
      text: "By starting the counter order Token No will start from 1.",
      icon: "info",
      cancelButtonText: t("popup-cancel-button-label"),
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes do it!",
    }).then(async (result) => {
      if (result?.isConfirmed) {
        delete shopSettingListData.CURRENCY_CODE;
        const updatedValues = {
          ...shopSettingListData,
          ORDER_TOKEN_RESET_TIME : moment().format("HH:mm")
        }

        setIsOrderTokenTimeUpdating(true);
        const shopId = localStorage.getItem("shopId");
        const result = await updateSettingData(updatedValues, shopId);
        if (result.status === 200) {
          setIsOrderTokenTimeUpdating(false);
          dispatch(fetchShopSettingInfo());
          toast.success(result.data.message);
        } else if (result.status === 400) {
          setIsOrderTokenTimeUpdating(false);
          toast.error(result?.message);
        }
        setRefreshShopToken();
       
      }
    });
  }

  const setRefreshShopToken = async () => {
    const bodyPayload = {
      username: localStorage.getItem("username"),
    };
    const result = await refreshShopToken(bodyPayload);
    if (result.statusCode === 200) {
      localStorage.setItem("token", result?.data?.token);
    } else {
      toast.error(result?.message);
    }
  };

  const handleReportByInterval = (event) => {
    var intervalPeriod = event?.target?.value;
    setSelectValue(intervalPeriod);
    if (intervalPeriod === "WEEKLY") {
      let initialInterval = {
        startDate: moment().startOf("week").format("YYYY-MM-DD"),
        endDate: moment().format("YYYY-MM-DD"),
      };
      setInitialReportDate(initialInterval);
    } else if (intervalPeriod === "MONTHLY") {
      let initialInterval = {
        startDate: moment().startOf("month").format("YYYY-MM-DD"),
        endDate: moment().format("YYYY-MM-DD"),
      };
      setInitialReportDate(initialInterval);
    } else if (intervalPeriod === "YEARLY") {
      let initialInterval = {
        startDate: moment().startOf("year").format("YYYY-MM-DD"),
        endDate: moment().format("YYYY-MM-DD"),
      };
      setInitialReportDate(initialInterval);
    } else if (intervalPeriod === "YESTERDAY") {
      let initialInterval = {
        startDate: moment().subtract(1, "days").format("YYYY-MM-DD"),
        endDate: moment().format("YYYY-MM-DD"),
      };
      setInitialReportDate(initialInterval);
    } else {
      let initialInterval = {
        startDate: moment().format("YYYY-MM-DD"),
        endDate: moment().add(1, "days").format("YYYY-MM-DD"),
      };
      setInitialReportDate(initialInterval);
    }
  };

  const startEndDatePicker = () => {
    return (
      <div style={{ padding: "0px" }}>
        <div className={classes.parentDiv}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
            <Stack spacing={2} direction={{ xs: 'column', md: 'row' }} sx={{ py: 2.5, px: 3}}>
            <TextInputMui
              select
              sx={{
                width: '250px',
                textTransform: 'capitalize',
              }}
              label={t("calendar-wise-label")}
              variant="outlined"
              labelId="demo-simple-select-label"
              value={selectValue}
              onChange={handleReportByInterval}
            >
              <MenuItem value="TODAY">
                {t("analytics-chart-today-label")}
              </MenuItem>
              <MenuItem value="YESTERDAY">
                {t("analytics-chart-yesterday-label")}
              </MenuItem>
              <MenuItem value="WEEKLY">
                {t("analytics-current-week")}
              </MenuItem>
              <MenuItem value="MONTHLY">
                {t("analytics-current-month")}
              </MenuItem>
              <MenuItem value="YEARLY">
                {t("analytics-current-year")}
              </MenuItem>
            </TextInputMui>
              <Stack  sx={{
                textTransform: 'capitalize',
              }}>
                <Stack fullWidth spacing={2} direction={'row'} className={classes.searchBox}>
                    <DatePicker
                      label={t("order-history-start-date")}
                      value={initialReportDate?.startDate}
                      onChange={(value) => setInitialReportDate({
                        ...initialReportDate,
                          startDate: moment(value).format("YYYY-MM-DD"),
                        })}
                      inputFormat = "DD/MM/YYYY"
                      renderInput={(params) => (
                        <TextInputMui
                              variant="outlined"
                              {...params}
                              fullWidth
                              sx={{
                                marginLeft: '16px',
                                maxWidth: { md: INPUT_WIDTH },
                              }}
                              // defaultValue={initialReportDate?.endDate}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                      )}
                    />
                          
                    <DatePicker
                      label={t("order-history-end-date")}
                      onChange={(value) => setInitialReportDate({
                        ...initialReportDate,
                        endDate: moment(value).format("YYYY-MM-DD")
                        })}
                        inputFormat = "DD/MM/YYYY"
                        value={initialReportDate?.endDate}
                      // defaultValue={initialReportDate?.startDate}
                      renderInput={(params) => (
                        <TextInputMui
                              {...params}
                              fullWidth
                              sx={{
                                maxWidth: { md: INPUT_WIDTH },
                              }}
                            />
                      )}
                    />
                </Stack>
                <Stack className={`${classes.errorStyle}`}>
                {validationData && validationData?.startDate}
              </Stack>
              </Stack>
          </Stack>
          </LocalizationProvider>
        </div>
      </div>
    );
  };

  const filterButtons = () => {
    return (
      <>
        <DeskTopContainer>
        <div className="analytics-order">
          <Scrollbar>
            <Stack
              direction="row"
              divider={
                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{ borderStyle: "dashed" }}
                />
              }
              sx={{ py: 1 }}
            >
              <InvoiceAnalytic
                shopSettingListData={shopSettingListData}
                title={t("order-history-total-label")}
                total={countDisplay("ALL")?.count || 0}
                percent={100}
                price={countDisplay("ALL")?.sum || 0}
                icon="ic:round-receipt"
                color={theme.palette.info.main}
                onClick={() => FilterButtonClick(ORDER_FILTERS.ALL)}
              />
              <InvoiceAnalytic
                shopSettingListData={shopSettingListData}
                title={t("sales-history-in-progress-label")}
                total={countDisplay("INPROGRESS")?.count || 0}
                percent={getPercentByStatus("paid")}
                price={countDisplay("INPROGRESS")?.sum || 0}
                icon="eva:clock-fill"
                color={theme.palette.warning.main}
                onClick={() => FilterButtonClick(ORDER_FILTERS.IN_PROGRESS)}
              />
              <InvoiceAnalytic
                shopSettingListData={shopSettingListData}
                title={t("dine-in-label")}
                total={countDisplay("DINE_IN")?.count || 0}
                percent={getPercentByStatus("unpaid")}
                price={countDisplay("DINE_IN")?.sum || 0}
                icon="ic:sharp-table-restaurant"
                color={theme.palette.text.secondary}
                onClick={() => FilterButtonClick(ORDER_FILTERS.DINE_IN)}
              />
              <InvoiceAnalytic
                shopSettingListData={shopSettingListData}
                title={t("sales-history-parcel-label")}
                total={countDisplay("PARCEL")?.count || 0}
                percent={getPercentByStatus("overdue")}
                price={countDisplay("PARCEL")?.sum || 0}
                icon="logos:parcel-icon"
                color={theme.palette.error.main}
                onClick={() => FilterButtonClick(ORDER_FILTERS.PARCEL)}
              />
              <InvoiceAnalytic
                shopSettingListData={shopSettingListData}
                title={t("sales-history-online-label")}
                total={countDisplay("ONLINE")?.count || 0}
                percent={getPercentByStatus("overdue")}
                price={countDisplay("ONLINE")?.sum || 0}
                icon="ri:takeaway-fill"
                color={theme.palette.info.dark}
                onClick={() => FilterButtonClick(ORDER_FILTERS.ONLINE)}
              />
              <InvoiceAnalytic
                shopSettingListData={shopSettingListData}
                title={t("sales-history-completed-label")}
                total={countDisplay("COMPLETED")?.count || 0}
                percent={getPercentByStatus("draft")}
                price={countDisplay("COMPLETED")?.sum || 0}
                icon="eva:checkmark-circle-2-fill"
                color={theme.palette.success.main}
                onClick={() => FilterButtonClick(ORDER_FILTERS.COMPLETED)}
              />
            </Stack>
          </Scrollbar>
        </div>
          <Tabs
            allowScrollButtonsMobile
            variant="scrollable"
            scrollButtons="auto"
            value={filterStatus}
            onChange={onFilterStatus}
            sx={{
              px: 2,
              bgcolor: theme.palette.grey[200],
            }}
          >
            {TABS.map((tab) => (
              <Tab
                disableRipple
                key={tab.value}
                value={tab.value}
                icon={
                  <Label color={tab.color} sx={{ marginRight: "8px" }}>
                    {tab.count}{" "}
                  </Label>
                }
                label={tab.label}
                sx={{
                  px: 2,
                  flexDirection: "row",
                  color: theme.palette.grey[600],
                  fontWeight: "600",
                  textTransform: "none",
                  minHeight: "48px",
                  marginRight: "30px",
                }}
              />
            ))}
          </Tabs>

          <Divider />
        </DeskTopContainer>
        <MobileContainer>
          <div className="history-filter-button">
            <Stack className={classes.orderTypeMobileCard}>
              <Button
                variant="outlined"
                className={`order-history-filter-btn w-100`}
                style={{
                  padding: "6px 30px",
                  fontSize: "15px",
                  textTransform: 'none',
                  color:
                    activeFilterType === ORDER_FILTERS.ALL
                      ? theme.palette.secondary.contrastText
                      : "rgb(99, 115, 129)",
                  backgroundColor:
                    activeFilterType === ORDER_FILTERS.ALL
                      ? theme.palette.primary.main
                      : "",
                }}
                onClick={() => FilterButtonClick(ORDER_FILTERS.ALL)}
              >
                <div className="d-flex flex-column align-items-center">
                    <div>
                      <Label color={'info'} 
                      sx={{ marginRight: "8px", color:
                        activeFilterType === ORDER_FILTERS.ALL
                          ? theme.palette.secondary.contrastText
                          : "rgb(99, 115, 129)",
                          backgroundColor: activeFilterType === ORDER_FILTERS.ALL
                            ? theme.palette.primary.dark
                            : '', }}>
                        {countDisplay("ALL")?.count || 0}
                      </Label>
                      ALL
                    </div>
                   <div>{shopSettingListData?.CURRENCY_CODE} {countDisplay("ALL")?.sum || 0}</div>
                </div>
                
              </Button>
            </Stack>
            <Stack className={classes.orderTypeMobileCard}>
              <Button
                variant="outlined"
                className={`order-history-filter-btn w-100`}
                onClick={() => FilterButtonClick(ORDER_FILTERS.IN_PROGRESS)}
                style={{
                  padding: "6px 30px",
                  fontSize: "15px",
                  textTransform: 'none',
                  color:
                    activeFilterType === ORDER_FILTERS.IN_PROGRESS
                      ? theme.palette.secondary.contrastText
                      : "rgb(99, 115, 129)",
                  backgroundColor:
                    activeFilterType === ORDER_FILTERS.IN_PROGRESS
                      ? theme.palette.primary.main
                      : "",
                }}
              >
                 <div className="d-flex flex-column align-items-center">
                    <div>
                      <Label color={'primary'} 
                      sx={{ marginRight: "8px", color:
                        activeFilterType === ORDER_FILTERS.IN_PROGRESS
                          ? theme.palette.secondary.contrastText
                          : "rgb(99, 115, 129)",
                          backgroundColor: activeFilterType === ORDER_FILTERS.IN_PROGRESS
                            ? theme.palette.primary.dark
                            : '', }}>
                        {countDisplay("INPROGRESS")?.count || 0}
                      </Label>
                      {t("sales-history-in-progress-label")}
                    </div>
                   <div>{shopSettingListData?.CURRENCY_CODE} {countDisplay("INPROGRESS")?.sum || 0}</div>
                </div>
              </Button>
            </Stack>
            <Stack className={classes.orderTypeMobileCard}>
              <Button
                variant="outlined"
                className={`order-history-filter-btn w-100`}
                onClick={() => FilterButtonClick(ORDER_FILTERS.PARCEL)}
                style={{
                  padding: "6px 30px",
                  fontSize: "15px",
                  textTransform: 'none',
                  color:
                    activeFilterType === ORDER_FILTERS.PARCEL
                      ? theme.palette.secondary.contrastText
                      : "rgb(99, 115, 129)",
                  backgroundColor:
                    activeFilterType === ORDER_FILTERS.PARCEL
                      ? theme.palette.primary.main
                      : "",
                }}
              >
                <div className="d-flex flex-column align-items-center">
                    <div>
                      <Label color={'error'} 
                      sx={{ marginRight: "8px", color:
                        activeFilterType === ORDER_FILTERS.PARCEL
                          ? theme.palette.secondary.contrastText
                          : "rgb(99, 115, 129)",
                          backgroundColor: activeFilterType === ORDER_FILTERS.PARCEL
                            ? theme.palette.primary.dark
                            : '', }}>
                        {countDisplay("PARCEL")?.count || 0}
                      </Label>
                      {t("sales-history-parcel-label")}
                    </div>
                   <div>{shopSettingListData?.CURRENCY_CODE} {countDisplay("PARCEL")?.sum || 0}</div>
                </div>
                
              </Button>
            </Stack>
            {isDineInEnabled && (
              <Stack className={classes.orderTypeMobileCard}>
                <Button
                  variant="outlined"
                  className={`order-history-filter-btn w-100`}
                  style={{
                    padding: "6px 30px",
                    fontSize: "15px",
                    textTransform: 'none',
                    color:
                      activeFilterType === ORDER_FILTERS.DINE_IN
                        ? theme.palette.secondary.contrastText
                        : "rgb(99, 115, 129)",
                    backgroundColor:
                      activeFilterType === ORDER_FILTERS.DINE_IN
                        ? theme.palette.primary.main
                        : "",
                  }}
                  onClick={() => FilterButtonClick(ORDER_FILTERS.DINE_IN)}
                >
                  <div className="d-flex flex-column align-items-center">
                    <div>
                      <Label color={'warning'} 
                      sx={{ marginRight: "8px", color:
                        activeFilterType === ORDER_FILTERS.DINE_IN
                          ? theme.palette.secondary.contrastText
                          : "rgb(99, 115, 129)",
                          backgroundColor: activeFilterType === ORDER_FILTERS.DINE_IN
                            ? theme.palette.primary.dark
                            : '', }}>
                        {countDisplay("DINE_IN")?.count || 0}
                      </Label>
                      {t("dine-in-label")}
                    </div>
                   <div>{shopSettingListData?.CURRENCY_CODE} {countDisplay("DINE_IN")?.sum || 0}</div>
                </div>
                </Button>
                
              </Stack>
            )}
            <Stack className={classes.orderTypeMobileCard}>
              <Button
                variant="outlined"
                className={`order-history-filter-btn w-100`}
                onClick={() => FilterButtonClick(ORDER_FILTERS.ONLINE)}
                style={{
                  padding: "6px 30px",
                  fontSize: "15px",
                  textTransform: 'none',
                  minWidth: "80px",
                  color:
                    activeFilterType === ORDER_FILTERS.ONLINE
                      ? theme.palette.secondary.contrastText
                      : "rgb(99, 115, 129)",
                  backgroundColor:
                    activeFilterType === ORDER_FILTERS.ONLINE
                      ? theme.palette.primary.main
                      : "",
                }}
              >
                <div className="d-flex flex-column align-items-center">
                    <div>
                      <Label color={'secondary'} 
                      sx={{ marginRight: "8px", color:
                        activeFilterType === ORDER_FILTERS.ONLINE
                          ? theme.palette.secondary.contrastText
                          : "rgb(99, 115, 129)",
                          backgroundColor: activeFilterType === ORDER_FILTERS.ONLINE
                            ? theme.palette.primary.dark
                            : '', }}>
                        {countDisplay("ONLINE")?.count || 0}
                      </Label>
                      {t("sales-history-online-label")}
                    </div>
                   <div>{shopSettingListData?.CURRENCY_CODE} {countDisplay("ONLINE")?.sum || 0}</div>
                </div>
              </Button>
            </Stack>
            <Stack className={classes.orderTypeMobileCard}>
              <Button
                variant="outlined"
                className={`order-history-filter-btn w-100`}
                onClick={() => FilterButtonClick(ORDER_FILTERS.COMPLETED)}
                style={{
                  padding: "6px 30px",
                  fontSize: "15px",
                  textTransform: 'none',
                  minWidth: "80px",
                  color:
                    activeFilterType === ORDER_FILTERS.COMPLETED
                      ? theme.palette.secondary.contrastText
                      : "rgb(99, 115, 129)",
                  backgroundColor:
                    activeFilterType === ORDER_FILTERS.COMPLETED
                      ? theme.palette.primary.main
                      : "",
                }}
              >
                 <div className="d-flex flex-column align-items-center">
                    <div>
                      <Label color={'info'} 
                      sx={{ marginRight: "8px", color:
                        activeFilterType === ORDER_FILTERS.COMPLETED
                          ? theme.palette.secondary.contrastText
                          : "rgb(99, 115, 129)",
                          backgroundColor: activeFilterType === ORDER_FILTERS.COMPLETED
                            ? theme.palette.primary.dark
                            : '', }}>
                        {countDisplay("COMPLETED")?.count || 0}
                      </Label>
                      {t("sales-history-completed-label")}
                    </div>
                   <div>{shopSettingListData?.CURRENCY_CODE} {countDisplay("COMPLETED")?.sum || 0}</div>
                </div>
              </Button>
            </Stack>
          </div>
        </MobileContainer>
        </>
    );
  };

  return <Root>
     <PageLoaderBlock 
          processing={dataLoading}
          message="Please wait, we are processing order">
          <PopUpModel
            show={deletePopupshow}
            heading={t("order-history-order-delete-popup-message")}
            body={t("order-history-order-delete-popup-body")}
            footer={
              <>
                <Button
                  variant="contained"
                  color="primary"
                  className="mr-3"
                  onClick={() => setDeletePopupshow(false)}
                >
                  <Cancel /> {t("popup-cancel-button-label")}
                </Button>
                <Button variant="contained" color="secondary" onClick={deleteOrder}>
                  <Delete /> {isDeleting ? <CircularProgress /> : t("delete")}
                </Button>
              </>
            }
            onHide={() => {
              setDeletePopupshow(false);
            }}
          />

          <AddEditOrderHistory
            getAllOrderHistoryList={getAllOrderHistoryList}
            show={addEditOrderHistoryModelShow}
            heading={"Edit Order"}
            addEditOrderHistoryId={addEditOrderHistoryId}
            onHide={() => setAddEditOrderHistoryModelShow(false)}
          />

          <DeskTopContainer>
            <div className={classes.tabel}>
              <MaterialTable
                title={""}
                columns={[
                  {
                    title: "Token",
                    field: "token_no",
                    cellStyle: {
                      width: "5%",
                      textAlign: "center",
                    },
                    defaultSort: "desc",
                    render: (rowData) => (
                      <Label
                        variant={
                          theme.palette.mode === "light" ? "ghost" : "filled"
                        }
                        color={"info"}
                        style={{ fontSize: "20px!important" }}
                      >
                        {rowData?.token_no}
                      </Label>
                    ),
                  },
                  {
                    title: t("order-number-label"),
                    field: "orderNo",
                    cellStyle: {
                      width: "8%",
                    },
                    defaultSort: "desc",
                  },
                  {
                    title: t("sales-report-order-type"),
                    field: "diningTable",
                    cellStyle: {
                      width: "8%",
                    },
                  },
                  {
                    title: "Created By",
                    field: "created_by",
                    cellStyle: {
                      width: "13%",
                    }
                  },
                  {
                    title: t("created-date-label"),
                    field: "createdDate",
                    cellStyle: {
                      width: "13%",
                    },
                    render: (rowData) =>
                      moment(rowData?.createdDate).format("DD/MM/YYYY hh:mm A"),
                  },
                  {
                    title: "Updated Date",
                    field: "updatedDate",
                    cellStyle: {
                      width: "13%",
                    },
                    render: (rowData) =>
                      moment(rowData?.updatedDate).format("DD/MM/YYYY hh:mm A"),
                  },
                  {
                    title: "Customer",
                    field: "customerName",
                    cellStyle: {
                      width: "8%",
                      wordBreak: "break-all"
                    },
                  },
                  {
                    title: "Payment",
                    field: "bill_type",
                    cellStyle: {
                      width: "8%",
                    },
                  },
                  {
                    title: t("order-history-total-bill-label"),
                    field: "totalBill",
                    cellStyle: {
                      width: "8%",
                    },
                  },
                  {
                    title: t("action"),
                    field: "orderDetail",
                    cellStyle: {
                      width: "18%",
                    },
                    render: (rowData) => (
                      <>
                        { (
                          <RoleBasedGuard
                            resource={RESOURCE.SALES}
                            action={ACTION.VIEW}
                          >
                            <IconButton
                              color="primary"
                              title={t("order-history-preview-order-label")}
                              aria-label={t("order-history-preview-order-label")}
                              onClick={() => {
                                rowData?.orderDetail?.order_type === "PARCEL"
                                  ? props.history.push({
                                    pathname:
                                      rowData?.id === 0 || rowData?.id == null
                                        ? `/app/order/parcel/`
                                        : `/app/order/parcel/${rowData?.id}`,
                                    tableId: "PARCEL",
                                  })
                                  : props.history.push({
                                    pathname:
                                      rowData?.id === 0 || rowData?.id == null
                                        ? `/app/dine-in/order/`
                                        : `/app/dine-in/order/${rowData?.id}`,
                                    tableId: rowData?.tableId,
                                  });
                              }}
                              size="large">
                              <VisibilityIcon />
                            </IconButton>
                          </RoleBasedGuard>
                        )}
                      <RoleBasedGuard
                        resource={RESOURCE.SALES}
                        action={ACTION.SETTLE}
                      >
                          <IconButton
                            color="secondary"
                              title={"Settle Order"}
                              aria-label={"Settle Order"}
                              onClick={() => {
                                setOrderViewData(rowData.orderDetail);
                                setOrderSummeryModelShow(true);
                              }}
                            size="large">
                            {
                              rowData?.isCompleted ? <ViewList />  :   <Done /> 
                            }
                          </IconButton>
                        </RoleBasedGuard>
                        <RoleBasedGuard
                          resource={RESOURCE.SALES}
                          action={ACTION.DELETE}
                        >
                          <IconButton
                            color="secondary"
                            title={t("order-history-delete-order-label")}
                            aria-label={t("order-history-delete-order-label")}
                            onClick={() => deleteDataConfirm(rowData?.id)}
                            size="large">
                              <DeleteIcon />
                            </IconButton>
                        </RoleBasedGuard>
                        {rowData?.is_online && (
                          rowData?.is_online_accepted === "CREATED" ?
                            <Stack direction={"row"} gap={1} style={{ marginTop: '10px' }}>
                              <RoleBasedGuard
                                resource={RESOURCE.ONLINE_ORDER}
                                action={ACTION.UPDATE}
                              >
                              <Button
                                size="small" onClick={() => acceptOnlineOrder(rowData?.id)} sx={{ textTransform: 'initial' }} variant="contained" endIcon={<Iconify icon={'eva:checkmark-circle-2-fill'} />}>
                                Accept
                                </Button>
                              </RoleBasedGuard>
                              <RoleBasedGuard
                                resource={RESOURCE.ONLINE_ORDER}
                                action={ACTION.UPDATE}
                              >
                                <Button
                                size="small" onClick={() => declineOnlineOrder(rowData?.id)} sx={{ textTransform: 'initial' }} variant="contained" color="error" endIcon={<Iconify icon={'eva:close-circle-fill'} />}>
                                Decline
                              </Button>
                              </RoleBasedGuard>
                            </Stack>
                            :
                            <Stack direction={"row"} gap={1} style={{ marginTop: '10px' }}>
                              <Label
                                variant={
                                  theme.palette.mode === "light" ? "ghost" : "filled"
                                }
                                color={"error"}
                              >
                                {rowData?.is_online_accepted}
                              </Label>
                            </Stack>)
                        }
                      </>
                    ),
                  },
                ]}
                data={
                  orderHistoryDataList &&
                  orderHistoryDataList
                    .sort(function (a, b) {
                      return parseInt(a?.order_id) < parseInt(b?.order_id);
                    })
                    .map((item, index) => {
                      return {
                        orderNo: item?.order_id + " (" + item?.id + ") ",
                        orderName: item?.diningtable
                          ? item?.diningtable?.name_of_table
                          : "Parcel Order",
                        tableId: item?.diningtable ? item?.diningtable?.id : 0,
                        diningTable: item?.diningtable ? (
                          <>
                            <div className={classes.dineinbadge}>
                              <Label
                                variant={
                                  theme.palette.mode === "light"
                                    ? "ghost"
                                    : "filled"
                                }
                                color={"info"}
                              >
                                {item?.order_type === "DINE_IN"
                                  ? "Dine-In"
                                  : item?.order_type}
                              </Label>{" "}
                              &nbsp;&nbsp;
                              <span>{item?.diningtable?.name_of_table}</span>
                            </div>
                          </>
                        ) : (
                          <Label
                            variant={
                              theme.palette.mode === "light" ? "ghost" : "filled"
                            }
                            color={"warning"}
                          >
                            {item?.order_type}
                          </Label>
                        ),
                        customerName: item?.name_of_customer || "-",
                        phoneNo: item?.phone,
                        created_by: item?.created_by || "-",
                        createdDate: item?.created_date || "-",
                        updatedDate: item?.updated_date || "-",
                        bill_type: item?.bill_type || "-",
                        totalBill: `${shopSettingListData?.CURRENCY_CODE} ` + item?.total_bill,
                        id: item.id,
                        is_online_accepted: item?.is_online_accepted,
                        is_online: item?.is_online,
                        isCompleted: item?.is_complete,
                        orderDetail: item,
                        token_no: item?.token_no,
                      };
                    })
                }
                localization={{
                  toolbar: {
                    searchPlaceholder: t("search"),
                  },
                  body: {
                    emptyDataSourceMessage: dataLoading ? (
                      <SkeletonLoader />
                    ) : (
                      <NotFoundComponent imgUrl={noFoundImg}>
                        <Label color="warning" sx={{ fontSize: '15px' }}>
                          {
                            activeFilterNoDataMessage
                          }
                        </Label>
                      </NotFoundComponent>
                    ),
                  },
                }}
                options={{
                  paging: true,
                  pageSize: 100,
                  emptyRowsWhenPaging: false, //to make page size fix in case of less data rows
                  pageSizeOptions: [10, 20, 30, 50, 100],
                  sorting: true,
                  searchFieldAlignment: "left",
                  searchFieldStyle: {
                    color: theme.palette.grey[500],
                    borderColor: "rgba(0, 0, 0, 0.23)",
                    border: "0.8px solid",
                    borderRadius: "6px",
                    padding: "11px",
                    "&:before": {
                      borderBottom: "0px!important",
                    },
                    "&:after": {
                      borderBottom: "0px!important",
                    },
                  },
                  headerStyle: {
                    color: theme.palette.grey[600],
                    backgroundColor: theme.palette.grey[200],
                  },
                  actionsColumnIndex: -1
                }}
                components={{
                  Toolbar: (props) => (
                    <>
                      <div className="d-flex align-items-center justify-content-between">
                        <h5>
                          <b>{t("sales-history-route-title")}</b>
                        </h5>
                        <div>
                          <h5 style={{color: 'rgba(0, 0, 0, 0.6)'}}>Your daily counter start time is &nbsp; <b>{shopSettingListData?.ORDER_TOKEN_RESET_TIME}</b></h5>
                        </div>
                        <div>
                        {/* <Button
                          variant="contained"
                          color="info"
                          className="mr-2"
                          onClick={startCounterAtCurrentTime}
                        >
                          {
                            isOrderTokenTimeUpdating ? "..." : "Start Token"
                          }
                        </Button> */}
                        <Button
                          variant="contained"
                          color="warning"
                          // disabled={!orderHistory.some((item) => !item?.is_complete && (item?.is_online_accepted !== "DECLINE"))}
                          onClick={settleAllOrderClick}
                        >
                          {"Settle All"}
                        </Button>
                        <IconButton
                          title={"Sync Order"}
                          color="primary"
                          arial-label={"Referesh"}
                          style={{ transform: dataLoading ? 'rotate(180deg)' : ''}}
                          onClick={getAllOrderHistoryList}
                          size="large">
                          {
                            dataLoading ?
                              <CachedSharp  style={{ transform: dataLoading ? 'rotate(180deg)' : ''}}/>
                              :  <CachedSharp/>
                          }
                        </IconButton>
                        </div>
                      
                      </div>

                      {filterButtons()}
                      {/* <Label color="info"> You are getting an order between {moment(startDate).utc().format("YYYY-MM-DD")} - {moment(endDate).utc().format("YYYY-MM-DD")}</Label> */}
                      <div className="custom-material-table-toolbar">
                        <MTableToolbar {...props} />
                        <div>
                        {startEndDatePicker()}
                        </div>
                      </div>
                
                    </>
                  ),
                }}
              />
            </div>
          </DeskTopContainer>
          <MobileContainer>
            <div className={classes.mobileCard}>
            <div className="d-flex align-items-center justify-content-between">
                        <div>
                          <Button
                            variant="contained"
                            color="warning"
                            onClick={settleAllOrderClick}
                          >
                            {"settle All"}
                          </Button>
                        </div>
                        <div>
                          <IconButton
                            title={"Sync Order"}
                            color="primary"
                            arial-label={"Referesh"}
                            style={{ transform: dataLoading ? 'rotate(180deg)' : ''}}
                            onClick={getAllOrderHistoryList}
                            size="large">
                            {
                              dataLoading ?
                                <CachedSharp  style={{ transform: dataLoading ? 'rotate(180deg)' : ''}}/>
                                :  <CachedSharp/>
                            }
                          </IconButton>
                        </div>
                      </div>
              <div className={classes.searchfilterSection}>
                <Accordion defaultExpanded={false}>
                  <AccordionSummary
                    expandIcon={<FilterListIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    className={classes.customerHeaderAccordion}
                  ></AccordionSummary>
                  <AccordionDetails className={classes.mobileviewdate}>
                    {startEndDatePicker()}
                  </AccordionDetails>
                </Accordion>
                <div className={classes.searchBarSection}>
                  <SearchBarInput
                    onChange={(e) => {
                      setSearchHistory(e.target.value);
                    }}
                    clearTxt={() => setSearchHistory("")}
                    className={classes.searchBarInput}
                    value={searchHistory}
                    placeholder={t("order-history-search-order-history-label")}
                  />
                </div>
              </div>
              {filterButtons()}
              {dataLoading ? (
                <SkeletonLoader count={3} height="10rem" />
              ) : (
                <div>
                  {filterHistory &&
                    filterHistory.map((item, index) => (
                      <div className={classes.mobileCard} key={index}>
                        <MobileSalesHistory
                          orderId={item?.id}
                          customerName={item?.name_of_customer}
                          createdDate={moment(item?.created_date).format(
                            "DD/MM/YYYY hh:mm A",
                          )}
                          updatedDate={moment(item?.updated_date).format(
                            "DD/MM/YYYY hh:mm A",
                          )}
                          order_type={item?.order_type}
                          totalBill={`${shopSettingListData?.CURRENCY_CODE} ` + item?.total_bill}
                          tableNo={item?.diningtable?.name_of_table || "-"}
                          deleteData={deleteData}
                          editData={editData}
                          viewData={viewData}
                          printData={printData}
                          orderDetail={item}
                          itemId={item?.id}
                        />
                      </div>
                    ))}
                </div>
              )}
            </div>

            {filterHistory.length < 1 && !dataLoading && (
              <NotFoundComponent imgUrl={noFoundImg}>
                <p style={{ fontWeight: 600 }}>
                  {t("order-history-empty-data-message")}
                </p>
              </NotFoundComponent>
            )}
          </MobileContainer>

          <CommonOrderItemSummeryModel
           show={orderSummeryModelShow}
           onHide={hidePreviewPopup}
           refetchData = {getAllOrderHistoryList}
           orderDetailsData = {orderViewData}
           selectedOrderId = {orderViewData?.id}
        />
          {/* <OrderViewPopup
            show={detailsModalShow}
            orderDetails={orderViewData}
            settleOrderById = {settleOrderById}
            mobileBillPrint = {mobileBillPrint}
            onHide={() => setDetailsModalShow(false)}
          ></OrderViewPopup> */}
        </PageLoaderBlock>
  </Root>;
};

export default OrderHistory;
