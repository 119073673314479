import React, { useEffect, useState } from "react";
import { styled } from '@mui/material/styles';
// Importing toastify module
import MaterialTable, { MTableToolbar } from "material-table";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { useSelector } from "react-redux";
import moment from "moment";
import { RESOURCE } from "common/constant";
//context
import {
    useLayoutDispatch,
    addCurrentScreenTitle,
} from "context/LayoutContext";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { getGstReportData } from "services/report.service";
import CalendarWise from "./CalendarWise";

const PREFIX = 'OrderReport';

const classes = {
    cardCategoryWhite: `${PREFIX}-cardCategoryWhite`,
    cardTitleWhite: `${PREFIX}-cardTitleWhite`,
    root: `${PREFIX}-root`,
    tabel: `${PREFIX}-tabel`,
    header: `${PREFIX}-header`,
    searchbtn: `${PREFIX}-searchbtn`
};

const Root = styled('div')({
    [`& .${classes.cardCategoryWhite}`]: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0",
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF",
        },
    },
    [`& .${classes.cardTitleWhite}`]: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        // fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontFamily: '"Oxygen", sans-serif',
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1",
        },
    },
    [`& .${classes.root}`]: {
        width: "100%",
    },
    [`& .${classes.tabel}`]: {
        margin: "10px",
        marginTop: "0",
        "& .MuiPaper-root": {
            padding: 10,
            paddingBottom: 0,
        },
        "& tr": {
            height: 50,
            "& .MuiTableCell-root": {
                paddingLeft: "15px",
                fontSize: "14px !important",
            },
        },
    },
    [`&.${classes.header}`]: {
        backgroundColor: "white",
        boxShadow:
            "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
        padding: "10px",
        marginBottom: "10px",
    },
    [`& .${classes.searchbtn}`]: {
        fontWeight: 500,
        fontSize: "1rem",
        marginTop: "10px",
        height: "50px",
        lineHeight: "2px",
    },
});

const GstReport = () => {
    const unit = "pt";
    const size = "A4";
    const orientation = "portrait";
    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);
    require("jspdf-autotable");
    const theme = useTheme();
    const [initialReportDate, setInitialReportDate] = useState({
        startDate: moment().format("YYYY-MM-DD"), //  "endDate": "2021-03-30",
        endDate: moment().add(1, "days").format("YYYY-MM-DD") // "startDate": "YYYY-MM-DD"
    });
    const [gstReportData, setGstReportData] = useState([]);

    const [loading, setLoading] = useState(false);
    const userPermission = useSelector((state) => state.user.userPermission);
    const layoutDispatch = useLayoutDispatch();
    const { t } = useTranslation();
    const hasExportPermission =
        userPermission.length > 0
            ? JSON.parse(
                userPermission?.find((a) => a.resource === RESOURCE.SALES_REPORT)
                    ?.permission,
            )?.DOWNLOAD
            : true;
    useEffect(() => {
        addCurrentScreenTitle(layoutDispatch, {
            currentScreenTitle: t("gst-report-title-mobile-screen"),
        });
        return () => {
            addCurrentScreenTitle(layoutDispatch, { currentScreenTitle: "" });
        };
    }, []);

    const exportPDF = () => {
        doc.setFontSize(15);

        const title = `${t("gst-report-title-mobile-screen")} of ${initialReportDate?.startDate} to ${initialReportDate?.endDate}.}`;
        const headers = [Object.keys(gstReportData[0])]

        const data = gstReportData.map((item) => {
            return Object.values(item)
        });

        let content = {
            startY: 50,
            head: headers,
            body: data,
        };

        doc.text(title, marginLeft, 40);
        doc.autoTable(content);
        doc.save("tax-Report.pdf");
    };

    return (
        <Root className={classes.header}>
            <CalendarWise
                dataFetchingFunction={getGstReportData}
                setReportData={setGstReportData}
            />
            <MaterialTable
                title={
                    <h5>
                        <b>Tax Report </b>
                    </h5>
                }
                columns={
                    gstReportData && gstReportData.length > 0 ? Object.keys(gstReportData[0]).map(k => {
                        return {
                            title: k.toUpperCase(),
                            field: k,
                            cellStyle: {
                                width: k == "date" ? "10%" : "7%"
                            },
                        }
                    }) : []
                }
                data={
                    gstReportData &&
                    gstReportData?.map((item, index) => {
                        return { ...item, date: item?.date == "TOTAL" ? item?.date : moment(item?.date).format('DD-MM-YYYY'), };
                    })
                }
                options={{
                    maxBodyHeight: 'calc(100vh - 240px)',
                    rowStyle: (rowData) => rowData.bill_types === "Total amount" && {
                        color: 'green',
                        background: '#eee'
                    },
                    exportButton: hasExportPermission
                        ? {
                            pdf: false,
                            csv: true,
                        }
                        : false,
                    exportFileName: `gstReport-${moment().format("DD-MM-YYYY")}`,
                    searchFieldStyle: {
                        color: theme.palette.grey[500],
                        borderColor: "rgba(0, 0, 0, 0.23)",
                        border: "0.8px solid",
                        borderRadius: "6px",
                        padding: "10px",
                        "&:before": {
                            borderBottom: "0px!important",
                        },
                        "&:after": {
                            borderBottom: "0px!important",
                        },
                    },
                    headerStyle: {
                        color: theme.palette.grey[600],
                        backgroundColor: theme.palette.grey[200],
                    },
                    pageSize: 10,
                    paging: false,
                    emptyRowsWhenPaging: false, //to make page size fix in case of less data rows
                    pageSizeOptions: [10, 20, 30],
                    sorting: true,
                    actionsColumnIndex: -1,
                }}

            />
        </Root>
    );
};

export default GstReport;
