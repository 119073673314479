import React, { Fragment, useEffect, useState } from "react";
import { styled } from '@mui/material/styles';
import { toast } from "react-hot-toast";

// core components
import { useHistory } from "react-router-dom";
import TextInputMui from "components/TextInputMui";
import {
  Button,
  CircularProgress,
  IconButton,
  MenuItem,
  FormControl
} from "@mui/material";
import MaterialTable, { MTableToolbar } from "material-table";
import blankImage100 from "assets/img/dummyplaceholderImg/dummy_100.svg";
import {
  AddCircleOutline as AddCircleOutlineIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Cancel,
  Delete,
  DeleteForever,
} from "@mui/icons-material";

// API Calls
import { deleteFoodMenu, deleteBulkFoodMenu } from "services/foodItemMenu.service";
import { useDispatch, useSelector } from "react-redux";
import { fetchFoodMealList } from "state/thunk-async";
import configData from "../../config.js";
import TemporaryDrawer from "components/TemporaryDrawer";
import AddEditFoodMenu from "./AddEditFoodMenu";
import SkeletonLoader from "components/Loader/SkeletonLoader";
import NotFoundComponent from "components/NotFoundComponent";
import noFoundImg from "../../assets/img/no-data-found.jpg";
import vegImg from "../../assets/img/vegetarian-food-symbol.png";
import nonVegImg from "../../assets/img/non-vegetarian-food-symbol.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBookOpen } from "@fortawesome/free-solid-svg-icons";
import LowPriorityIcon from "@mui/icons-material/LowPriority";
import PriorityFoodMenu from "./PriorityFoodMenu.js";
import { DeskTopContainer } from "themes/Device.js";
import { MobileContainer } from "themes/Device.js";
import SearchBarInput from "components/SearchBarInput/SearchBarInput.js";
import Mobilefoodmenucard from "../../components/MobileFoodMenuCard/mobilefoodmenucard";
import MobileTitle from "components/MobileTitle/MobileTitle.js";
import RoleBasedGuard from "guards/RoleBasedGuard.js";
import { RESOURCE } from "common/constant.js";
import { ACTION } from "common/constant.js";
import { handleImageLoadingError } from "utility/helpers.js";
import PopUpModel from "components/Popup/PopUpModel.js";
//context
import {
  useLayoutState,
  useLayoutDispatch,
  addCurrentScreenTitle,
} from "context/LayoutContext";
import { useTheme } from "@mui/material/styles";
import Label from "components/Label";
import { useTranslation } from "react-i18next";
import CheckPermission from "guards/CheckPermission.js";

const PREFIX = 'FoodMenuList';

const classes = {
  cardCategoryWhite: `${PREFIX}-cardCategoryWhite`,
  cardTitleWhite: `${PREFIX}-cardTitleWhite`,
  root: `${PREFIX}-root`,
  tabel: `${PREFIX}-tabel`,
  prioritybtn: `${PREFIX}-prioritybtn`,
  mobileCard: `${PREFIX}-mobileCard`,
  mobileCardRoot:  `${PREFIX}-mobileCardRoot`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.cardCategoryWhite}`]: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },

  [`& .${classes.cardTitleWhite}`]: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    // fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontFamily: '"Oxygen", sans-serif',
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },

  [`& .${classes.root}`]: {
    width: "100%",
    paddingBottom: '80px'
  },
  [`& .${classes.mobileCardRoot}`]: {
    width: "100%",
    paddingBottom: '80px'
  },
  [`& .${classes.tabel}`]: {
    margin: "10px",
    marginTop: "0",
    "& .MuiPaper-root": {
      padding: 10,
      paddingBottom: 0,
    },
    "& tr": {
      height: 50,
      "& .MuiTableCell-root": {
        paddingLeft: "15px",
        fontSize: "14px !important",
      },
    },
  },

  [`& .${classes.prioritybtn}`]: {
    "& a:hover,& a:focus": {
      backgroundColor: "#FF5C93",
    },
    marginLeft: "5px",
    backgroundColor: "#FF5C93",
    color: "white",
  },

  [`& .${classes.mobileCard}`]: {
    padding: "2px"
  }
}));

const FoodMenuList = () => {

  const theme = useTheme();
  const [deletePopupshow, setDeletePopupshow] = useState(false);
  const [deleteId, setDeleteId] = useState(0);
  const [addEditDrawerShow, setAddEditDrawerShow] = useState(false);
  const [addEditFoodMenuId, setAddEditFoodMenuId] = useState(0);

  const foodItemMenuDataList = useSelector((state) => state.api.food_meal_list);
  const foodItemMenuPaginationData = useSelector((state) => state.api.food_meal_listPager);
  const foodMenuLoader = useSelector((state) => state.api.foodMenuLoader);
  const shopSettingListData = useSelector(
    (state) => state.shopSettingReducer.shopSettingData,
  );
  const foodCategoryDataList = useSelector((state) => state.api.category);
  const [beautifulDrawerShow, setBeautifulDrawerShow] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [searchFoodMenu, setSearchFoodMenu] = useState("");
  const [filterFoodMenuList, setFilterFoodMenuList] = useState([]);
  const [selectCategory, setSelectCategory] = useState(null);
  const [filterdFoodItemList, setFilteredFoodItemList] = useState([]);
  const dispatch = useDispatch();
  const history = useHistory();
  var layoutState = useLayoutState();
  var layoutDispatch = useLayoutDispatch();
  const { t } = useTranslation();
  const [paginationParam, setPaginationParam] = useState({
    type: 'DESC',
    search: '',
    page: 1,
    limit: 20,
    column: 'id'
  })

  const deleteFoodMenuData = () => {
    if (deleteId > 0) {
      setIsDeleting(true);
      deleteFoodMenu(deleteId)
        .then((res) => {
          setIsDeleting(false);
          setDeletePopupshow(false);
          if (res?.data) {
            toast.success(res?.message);
          } else {
            toast.success(res?.message);
          }
          dispatch(fetchFoodMealList());
        })
        .catch((err) => {
          setIsDeleting(false);
          setDeletePopupshow(false);
          toast.success(err?.message);
        });
    }
  };

  const deleteDataConfirm = (id) => {
    setDeleteId(id);
    setDeletePopupshow(true);
  };

  const addEditDrawerClose = () => {
    setAddEditDrawerShow(false);
    setBeautifulDrawerShow(false);
    setAddEditFoodMenuId(0);
  };

  const isOnlyVegStore = () => {
    return shopSettingListData?.IS_SHOP_VEGETARIAN;
  };

  function sortByPriority(a, b) {
    if (a.is_priority < b.is_priority) {
      return -1;
    }
    if (a.is_priority > b.is_priority) {
      return 1;
    }
    return 0;
  }

  const deleteData = (deleteid) => {
    setDeleteId(deleteid);
    setDeletePopupshow(true);
  };

  const editData = (editid) => {
    // setAddEditFoodMenuId(editid);
    // setAddEditDrawerShow(true);
    history.push({
      pathname: `/app/food-menu/edit/${editid}`,
      state: {
        foodMenuId: editid,
        backToMain: () => history.push(`/app/food-menu`),
      },
    });
  };

  const addDrawerShow = () => {
    // setAddEditDrawerShow(true);
    history.push({
      pathname: `/app/food-menu/add`,
      state: {
        foodMenuId: 0,
        backToMain: () => history.push(`/app/food-menu`),
      },
    });
  };

  const clearTxt = () => {
    setSearchFoodMenu("");
  };

  const handleChange = (selectedOption) => {
    setSelectCategory(selectedOption);
  };

  useEffect(() => {
    if (layoutState?.currentScreenTitle !== "Food Menu") {
      addCurrentScreenTitle(layoutDispatch, {
        currentScreenTitle: t("food-menu-title"),
      });
    }
    return () => {
      if (layoutState?.currentScreenTitle === "Food Menu") {
        addCurrentScreenTitle(layoutDispatch, { currentScreenTitle: "" });
      }
    };
  }, []);

  useEffect(() => {
    var IDs = [];
    if (selectCategory) {
      IDs = [selectCategory];
      const filteredFoodItem = foodItemMenuDataList.filter((fooditem) =>
        IDs.includes(fooditem.category_id),
      );
      setFilteredFoodItemList(filteredFoodItem);
    } else {
      setFilteredFoodItemList(foodItemMenuDataList);
    }
  }, [selectCategory, foodItemMenuDataList]);

  useEffect(() => {
    setFilterFoodMenuList(foodItemMenuDataList);
  }, [foodItemMenuDataList]);



  useEffect(() => {
    dispatch(fetchFoodMealList(paginationParam));
  }, [paginationParam])

  useEffect(() => {
    if (searchFoodMenu) {
      let filterMenuList = foodItemMenuDataList.filter(
        (item) =>
          item.name &&
          item.name?.toLowerCase()?.includes(searchFoodMenu.toLowerCase()),
      );
      setFilterFoodMenuList(filterMenuList);
    } else {
      setFilterFoodMenuList(foodItemMenuDataList);
    }
  }, [searchFoodMenu]);

  const addSetPriorityDrawerShow = () => {
    setBeautifulDrawerShow(true);
  };
  const [bulkDeleteIds, setbulkDeleteIds] = useState([]);
  const [deletebulkPopupshow, setDeletebulkPopupshow] = useState(false);
  const handleDeleteRows = async () => {
    setDeletebulkPopupshow(false)
    setbulkDeleteIds([]);
    const result = await deleteBulkFoodMenu({
      ids: bulkDeleteIds
    });
    if (result?.data?.status === 200) {
      toast.success(result?.data?.message);
    } else {
      toast.error(result?.message);
    }
    dispatch(fetchFoodMealList());
  };
  const handleDeleteRowsConformation = async (event, rowData) => {
    const ids = rowData.map((d) => d.id);
    setDeletebulkPopupshow(true)
    setbulkDeleteIds(ids);
  };
  return (
    <Root>
      <PopUpModel
        show={deletePopupshow}
        heading={t("food-menu-delete-title")}
        body={t("food-menu-delete-popup-body")}
        footer={
          <>
            <Button
              variant="contained"
              color="primary"
              className="mr-3"
              onClick={() => setDeletePopupshow(false)}
            >
              {" "}
              <Cancel /> {t("popup-cancel-button-label")}
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={deleteFoodMenuData}
            >
              {" "}
              <Delete /> {isDeleting ? <CircularProgress /> : t("delete")}
            </Button>
          </>
        }
        onHide={() => setDeletePopupshow(false)}
      />
      <PopUpModel
        show={deletebulkPopupshow}
        heading={"Are you sure you want to delete selected food items?"}
        body={"Please Conform Selected Opration."}
        footer={
          <>
            <Button
              variant="contained"
              color="primary"
              className="mr-3"
              onClick={() => setDeletebulkPopupshow(false)}
            >
              {" "}
              <Cancel /> {t("popup-cancel-button-label")}
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleDeleteRows}
            >
              {" "}
              <Delete /> {isDeleting ? <CircularProgress /> : t("delete")}{" "}
            </Button>
          </>
        }
        onHide={() => setDeletebulkPopupshow(false)}
      />
      <TemporaryDrawer
        drawerClose={addEditDrawerClose}
        isOpen={addEditDrawerShow}
        drawerWidth="50%"
        icon={<FontAwesomeIcon icon={faBookOpen} />}
        title={t("food-menu-title")}
      >
        <AddEditFoodMenu
          foodMenuId={addEditFoodMenuId}
          drawerClose={addEditDrawerClose}
        />
      </TemporaryDrawer>
      <TemporaryDrawer
        drawerClose={addEditDrawerClose}
        isOpen={beautifulDrawerShow}
        drawerWidth="50%"
        icon={<LowPriorityIcon />}
        title={t("priority-label")}
      >
        <PriorityFoodMenu
          foodItemMenuDataList={foodItemMenuDataList}
          drawerClose={addEditDrawerClose}
        />
      </TemporaryDrawer>
      <DeskTopContainer>
        <div className={classes.tabel}>
          <MaterialTable
            title={
              <h5>
                <b className="ml-3">{t("food-menu-title")}</b>
              </h5>
            }
            columns={[
              {
                title: t("food-menu-label"),
                field: "foodName",
                width: "30%",
                cellStyle: {
                  width: "30%",
                },
                render: (rowData) =>
                  rowData.food_image ? (
                    <div>
                      {!isOnlyVegStore && (
                        <>
                          {!rowData?.is_non_veg ? (
                            <img src={vegImg} style={{ width: "25px" }} />
                          ) : (
                            <img src={nonVegImg} style={{ width: "25px" }} />
                          )}
                        </>
                      )}
                      <img
                        src={`${configData?.S3_BUCKET}${rowData.food_image}`}
                        onError={({ currentTarget }) =>
                          handleImageLoadingError(currentTarget, blankImage100)
                        }
                        style={{ height: 35, width: 35, marginLeft: 10 }}
                      />
                      <span style={{ marginLeft: 10 }}>{rowData.foodName}</span>
                    </div>
                  ) : (
                    <div>
                      {!isOnlyVegStore && (
                        <>
                          {!rowData?.is_non_veg ? (
                            <img src={vegImg} style={{ width: "25px" }} />
                          ) : (
                            <img src={nonVegImg} style={{ width: "25px" }} />
                          )}
                        </>
                      )}
                        {/* <img
                        src={`${configData?.S3_BUCKET}no-photo.png`}
                        onError={({ currentTarget }) =>
                          handleImageLoadingError(currentTarget, blankImage100)
                        }
                        style={{ height: 35, width: 35, marginLeft: 10 }}
                      /> */}
                      <span style={{ marginLeft: 10 }}>{rowData.foodName}</span>
                    </div>
                  ),
              },
              {
                title: t("category-tab-label"),
                field: "category",
                cellStyle: {
                  width: "20%",
                },
              },
              {
                title: t("price-label"),
                field: "price",
                cellStyle: {
                  width: "15%",
                },
              },
              {
                title: t("available-unavailable"),
                field: "active",
                cellStyle: {
                  width: "25%",
                },
              },
              {
                title: t("action"),
                field: "action",
                cellStyle: {
                  width: "10%",
                },
                render: (rowData) => (
                  <Fragment>
                    <RoleBasedGuard
                      resource={RESOURCE.FOOD_MENUS}
                      action={ACTION.UPDATE}
                    >
                      <IconButton
                        color="primary"
                        title={t("food-menu-edit")}
                        aria-label={t("food-menu-edit")}
                        onClick={() => {
                          setAddEditFoodMenuId(rowData.id);
                          setAddEditDrawerShow(true);
                        }}
                        size="large">
                        <EditIcon />
                      </IconButton>
                    </RoleBasedGuard>
                    <RoleBasedGuard
                      resource={RESOURCE.FOOD_MENUS}
                      action={ACTION.DELETE}
                    >
                      <IconButton
                        color="secondary"
                        title={t("food-menu-delete")}
                        aria-label={t("food-menu-delete")}
                        onClick={() => deleteDataConfirm(rowData.id)}
                        size="large">
                        <DeleteIcon />
                      </IconButton>
                    </RoleBasedGuard>
                  </Fragment>
                ),
              },
            ]}
            data={
              filterdFoodItemList &&
              filterdFoodItemList.sort(sortByPriority).map((item, index) => {
                return {
                  foodName: item.name,
                  category: item?.category?.name,
                  price: shopSettingListData?.CURRENCY_CODE + parseFloat(item.price).toFixed(2),
                  id: item.id,
                  is_non_veg: item?.is_non_veg,
                  food_image: item.food_image,
                  active: item?.is_active ? (
                    <>
                      <div>
                        <Label
                          variant={
                            theme.palette.mode === "light" ? "ghost" : "filled"
                          }
                          color={"success"}
                        >
                          {item?.is_active ? "Available" : item?.is_active}
                        </Label>
                      </div>
                    </>
                  ) : (
                    <Label
                      variant={
                        theme.palette.mode === "light" ? "ghost" : "filled"
                      }
                      color={"error"}
                    >
                      {!item?.is_active ? "Unavailable" : item?.is_active}
                    </Label>
                  ),
                };
              })
            }
            options={{
              paging: true,
              pageSize: 100,
              emptyRowsWhenPaging: false, //to make page size fix in case of less data rows
              pageSizeOptions: [10, 20, 30],
              sorting: true,
              selection: CheckPermission(RESOURCE.FOOD_MENUS, ACTION.DELETE) ? true : false,
              actionsColumnIndex: -1,
              searchFieldAlignment: "left",
              searchFieldStyle: {
                color: theme.palette.grey[500],
                borderColor: "rgba(0, 0, 0, 0.23)",
                border: "0.8px solid",
                borderRadius: "6px",
                padding: "10px",
                "&:before": {
                  borderBottom: "0px!important",
                },
                "&:after": {
                  borderBottom: "0px!important",
                },
              },
              headerStyle: {
                color: theme.palette.grey[600],
                backgroundColor: theme.palette.grey[200],
              },
              // showTitle: false,
              // rowStyle: (rowData, index) => {
              //   if (index % 2) {
              //     return { backgroundColor: "#f2f2f2" };
              //   }
              // },
            }}
            actions={
              CheckPermission(RESOURCE.FOOD_MENUS, ACTION.DELETE) ? [
                {
                  icon: () => <DeleteForever />,
                  tooltip: "Delete Rows",
                  onClick: handleDeleteRowsConformation
                }
              ] : []
            }
            localization={{
              toolbar: {
                searchPlaceholder: t("search"),
              },
              body: {
                emptyDataSourceMessage: foodMenuLoader ? (
                  <SkeletonLoader />
                ) : (
                  <NotFoundComponent imgUrl={noFoundImg}>
                    <p style={{ fontWeight: 600 }}>No Food Menu found !</p>
                  </NotFoundComponent>
                ),
              },
            }}
            // other props
            components={{
              Toolbar: (props) => (
                <div className="custom-material-table-toolbar ">
                  <MTableToolbar {...props} />
                  <FormControl style={{ width: "20rem" }} size="small">
                    <TextInputMui
                      value={selectCategory}
                      select
                      defaultValue=""
                      label={t("food-menu-category-filter")}
                      variant="outlined"
                      onChange={(event) => handleChange(event.target.value)}
                      styles={{
                        control: (style) => ({
                          ...style,
                          width: 200,
                          marginRight: 10,
                        }),
                        container: (style) => ({
                          ...style,
                          zIndex: 999,
                        }),
                        option: (provided, state) => ({
                          ...provided,
                          height: 30,
                          lineHeight: "30px",
                          padding: 20,
                        }),
                      }}
                    >
                      {foodCategoryDataList?.map((foodCategoryitem) => (
                        <MenuItem value={foodCategoryitem?.id}>
                          {foodCategoryitem?.name}
                        </MenuItem>
                      ))}
                    </TextInputMui>
                  </FormControl>
                  <div style={{ padding: "0px 10px", display: "flex" }}>
                  <RoleBasedGuard
                      resource={RESOURCE.FOOD_MENUS}
                      action={ACTION.UPDATE}
                    >
                      <Button
                        variant="contained"
                        color="secondary"
                        className="ml-2"
                        onClick={() => {
                          setBeautifulDrawerShow(true);
                        }}
                        startIcon={<LowPriorityIcon />}
                        disabled={
                          foodItemMenuDataList?.length > 0 ? false : true
                        }
                      >
                        {t("priority-label")}
                      </Button>
                    </RoleBasedGuard>
                    <RoleBasedGuard
                      resource={RESOURCE.FOOD_MENUS}
                      action={ACTION.ADD}
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        startIcon={<AddCircleOutlineIcon />}
                        onClick={() => {
                          setAddEditDrawerShow(true);
                        }}
                      >
                        {t("food-menu-add")}
                      </Button>
                    </RoleBasedGuard>
                 
                  </div>
                </div>
              ),
            }}
          />
        </div>
      </DeskTopContainer>
      <MobileContainer>
        <div className={classes.mobileCardRoot}>
          <MobileTitle
            resource={RESOURCE.FOOD_MENUS}
            title={t("food-menu-title")}
            addButton={addDrawerShow}
            setPriorityButton={addSetPriorityDrawerShow}
          />
          <SearchBarInput
            onChange={(e) => {
              setSearchFoodMenu(e.target.value);
            }}
            value={searchFoodMenu}
            placeholder={t("food-menu-search-field")}
            clearTxt={clearTxt}
          />
          {foodMenuLoader ? (
            <SkeletonLoader count={3} height="10rem" />
          ) : (
            <div>
              {filterFoodMenuList &&
                filterFoodMenuList?.map((item, index) => (
                  <div className={classes.mobileCard} key={index}>
                    <Mobilefoodmenucard
                      resource={RESOURCE.FOOD_MENUS}
                      foodName={item.name}
                      category={item?.category?.name}
                      price={`${shopSettingListData?.CURRENCY_CODE}` + parseFloat(item.price).toFixed(2)}
                      description={item?.description || "-"}
                      active={item.is_active}
                      is_non_veg={item?.is_non_veg}
                      food_image={item.food_image}
                      deleteData={deleteData}
                      editData={editData}
                      itemId={item?.id}
                    />
                  </div>
                ))}
            </div>
          )}
        </div>
        {filterFoodMenuList?.length < 1 && !foodMenuLoader && (
          <NotFoundComponent imgUrl={noFoundImg}>
            <p style={{ fontWeight: 600 }}>
              {t("food-menu-empty-data-message")}
            </p>
          </NotFoundComponent>
        )}
      </MobileContainer>
    </Root>
  );
};

export default FoodMenuList;
