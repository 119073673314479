import { Card, Container, Grid, Stack, TextField, Typography, Autocomplete } from "@mui/material";
import { styled } from '@mui/material/styles';
import React, { useEffect, useState } from "react";
import { Switch, Button, MenuItem } from "@mui/material";
import { Form, Field } from "react-final-form";
import TextInputMui from "components/TextInputMui";
import arrayMutators from "final-form-arrays";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import { updateSettingData } from "services/setting.service";
import toast from "react-hot-toast";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { refreshShopToken } from "services/shop.service";
import { fetchShopSettingInfo } from "state/thunk-async";
import { useDispatch, useSelector } from "react-redux";
import { setShopSettingData as setShopSettingDataDispatch } from "state/actions/shopSetting.types";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import PopUpModel from "components/Popup/PopUpModel";
import AddEditPrinter from "pages/AdditionalSetting/Printer/AddEditPrinter";
import { useTranslation } from "react-i18next";
import RoleBasedGuard from "guards/RoleBasedGuard";
import { RESOURCE } from "common/constant";
import { ACTION } from "common/constant";
import currencyJson from "../../../currency/currency.json";


const PREFIX = 'Settings';

const classes = {
  formcontrolwidth: `${PREFIX}-formcontrolwidth`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')({
  [`& .${classes.formcontrolwidth}`]: {
    width: "100%",
    marginBottom: 10,
    // paddingLeft: 58,
    "& > span": {
      maxWidth: 240,
      fontSize: 12,
    },
  },
});

const AutocompleteStyle = styled('div')(({ theme }) => ({
  '& .MuiAutocomplete-root': {
    // minWidth: 280,
    '&.Mui-focused .MuiAutocomplete-inputRoot': {
      boxShadow: theme.customShadows.z8,
    },
  },
  '& .MuiAutocomplete-inputRoot': {
    transition: theme.transitions.create('box-shadow', {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.shorter,
    }),
    '& fieldset': {
      borderWidth: `1px !important`,
      borderColor: `${theme.palette.grey[500_32]} !important`,
    },
  },
}));

const AutocompleteMui = styled(Autocomplete)`
  .MuiAutocomplete-root,
  .MuiFormControl-root,
  .MuiAutocomplete-inputRoot {
    height: inherit !important;
  }

  .MuiOutlinedInput-root.MuiInputBase-sizeSmall {
    padding: 16.5px 14px;
  }
  input {
    // padding: 5px 10px !important;
  }
`;

export default function Settings() {

  const [isUpdating, setIsUpdating] = useState(false);
  // const [shopSettingsData, setShopSettingsData] = useState({});
  const dispatch = useDispatch();
  const shopSettingsData = useSelector(
    (state) => state.shopSettingReducer.shopSettingData,
  );
  const [printerModalShow, setPrinterModalShow] = useState(false);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    getSettingsData();
  }, []);

  const getSettingsData = async () => {
    dispatch(fetchShopSettingInfo());
  };

  useEffect(() => {
    // var addScript = document.createElement("script");
    // addScript.setAttribute(
    //   "src",
    //   "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
    // );
    // document.body.appendChild(addScript);
    // window.googleTranslateElementInit = googleTranslateElementInit;
  }, []);

  const onSubmit = async (values) => {
    setIsUpdating(true);
    i18n.changeLanguage(values.STORE_LANGUAGE);
    let newValues = { ...values};
    delete newValues.CURRENCY_CODE;
    newValues = {...newValues, CURRENCY: newValues?.CURRENCY?.split(" - ")?.[0]}

    const shopId = localStorage.getItem("shopId");
    const result = await updateSettingData(newValues, shopId);
    if (result.status === 200) {
      setIsUpdating(false);
      getSettingsData();
      toast.success(result.data.message);
    } else if (result.status === 400) {
      setIsUpdating(false);
      toast.error(result?.message);
    }
    setRefreshShopToken();
  };

  const setRefreshShopToken = async () => {
    const bodyPayload = {
      username: localStorage.getItem("username"),
    };
    const result = await refreshShopToken(bodyPayload);
    if (result.statusCode === 200) {
      localStorage.setItem("token", result?.data?.token);
    } else {
      toast.error(result?.message);
    }
  };

  return (
    (<Root>
      {/* <div id="google_translate_element"></div> */}
      <PopUpModel
        size="lg"
        onHide={() => {
          setPrinterModalShow(false);
        }}
        show={printerModalShow}
        heading={t("available-printer-title")}
        body={<AddEditPrinter />}
      />
      <Form
        onSubmit={onSubmit}
        initialValues={shopSettingsData}
        validate={(values) => {
          const errors = {};
          if (
            values?.NOTIFICATION_TIME?.trim() &&
            parseInt(values?.NOTIFICATION_TIME) > 24
          ) {
            errors.NOTIFICATION_TIME = t(
              "settings-time-max-validation-message",
            );
          }
          if (
            values?.NOTIFICATION_TIME?.trim() &&
            parseInt(values?.NOTIFICATION_TIME) < 0
          ) {
            errors.NOTIFICATION_TIME = t(
              "settings-time-min-validation-message",
            );
          }
          if (
            values?.ORDER_TOKEN_RESET_TIME?.trim() &&
            parseInt(values?.ORDER_TOKEN_RESET_TIME) > 24
          ) {
            errors.NOTIFICATION_TIME = t(
              "settings-time-max-validation-message",
            );
          }
          if (
            values?.ORDER_TOKEN_RESET_TIME?.trim() &&
            parseInt(values?.ORDER_TOKEN_RESET_TIME) < 0
          ) {
            errors.NOTIFICATION_TIME = t(
              "settings-time-min-validation-message",
            );
          }

          return errors;
        }}
        mutators={{
          ...arrayMutators,
        }}
        render={({ handleSubmit, values }) => (
          <form onSubmit={handleSubmit} className="mt-3">
            <Container className="profile-container">
              <Card sx={{ p: 3 }}>
                <Stack spacing={3} alignItems="flex-end">
                  <Stack spacing={2} sx={{ width: 1 }}>
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Stack>
                        <Typography
                          variant="overline"
                          sx={{ color: "text.secondary" }}
                        >
                          {t("settings-screen-title")}
                        </Typography>
                      </Stack>
                      <Stack>
                        <RoleBasedGuard
                          resource={RESOURCE.PROFILE}
                          action={ACTION.UPDATE}
                        >
                          <Button
                            type="submit"
                            color="primary"
                            variant="contained"
                            loading={isUpdating}
                          >
                            Save Changes
                          </Button>
                        </RoleBasedGuard>
                      </Stack>
                    </Stack>

                    <GridContainer spacing={1}>
                      <GridItem xs={12} sm={12} md={6}>
                        {/* <Field name="SMS_ENABLED">
                            {({ input, meta }) => (
                              <FormControlLabel
                                control={
                                  <Switch
                                    checked={
                                      Boolean(input.value) ||
                                      Boolean(input.checked)
                                    }
                                    value={Boolean(input.value)}
                                    onChange={(event) => {
                                      input.onChange(event.target.checked);
                                    }}
                                    inputProps={{
                                      "aria-label": "controlled",
                                    }}
                                  />
                                }
                                label="IS SMS ENABLED ?"
                              />
                            )}
                          </Field>
                          {values?.SMS_ENABLED && (
                            <Field name="SMS">
                              {({ input, meta }) => (
                                <FormControl
                                  className={classes.formcontrolwidth}
                                >
                                  <TextInputMui
                                    {...input}
                                    type="number"
                                    label="Sms Credit Shop *"
                                    variant="outlined"
                                    error={
                                      meta.error && meta.touched ? true : false
                                    }
                                  />
                                  {meta.error && meta.touched && (
                                    <span style={{ color: "red" }}>
                                      {meta.error}
                                    </span>
                                  )}
                                </FormControl>
                              )}
                            </Field>
                          )} */}
                        <Field name="WHATSAPP_ENABLED">
                          {({ input, meta }) => (
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={
                                    Boolean(input.value) ||
                                    Boolean(input.checked)
                                  }
                                  value={Boolean(input.value)}
                                  onChange={(event) => {
                                    input.onChange(event.target.checked);
                                  }}
                                  inputProps={{
                                    "aria-label": "controlled",
                                  }}
                                />
                              }
                              label={t("settings-whatsapp-enabled-label")}
                            />
                          )}
                        </Field>
                        {values.WHATSAPP_ENABLED && (
                          <Field name="WHATSAPP">
                            {({ input, meta }) => (
                              <FormControl
                                className={classes.formcontrolwidth}
                              >
                                <TextInputMui
                                  {...input}
                                  disabled
                                  type="number"
                                  label={`${t(
                                    "settings-whatsapp-credit-shop-label",
                                  )}${" *"}`}
                                  variant="outlined"
                                  error={
                                    meta.error && meta.touched
                                      ? true
                                      : false
                                  }
                                  helperText={t(
                                    "settings-whatsapp-alert-message",
                                  )}
                                />
                                {meta.error && meta.touched && (
                                  <span style={{ color: "red" }}>
                                    {meta.error}
                                  </span>
                                )}
                              </FormControl>
                            )}
                          </Field>
                        )}

                        <Field name="DINE_IN_SERVICE">
                          {({ input, meta }) => (
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={
                                    Boolean(input.value) ||
                                    Boolean(input.checked)
                                  }
                                  value={Boolean(input.value)}
                                  onChange={(event) => {
                                    input.onChange(event.target.checked);
                                  }}
                                  inputProps={{
                                    "aria-label": "controlled",
                                  }}
                                />
                              }
                              label={t(
                                "settings-dine-in-service-enabled-label",
                              )}
                            />
                          )}
                        </Field>
                        <Field name="DEFAULT_PARCEL_ORDER">
                          {({ input, meta }) => (
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={
                                    Boolean(input.value) ||
                                    Boolean(input.checked)
                                  }
                                  value={Boolean(input.value)}
                                  onChange={(event) => {
                                    input.onChange(event.target.checked);
                                  }}
                                  inputProps={{
                                    "aria-label": "controlled",
                                  }}
                                />
                              }
                              label={t(
                                "settings-default-parcel-order-enabled-label",
                              )}
                            />
                          )}
                        </Field>
                        <Field name="IS_SHOP_VEGETARIAN">
                          {({ input, meta }) => (
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={
                                    Boolean(input.value) ||
                                    Boolean(input.checked)
                                  }
                                  value={Boolean(input.value)}
                                  onChange={(event) => {
                                    input.onChange(event.target.checked);
                                  }}
                                  inputProps={{
                                    "aria-label": "controlled",
                                  }}
                                />
                              }
                              label={t("settings-vegetarian-shop-label")}
                            />
                          )}
                        </Field>
                        <br></br>


                        {/* <Field name="IS_ENABLE_KOT">
                              {({ input, meta }) => (
                                <FormControlLabel
                                  control={
                                    <Switch
                                      checked={
                                        Boolean(input.value) ||
                                        Boolean(input.checked)
                                      }
                                      value={Boolean(input.value)}
                                      onChange={(event) => {
                                        input.onChange(event.target.checked);
                                      }}
                                      inputProps={{
                                        "aria-label": "controlled",
                                      }}
                                    />
                                  }
                                  label={t("settings-kot-order-enabled-label")}
                                />
                              )}
                            </Field> */}
                        <br></br>
                        <Field name="INVENTORY_OUTOFSTOCK_NOTIFIER">
                          {({ input, meta }) => (
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={
                                    Boolean(input.value) ||
                                    Boolean(input.checked)
                                  }
                                  value={Boolean(input.value)}
                                  onChange={(event) => {
                                    input.onChange(event.target.checked);
                                  }}
                                  inputProps={{
                                    "aria-label": "controlled",
                                  }}
                                />
                              }
                              label={"Enable Inventory Out Of Stock Notifier?"}
                            />
                          )}
                        </Field>
                        <br></br>
                        <Field name="INVENTORY_SERVICE">
                          {({ input, meta }) => (
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={
                                    Boolean(input.value) ||
                                    Boolean(input.checked)
                                  }
                                  value={Boolean(input.value)}
                                  onChange={(event) => {
                                    input.onChange(event.target.checked);
                                  }}
                                  inputProps={{
                                    "aria-label": "controlled",
                                  }}
                                />
                              }
                              label={"Is Inventory Service Enabled?"}
                            />
                          )}
                        </Field>
                        <Field name="ENABLE_AUTO_FOOD_MENU_PRIORITY">
                          {({ input, meta }) => (
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={
                                    Boolean(input.value) ||
                                    Boolean(input.checked)
                                  }
                                  value={Boolean(input.value)}
                                  onChange={(event) => {
                                    input.onChange(event.target.checked);
                                  }}
                                  inputProps={{
                                    "aria-label": "controlled",
                                  }}
                                />
                              }
                              label={"Is food meal will auto arrange based on most selling food meal count?"}
                            />
                          )}
                        </Field>
                        <Field name="ENABLE_AUTO_FOOD_CATEGORY_PRIORITY">
                          {({ input, meta }) => (
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={
                                    Boolean(input.value) ||
                                    Boolean(input.checked)
                                  }
                                  value={Boolean(input.value)}
                                  onChange={(event) => {
                                    input.onChange(event.target.checked);
                                  }}
                                  inputProps={{
                                    "aria-label": "controlled",
                                  }}
                                />
                              }
                              label={"Is food category will auto arrange based on most selling food category count?"}
                            />
                          )}
                        </Field>
                        <Field name="AUTOMATIC_ORDER_SETTLE">
                          {({ input, meta }) => (
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={
                                    Boolean(input.value) ||
                                    Boolean(input.checked)
                                  }
                                  value={Boolean(input.value)}
                                  onChange={(event) => {
                                    input.onChange(event.target.checked);
                                  }}
                                  inputProps={{
                                    "aria-label": "controlled",
                                  }}
                                />
                              }
                              label={"Automatically Settle Order ?"}
                            />
                          )}
                        </Field>
                        <br></br>
                        <Field name="ONLINE_ORDER_START">
                          {({ input, meta }) => (
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={
                                    Boolean(input.value) ||
                                    Boolean(input.checked)
                                  }
                                  value={Boolean(input.value)}
                                  onChange={(event) => {
                                    input.onChange(event.target.checked);
                                  }}
                                  inputProps={{
                                    "aria-label": "controlled",
                                  }}
                                />
                              }
                              label={"Start Online Order?"}
                            />
                          )}
                        </Field>
                        <br></br>
                        <Field name="PAYMENT_ACEEPT_ONLINE">
                          {({ input, meta }) => (
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={
                                    Boolean(input.value) ||
                                    Boolean(input.checked)
                                  }
                                  value={Boolean(input.value)}
                                  onChange={(event) => {
                                    input.onChange(event.target.checked);
                                  }}
                                  inputProps={{
                                    "aria-label": "controlled",
                                  }}
                                />
                              }
                              label={"Accept Online Payment on your online store?"}
                            />
                          )}
                        </Field>

                        <br></br>
                        <Field name="ENABLE_CONFIRM_SETTLE_BTN">
                          {({ input, meta }) => (
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={
                                    Boolean(input.value) ||
                                    Boolean(input.checked)
                                  }
                                  value={Boolean(input.value)}
                                  onChange={(event) => {
                                    input.onChange(event.target.checked);
                                  }}
                                  inputProps={{
                                    "aria-label": "controlled",
                                  }}
                                />
                              }
                              label={"Show a confirm box on settle order?"}
                            />
                          )}
                        </Field>
                        <br></br>
                        <Field name="ROUND_OFF_PRICE">
                          {({ input, meta }) => (
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={
                                    Boolean(input.value) ||
                                    Boolean(input.checked)
                                  }
                                  value={Boolean(input.value)}
                                  onChange={(event) => {
                                    input.onChange(event.target.checked);
                                  }}
                                  inputProps={{
                                    "aria-label": "controlled",
                                  }}
                                />
                              }
                              label={"Round off the order prices?"}
                            />
                          )}
                        </Field>
                        <br></br>
                        <Field name="CURRENCY">
                          {({ input, meta }) => (
                            <FormControl
                              className={classes.formcontrolwidth}
                            >
                              <AutocompleteStyle>
                                <AutocompleteMui
                                  size="small"
                                  includeInputInList={true}
                                  getOptionLabel={(option) => option ? option : ''}
                                  onChange={(event, newValue) => {
                                    // setCustomerOptions(newValue ? [newValue, ...customerOptions] : customerOptions);
                                    input.onChange(newValue)
                                  }}
                                  onInputChange={(event, newInputValue) => {
                                    // console.log('%cOrderBillBox.js line:364 newInputValue', 'color: #007acc;', newInputValue);
                                    // if(newInputValue.length > 2)
                                    // {
                                    //   searchDelayed(newInputValue);
                                    // }
                                  }}
                                  value={input?.value || null}
                                  noOptionsText={"No option available"}
                                  options={Object?.keys(currencyJson)?.map(
                                      (a) => `${a} - ${currencyJson[a]?.symbol}`,
                                    )}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      placeholder={"Type currency name..."}
                                      type="text"
                                      label={"Select currency"}
                                      variant="outlined"
                                      error={
                                        meta.error && meta.touched
                                          ? true
                                          : false
                                      }
                                    />
                                  )}
                                />
                              </AutocompleteStyle>
                              {meta.error && meta.touched && (
                                <span style={{ color: "red" }}>
                                  {meta.error}
                                </span>
                              )}
                            </FormControl>
                          )}
                        </Field>
                      </GridItem>


                      <GridItem xs={12} sm={12} md={6}>


                        <Field name="EMAIL">
                          {({ input, meta }) => (
                            <FormControl
                              className={classes.formcontrolwidth}
                            >
                              <TextInputMui
                                {...input}
                                type="number"
                                label={`${t(
                                  "settings-email-credit-shop-label",
                                )}${" *"}`}
                                variant="outlined"
                                error={
                                  meta.error && meta.touched ? true : false
                                }
                              />
                              {meta.error && meta.touched && (
                                <span style={{ color: "red" }}>
                                  {meta.error}
                                </span>
                              )}
                            </FormControl>
                          )}
                        </Field>
                        <Field name="BILL_IP">
                          {({ input, meta }) => (
                            <FormControl
                              className={classes.formcontrolwidth}
                            >
                              <TextInputMui
                                {...input}
                                type="text"
                                label={t("settings-bill-ip-label")}
                                variant="outlined"
                                error={
                                  meta.error && meta.touched ? true : false
                                }
                              />
                              {meta.error && meta.touched && (
                                <span style={{ color: "red" }}>
                                  {meta.error}
                                </span>
                              )}
                            </FormControl>
                          )}
                        </Field>
                        <Field name="NOTIFICATION_TIME">
                          {({ input, meta }) => (
                            <FormControl
                              className={classes.formcontrolwidth}
                            >
                              <TextInputMui
                                {...input}
                                type="number"
                                label={t(
                                  "settings-daily-report--notification-label",
                                )}
                                variant="outlined"
                                error={
                                  meta.error && meta.touched ? true : false
                                }
                              />
                              {meta.error && meta.touched && (
                                <span style={{ color: "red" }}>
                                  {meta.error}
                                </span>
                              )}
                            </FormControl>
                          )}
                        </Field>
                        <Field name="REDIRECT_ON_BILLS">
                          {({ input, meta }) => (
                            <FormControl
                              className={classes.formcontrolwidth}
                            >
                              <TextInputMui
                                {...input}
                                select
                                label={t(
                                  "settings-redirect-page-after-bill-label",
                                )}
                                variant="outlined"
                                error={
                                  meta.error && meta.touched ? true : false
                                }
                              >
                                <MenuItem value={"DEFAULT"}>
                                  {t("settings-default-label")}
                                </MenuItem>
                                <MenuItem value={"DINE_IN_SCREEN"}>
                                  {t("settings-dine-in-screen-label")}
                                </MenuItem>
                                <MenuItem value={"SALES_HISTORY"}>
                                  {t("settings-sales-history-label")}
                                </MenuItem>
                                <MenuItem value={"SAME_SCREEN"}>
                                  {t("settings-same-screen-label")}
                                </MenuItem>
                              </TextInputMui>
                              {meta.error && meta.touched && (
                                <span style={{ color: "red" }}>
                                  {meta.error}
                                </span>
                              )}
                            </FormControl>
                          )}
                        </Field>
                        {/* <Field name="STORE_LANGUAGE">
                              {({ input, meta }) => (
                                <FormControl
                                  className={classes.formcontrolwidth}
                                >
                                  <TextInputMui
                                    {...input}
                                    select
                                    label={t("settings-shop-lang-label")}
                                    variant="outlined"
                                    error={
                                      meta.error && meta.touched ? true : false
                                    }
                                  >
                                    <MenuItem value={"en"}>
                                      {t("english-lang")}
                                    </MenuItem>
                                    <MenuItem value={"gu"}>
                                      {t("gujarati-lang")}
                                    </MenuItem>
                                    <MenuItem value={"hi"}>
                                      {"Hindi"}
                                    </MenuItem>
                                  </TextInputMui>
                                  {meta.error && meta.touched && (
                                    <span style={{ color: "red" }}>
                                      {meta.error}
                                    </span>
                                  )}
                                </FormControl>
                              )}
                            </Field> */}
                        <Field name="ORDER_TOKEN_RESET_TIME">
                          {({ input, meta }) => (
                            <FormControl
                              className={classes.formcontrolwidth}
                            >
                              <TextInputMui
                                {...input}
                                type="text"
                                helperText={"Eg values. 24:00, 03:00, 15:00 "}
                                label={t(
                                  "settings-order-token-reset-time-label",
                                )}
                                variant="outlined"
                                error={
                                  meta.error && meta.touched ? true : false
                                }
                              />
                              {meta.error && meta.touched && (
                                <span style={{ color: "red" }}>
                                  {meta.error}
                                </span>
                              )}
                            </FormControl>
                          )}
                        </Field>
                        <Field name="DAY_VALUE_FOR_CUSTOM_DATE_FILTER">
                          {({ input, meta }) => (
                            <FormControl
                              className={classes.formcontrolwidth}
                            >
                              <label>Forced Day value set for order history filter</label>
                              <TextInputMui
                                {...input}
                                type="number"
                                label={"Forced Day value set for order history filter ( Emergency USE)"}
                                variant="outlined"
                                helperText={" -1 -> 'current day - previous Day Filter', 0: 'current day', +1: 'current day + next day '"}
                                error={
                                  meta.error && meta.touched ? true : false
                                }

                              />
                              {meta.error && meta.touched && (
                                <span style={{ color: "red" }}>
                                  {meta.error}
                                </span>
                              )}
                            </FormControl>
                          )}
                        </Field>
                        <Field name="CUSTOMER_ORDER_DATE" >
                          {({ input, meta }) => (
                            <FormControl
                              className={classes.formcontrolwidth}
                            >
                              <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                  onChange={(e) => input.onChange(e)}
                                  value={input.value}
                                  label={"Custom Order Date"}
                                  inputFormat="dd/MM/yyyy"
                                  renderInput={(params) => <TextInputMui
                                    {...params}
                                    error={false}
                                  />}
                                />
                              </LocalizationProvider>
                            </FormControl>
                          )}
                        </Field>
                        <Field name="FOOD_MEAL_SORT">
                          {({ input, meta }) => (
                            <FormControl
                              className={classes.formcontrolwidth}
                            >
                              <TextInputMui
                                {...input}
                                select
                                label={"Food Meal Sorting order"}
                                variant="outlined"
                                error={
                                  meta.error && meta.touched ? true : false
                                }
                              >
                                <MenuItem value={"SORT_BY_PRICE_ASC"}>
                                  {"Sort by price(ASC)"}
                                </MenuItem>
                                <MenuItem value={"SORT_BY_PRICE_DESC"}>
                                  {"Sort by price(DESC)"}
                                </MenuItem>
                                <MenuItem value={"SORT_BY_ALPHABETIC_ASC"}>
                                  {"Sort by alphabetic(ASC)"}
                                </MenuItem>
                                <MenuItem value={"SORT_BY_ALPHABETIC_DESC"}>
                                  {"Sort by alphabetic(DESC)"}
                                </MenuItem>
                                <MenuItem value={"SORT_BY_NUMBER_ASC"}>
                                  {"Sort by number(ASC)"}
                                </MenuItem>
                                <MenuItem value={"SORT_BY_NUMBER_DESC"}>
                                  {"Sort by number(DESC)"}
                                </MenuItem>
                                <MenuItem value={"SORT_BY_PRIORITY_COUNT_ASC"}>
                                  {"Sort by priority count(ASC)"}
                                </MenuItem>
                                <MenuItem value={"SORT_BY_PRIORITY_COUNT_DESC"}>
                                  {"Sort by priority count(DESC)"}
                                </MenuItem>
                                <MenuItem value={"AUTO_SORT_BY_MOST_SELL_ASC"}>
                                  {"Auto sort by most sell items(ASC)"}
                                </MenuItem>
                                <MenuItem value={"AUTO_SORT_BY_MOST_SELL_DESC"}>
                                  {"Auto sort by most sell items(DESC)"}
                                </MenuItem>
                                <MenuItem value={"DEFAULT"}>
                                  {"Default"}
                                </MenuItem>
                              </TextInputMui>
                              {meta.error && meta.touched && (
                                <span style={{ color: "red" }}>
                                  {meta.error}
                                </span>
                              )}
                            </FormControl>
                          )}
                        </Field>

                        <Field name="FOOD_MENU_LANGUAGE_PREFERENCE">
                          {({ input, meta }) => (
                            <FormControl
                              className={classes.formcontrolwidth}
                            >
                              <TextInputMui
                                {...input}
                                select
                                label={"Food Meal/category language"}
                                variant="outlined"
                                error={
                                  meta.error && meta.touched ? true : false
                                }
                              >
                                <MenuItem value={"PRIMARY"}>
                                  {"Primary Language"}
                                </MenuItem>
                                <MenuItem value={"SECONDARY"}>
                                  {"Secondary Language"}
                                </MenuItem>
                              </TextInputMui>
                              {meta.error && meta.touched && (
                                <span style={{ color: "red" }}>
                                  {meta.error}
                                </span>
                              )}
                            </FormControl>
                          )}
                        </Field>


                        <Field name="CRASH_REPORT_ELECTRON_OPTION">
                          {({ input, meta }) => (
                            <FormControl
                              className={classes.formcontrolwidth}
                            >
                              <TextInputMui
                                {...input}
                                select
                                label={"Select crash report platform for software issue ( DEV PURPOSE)"}
                                variant="outlined"
                                error={
                                  meta.error && meta.touched ? true : false
                                }
                              >
                                <MenuItem value={"SENTRY_PLATFORM"}>
                                  {"Track report by sentry"}
                                </MenuItem>
                                <MenuItem value={"CUSTOM_API"}>
                                  {"Track report by servofeat custom API"}
                                </MenuItem>
                                <MenuItem value={"DISABLE_TRACKING"}>
                                  {"Disable Tracking"}
                                </MenuItem>
                              </TextInputMui>
                              {meta.error && meta.touched && (
                                <span style={{ color: "red" }}>
                                  {meta.error}
                                </span>
                              )}
                            </FormControl>
                          )}
                        </Field>
                      </GridItem>
                    </GridContainer>
                    <br></br>
                      <h5 className="m-auto p-3">Order Summery Popup Configuration</h5>
                      <br></br>
                        <GridContainer>
                        <Grid xs={12} sm={12} md={6}>
                          <Field name="IS_ORDER_SUMMERY_SHOW_ON_SETTLE">
                            {({ input, meta }) => (
                              <FormControlLabel
                                control={
                                  <Switch
                                    checked={
                                      Boolean(input.value) ||
                                      Boolean(input.checked)
                                    }
                                    value={Boolean(input.value)}
                                    onChange={(event) => {
                                      input.onChange(event.target.checked);
                                    }}
                                    inputProps={{
                                      "aria-label": "controlled",
                                    }}
                                  />
                                }
                                label={"Does order summery popup enable on settle button?"}
                              />
                            )}
                          </Field>
                          <br></br>
                          <Field name="ORDER_SUMMERY_POPUP_CUSTOMER">
                            {({ input, meta }) => (
                              <FormControlLabel
                                control={
                                  <Switch
                                    checked={
                                      Boolean(input.value) ||
                                      Boolean(input.checked)
                                    }
                                    value={Boolean(input.value)}
                                    onChange={(event) => {
                                      input.onChange(event.target.checked);
                                    }}
                                    inputProps={{
                                      "aria-label": "controlled",
                                    }}
                                  />
                                }
                                label={"show Customer add/edit box in order summery?"}
                              />
                            )}
                          </Field>
                          <br></br>

                          <Field name="ORDER_SUMMERY_POPUP_BILL">
                            {({ input, meta }) => (
                              <FormControlLabel
                                control={
                                  <Switch
                                    checked={
                                      Boolean(input.value) ||
                                      Boolean(input.checked)
                                    }
                                    value={Boolean(input.value)}
                                    onChange={(event) => {
                                      input.onChange(event.target.checked);
                                    }}
                                    inputProps={{
                                      "aria-label": "controlled",
                                    }}
                                  />
                                }
                                label={"Able to add/edit bill on order summery?"}
                              />
                            )}
                          </Field>
                          <br></br>
                        </Grid>
                        <Grid xs={12} sm={12} md={6}>

                          <Field name="ORDER_SUMMERY_POPUP_KITCHEN">
                            {({ input, meta }) => (
                              <FormControlLabel
                                control={
                                  <Switch
                                    checked={
                                      Boolean(input.value) ||
                                      Boolean(input.checked)
                                    }
                                    value={Boolean(input.value)}
                                    onChange={(event) => {
                                      input.onChange(event.target.checked);
                                    }}
                                    inputProps={{
                                      "aria-label": "controlled",
                                    }}
                                  />
                                }
                                label={"Able to manage kitchen progress in order summery?"}
                              />
                            )}
                          </Field>
                          <br></br>
                          <Field name="ORDER_SUMMERY_POPUP_PENDING_AMOUNT">
                            {({ input, meta }) => (
                              <FormControlLabel
                                control={
                                  <Switch
                                    checked={Boolean(input.value) || Boolean(input.checked)}
                                    value={Boolean(input.value)}
                                    onChange={(event) => { input.onChange(event.target.checked) }}
                                    inputProps={{
                                      "aria-label": "controlled",
                                    }}
                                  />
                                }
                                label={t(
                                  "Show a pending amount feature of calculation in order summery Popup?",
                                )}
                              />
                            )}
                          </Field>
                          <br></br>
                          <Field name="ORDER_SUMMERY_POPUP_BILL_CALCULATOR">
                            {({ input, meta }) => (
                              <FormControlLabel
                                control={
                                  <Switch
                                    checked={Boolean(input.value) || Boolean(input.checked)}
                                    value={Boolean(input.value)}
                                    onChange={(event) => { input.onChange(event.target.checked) }}
                                    inputProps={{
                                      "aria-label": "controlled",
                                    }}
                                  />
                                }
                                label={t(
                                  "Show a order bill calculation feature in order summery Popup?",
                                )}
                              />
                            )}
                          </Field>
                          <br></br>
                        </Grid>
                        </GridContainer>
                        <br></br>
                      <h5 className="m-auto p-3">Print Configuration Section</h5>
                      <br></br>
                    <GridContainer>
                      {/* printing_setting object start from here */}
                      <Grid>
                        <Grid>
                          <Field name="KITCHAN_SERVICE">
                            {({ input, meta }) => (
                              <FormControlLabel
                                control={
                                  <Switch
                                    checked={
                                      Boolean(input.value) ||
                                      Boolean(input.checked)
                                    }
                                    value={Boolean(input.value)}
                                    onChange={(event) => {
                                      input.onChange(event.target.checked);
                                    }}
                                    inputProps={{
                                      "aria-label": "controlled",
                                    }}
                                  />
                                }
                                label={t(
                                  "settings-kitchen-service-enabled-label",
                                )}
                              />
                            )}
                          </Field>
                          {values.KITCHAN_SERVICE && (
                            <>


                              <Field name="printing_setting.KITCHEN_PRINTER_NAME">
                                {({ input, meta }) => (
                                  <FormControl
                                    className={classes.formcontrolwidth}
                                  >
                                    <TextInputMui
                                      {...input}
                                      type="text"
                                      label={`${t(
                                        "settings-kitchen-printer-name-label",
                                      )}${" *"}`}
                                      variant="outlined"
                                      error={
                                        meta.error && meta.touched
                                          ? true
                                          : false
                                      }
                                    />
                                    {meta.error && meta.touched && (
                                      <span style={{ color: "red" }}>
                                        {meta.error}
                                      </span>
                                    )}
                                  </FormControl>
                                )}
                              </Field>
                              <Field name="printing_setting.KITCHEN_PRINTER_SIZE">
                                {({ input, meta }) => (
                                  <FormControl
                                    className={classes.formcontrolwidth}
                                  >
                                    <TextInputMui
                                      {...input}
                                      type="text"
                                      label={`${t(
                                        "settings-kitchen-printer-size-label",
                                      )}${" *"}`}
                                      variant="outlined"
                                      error={
                                        meta.error && meta.touched
                                          ? true
                                          : false
                                      }
                                    />
                                    {meta.error && meta.touched && (
                                      <span style={{ color: "red" }}>
                                        {meta.error}
                                      </span>
                                    )}
                                  </FormControl>
                                )}
                              </Field>
                              <Field name="KITCHEN_IP">
                                {({ input, meta }) => (
                                  <FormControl
                                    className={classes.formcontrolwidth}
                                  >
                                    <TextInputMui
                                      {...input}
                                      type="text"
                                      label={'Kitchen printer name or IP ( Mobile printer)'}
                                      variant="outlined"
                                      error={
                                        meta.error && meta.touched
                                          ? true
                                          : false
                                      }
                                    />
                                    {meta.error && meta.touched && (
                                      <span style={{ color: "red" }}>
                                        {meta.error}
                                      </span>
                                    )}
                                  </FormControl>
                                )}
                              </Field>
                            </>
                          )}
                          <Field name="PRINTER_ENABLED">
                            {({ input, meta }) => (
                              <FormControlLabel
                                control={
                                  <Switch
                                    checked={
                                      Boolean(input.value) ||
                                      Boolean(input.checked)
                                    }
                                    value={Boolean(input.value)}
                                    onChange={(event) => {
                                      input.onChange(event.target.checked);
                                    }}
                                    inputProps={{
                                      "aria-label": "controlled",
                                    }}
                                  />
                                }
                                label={t("settings-printer-enabled-label")}
                              />
                            )}
                          </Field>
                          {values?.PRINTER_ENABLED && (
                            <Field name="printing_setting.PRINTER_NAME">
                              {({ input, meta }) => (
                                <FormControl
                                  className={classes.formcontrolwidth}
                                >
                                  <TextInputMui
                                    {...input}
                                    type="text"
                                    label="Printer Name *"
                                    variant="outlined"
                                    error={
                                      meta.error && meta.touched
                                        ? true
                                        : false
                                    }
                                  />
                                  {meta.error && meta.touched && (
                                    <span style={{ color: "red" }}>
                                      {meta.error}
                                    </span>
                                  )}
                                  <div>
                                    <Button
                                      color="secondary"
                                      size="small"
                                      type="button"
                                      onClick={() =>
                                        setPrinterModalShow(true)
                                      }
                                    >
                                      {t("settings-click-here-label")}
                                    </Button>{" "}
                                    {t("settings-available-printer-label")}
                                  </div>
                                </FormControl>
                              )}
                            </Field>
                          )}
                          {values?.PRINTER_ENABLED && (
                            <Field name="printing_setting.PRINTER_TYPE">
                              {({ input, meta }) => (
                                <FormControl
                                  className={classes.formcontrolwidth}
                                >
                                  <TextInputMui
                                    {...input}
                                    type="text"
                                    label={`${t(
                                      "settings-printer-type-label",
                                    )}${" *"}`}
                                    variant="outlined"
                                    error={
                                      meta.error && meta.touched
                                        ? true
                                        : false
                                    }
                                  />
                                  {meta.error && meta.touched && (
                                    <span style={{ color: "red" }}>
                                      {meta.error}
                                    </span>
                                  )}
                                </FormControl>
                              )}
                            </Field>
                          )}
                          {values?.PRINTER_ENABLED && (
                            <Field name="printing_setting.PRINTER_SIZE">
                              {({ input, meta }) => (
                                <FormControl
                                  className={classes.formcontrolwidth}
                                >
                                  <TextInputMui
                                    {...input}
                                    type="number"
                                    label={t(
                                      "settings-printer-paper-size-label",
                                    )}
                                    variant="outlined"
                                    error={
                                      meta.error && meta.touched
                                        ? true
                                        : false
                                    }
                                    helperText={`${t(
                                      "settings-example",
                                    )}${"140, 170(40MM), 200(40MM), 250, 270(80MM), 300"}`}
                                  />
                                  {meta.error && meta.touched && (
                                    <span style={{ color: "red" }}>
                                      {meta.error}
                                    </span>
                                  )}
                                </FormControl>
                              )}
                            </Field>
                          )}
                          {/* {values?.PRINTER_ENABLED && (
                            <Field name="printing_setting.THERMAL_PRINTER_PAPER_SIZE">
                                  {({ input, meta }) => (
                                    <FormControl
                                      className={classes.formcontrolwidth}
                                    >
                                      <TextInputMui
                                        {...input}
                                        type="number"
                                        label={`${t(
                                          "settings-thermal-printer-paper-size-label",
                                        )}${" *"}`}
                                        variant="outlined"
                                        error={
                                          meta.error && meta.touched
                                            ? true
                                            : false
                                        }
                                        helperText={`${t(
                                          "settings-example",
                                        )}${"140, 170(40MM), 200(40MM), 250, 270(80MM), 300"}`}
                                      />
                                      {meta.error && meta.touched && (
                                        <span style={{ color: "red" }}>
                                          {meta.error}
                                        </span>
                                      )}
                                    </FormControl>
                                  )}
                                </Field>
                              )} */}
                          <Field name="printing_setting.ENABLE_PREVIEW_PRINT">
                            {({ input, meta }) => (
                              <FormControlLabel
                                control={
                                  <Switch
                                    checked={
                                      Boolean(input.value) ||
                                      Boolean(input.checked)
                                    }
                                    value={Boolean(input.value)}
                                    onChange={(event) => {
                                      input.onChange(event.target.checked);
                                    }}
                                    inputProps={{
                                      "aria-label": "controlled",
                                    }}
                                  />
                                }
                                label={"Show a print preview before printing in software?"}
                              />
                            )}
                          </Field>
                          <br></br>
                          <Field name="printing_setting.PRINT_ON_SETTLE">
                            {({ input, meta }) => (
                              <FormControlLabel
                                control={
                                  <Switch
                                    checked={
                                      Boolean(input.value) ||
                                      Boolean(input.checked)
                                    }
                                    value={Boolean(input.value)}
                                    onChange={(event) => {
                                      input.onChange(event.target.checked);
                                    }}
                                    inputProps={{
                                      "aria-label": "controlled",
                                    }}
                                  />
                                }
                                label={t(
                                  "settings-printer-settle-order-label",
                                )}
                              />
                            )}
                          </Field>
                          <br></br>
                          <Field name="printing_setting.SAME_PRINTER_KOT_PRINT_ENABLED">
                            {({ input, meta }) => (
                              <FormControlLabel
                                control={
                                  <Switch
                                    checked={
                                      Boolean(input.value) ||
                                      Boolean(input.checked)
                                    }
                                    value={Boolean(input.value)}
                                    onChange={(event) => {
                                      input.onChange(event.target.checked);
                                    }}
                                    inputProps={{
                                      "aria-label": "controlled",
                                    }}
                                  />
                                }
                                label={"Print KOT and BILL together on settle/finish order button?"}
                              />
                            )}
                          </Field>
                          <br></br>
                          <Field name="printing_setting.IS_MOBILE_PRINT_VIA_IMAGE">
                            {({ input, meta }) => (
                              <FormControlLabel
                                control={
                                  <Switch
                                    checked={
                                      Boolean(input.value) ||
                                      Boolean(input.checked)
                                    }
                                    value={Boolean(input.value)}
                                    onChange={(event) => {
                                      input.onChange(event.target.checked);
                                    }}
                                    inputProps={{
                                      "aria-label": "controlled",
                                    }}
                                  />
                                }
                                label={"Configure print through Image in mobile?"}
                              />
                            )}
                          </Field>
                          <br></br>
                          <Field name="printing_setting.PRINT_BILL_HEADER">
                            {({ input, meta }) => (
                              <FormControl
                                className={classes.formcontrolwidth}
                              >
                                <TextInputMui
                                  {...input}
                                  multiline={true}
                                  minRows={2}
                                  type="text"
                                  label={"Print Bill Header Text"}
                                  variant="outlined"
                                  error={
                                    meta.error && meta.touched ? true : false
                                  }
                                />
                                {meta.error && meta.touched && (
                                  <span style={{ color: "red" }}>
                                    {meta.error}
                                  </span>
                                )}
                              </FormControl>
                            )}
                          </Field>
                          <br></br>
                          <Field name="printing_setting.PRINT_BILL_FOOTER">
                            {({ input, meta }) => (
                              <FormControl
                                className={classes.formcontrolwidth}
                              >
                                <TextInputMui
                                  {...input}
                                  multiline={true}
                                  minRows={2}
                                  type="text"
                                  label={"Print Bill Footer Text"}
                                  variant="outlined"
                                  error={
                                    meta.error && meta.touched ? true : false
                                  }
                                />
                                {meta.error && meta.touched && (
                                  <span style={{ color: "red" }}>
                                    {meta.error}
                                  </span>
                                )}
                              </FormControl>
                            )}
                          </Field>
                          <br></br>
                          <Field name="printing_setting.PRINT_BILL_TEMPLATE">
                            {({ input, meta }) => (
                              <FormControl
                                className={classes.formcontrolwidth}
                              >
                                <TextInputMui
                                  {...input}
                                  select
                                  label={"Print Bill Template"}
                                  variant="outlined"
                                  error={
                                    meta.error && meta.touched ? true : false
                                  }
                                >
                                  <MenuItem value={"DEFAULT"}>
                                    {"Default Template"}
                                  </MenuItem>
                                  <MenuItem value={"TEMPLATE_DESIGN_1"}>
                                    {"TEMPLATE_DESIGN_1"}
                                  </MenuItem>
                                  <MenuItem value={"TEMPLATE_DESIGN_2"}>
                                    {"TEMPLATE_DESIGN_2"}
                                  </MenuItem>
                                </TextInputMui>
                                {meta.error && meta.touched && (
                                  <span style={{ color: "red" }}>
                                    {meta.error}
                                  </span>
                                )}
                              </FormControl>
                            )}
                          </Field>
                          <br></br>
                          <Field name="printing_setting.PRINTER_MARGIN">
                            {({ input, meta }) => (
                              <FormControl
                                className={classes.formcontrolwidth}
                              >
                                <TextInputMui
                                  {...input}
                                  type="text"
                                  label={'Printer Margin( Eg. 0 0 0 0)'}
                                  variant="outlined"
                                  error={
                                    meta.error && meta.touched
                                      ? true
                                      : false
                                  }
                                />
                                {meta.error && meta.touched && (
                                  <span style={{ color: "red" }}>
                                    {meta.error}
                                  </span>
                                )}
                              </FormControl>
                            )}
                          </Field>
                        </Grid>
                      </Grid>
                    </GridContainer>
                    {/* Mobile print related changed to here */}
                    <br></br>
                    <h5 className="m-auto p-3">Mobile Print Configuration Section</h5>
                    <br></br>
                    <GridContainer spacing={1}>
                      <GridItem xs={12} sm={12} md={4} >
                        <Field name="mobile_printing_setting.PRINTER_NAME">
                          {({ input, meta }) => (
                            <FormControl
                              className={classes.formcontrolwidth}
                            >
                              <TextInputMui
                                {...input}
                                type="text"
                                label="Mobile Printer Name *"
                                variant="outlined"
                                error={
                                  meta.error && meta.touched
                                    ? true
                                    : false
                                }
                              />
                              {meta.error && meta.touched && (
                                <span style={{ color: "red" }}>
                                  {meta.error}
                                </span>
                              )}
                            </FormControl>
                          )}
                        </Field>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={4} >
                        <Field name="mobile_printing_setting.PRINTER_SIZE">
                          {({ input, meta }) => (
                            <FormControl
                              className={classes.formcontrolwidth}
                            >

                              <TextInputMui
                                {...input}
                                type="text"
                                label="Mobile Printer Size *"
                                variant="outlined"
                                error={
                                  meta.error && meta.touched
                                    ? true
                                    : false
                                }
                              />
                              {meta.error && meta.touched && (
                                <span style={{ color: "red" }}>
                                  {meta.error}
                                </span>
                              )}
                            </FormControl>
                          )}
                        </Field>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={4} >
                        <Field name="mobile_printing_setting.PRINTER_TYPE">
                          {({ input, meta }) => (
                            <FormControl
                              className={classes.formcontrolwidth}
                            >
                              <TextInputMui
                                {...input}
                                select
                                label="Mobile Printer Type *"
                                variant="outlined"
                                error={
                                  meta.error && meta.touched
                                    ? true
                                    : false
                                }
                              >
                                <MenuItem value={"DEFAULT"}>
                                  Default
                                </MenuItem>
                                <MenuItem value={"bluetooth"}>
                                  Bluetooth
                                </MenuItem>
                                <MenuItem value={"usb"}>
                                  USB
                                </MenuItem>
                                <MenuItem value={"ip"}>
                                  IP Address
                                </MenuItem>
                              </TextInputMui>
                              {meta.error && meta.touched && (
                                <span style={{ color: "red" }}>
                                  {meta.error}
                                </span>
                              )}
                            </FormControl>
                          )}
                        </Field>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={4} >
                        <Field name="mobile_printing_setting.PRINTER_END_SPACE">
                          {({ input, meta }) => (
                            <FormControl
                              className={classes.formcontrolwidth}
                            >
                              <TextInputMui
                                {...input}
                                type="text"
                                label="Printer End Space"
                                variant="outlined"
                                error={
                                  meta.error && meta.touched
                                    ? true
                                    : false
                                }
                              />
                              {meta.error && meta.touched && (
                                <span style={{ color: "red" }}>
                                  {meta.error}
                                </span>
                              )}
                            </FormControl>
                          )}
                        </Field>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={4} >
                        <Field name="mobile_printing_setting.KITCHEN_IP">
                          {({ input, meta }) => (
                            <FormControl
                              className={classes.formcontrolwidth}
                            >
                              <TextInputMui
                                {...input}
                                type="text"
                                label="Kitchen Printer Ip Address"
                                variant="outlined"
                                error={
                                  meta.error && meta.touched
                                    ? true
                                    : false
                                }
                              />
                              {meta.error && meta.touched && (
                                <span style={{ color: "red" }}>
                                  {meta.error}
                                </span>
                              )}
                            </FormControl>
                          )}
                        </Field>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={4} >
                        <Field name="mobile_printing_setting.BILL_IP">
                          {({ input, meta }) => (
                            <FormControl
                              className={classes.formcontrolwidth}
                            >
                              <TextInputMui
                                {...input}
                                type="text"
                                label="Bill Printer IP Address"
                                variant="outlined"
                                error={
                                  meta.error && meta.touched
                                    ? true
                                    : false
                                }
                              />
                              {meta.error && meta.touched && (
                                <span style={{ color: "red" }}>
                                  {meta.error}
                                </span>
                              )}
                            </FormControl>
                          )}
                        </Field>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={4} >
                        <Field name="PRINT_BILL_HEADER">
                          {({ input, meta }) => (
                            <FormControl
                              className={classes.formcontrolwidth}
                            >
                              <TextInputMui
                                {...input}
                                type="text"
                                label="Mobile printer header info"
                                variant="outlined"
                                error={
                                  meta.error && meta.touched
                                    ? true
                                    : false
                                }
                              />
                              {meta.error && meta.touched && (
                                <span style={{ color: "red" }}>
                                  {meta.error}
                                </span>
                              )}
                            </FormControl>
                          )}
                        </Field>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={4} >
                        <Field name="PRINT_BILL_FOOTER">
                          {({ input, meta }) => (
                            <FormControl
                              className={classes.formcontrolwidth}
                            >
                              <TextInputMui
                                {...input}
                                type="text"
                                label="Mobile printer footer info"
                                variant="outlined"
                                error={
                                  meta.error && meta.touched
                                    ? true
                                    : false
                                }
                              />
                              {meta.error && meta.touched && (
                                <span style={{ color: "red" }}>
                                  {meta.error}
                                </span>
                              )}
                            </FormControl>
                          )}
                        </Field>
                      </GridItem>
                    </GridContainer>

                    <Stack>
                      <RoleBasedGuard
                        resource={RESOURCE.PROFILE}
                        action={ACTION.UPDATE}
                      >
                        <Button
                          color="primary"
                          type="submit"
                          variant="contained"
                        >
                          {isUpdating ? "..." : "Save Changes"}
                        </Button>
                      </RoleBasedGuard>
                    </Stack>

                  </Stack>
                </Stack>
              </Card>
            </Container>
          </form>
        )}
      />
    </Root>)
  );
}
// label={t("settings-shop-lang-label")}
// STORE_LANGUAGE