import React from "react";
import moment from "moment";

import "assets/css/print.css";
import { Phone } from "@mui/icons-material";

export default class PrintComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentDateTime: moment(Date().toLocaleString()).format(
        "DD/MM/YYYY hh:mm:ss",
      ),
    };
  }
  
  
  render() {
    return this.props.billingData[0] && this.props.customerDetails ? (
      <>
        <div className="print-container">
          {this.props?.billingData[0]?.logo && (
            <div style={{ textAlign: "center" }}>
              <img
                src={this.props?.billingData[0]?.logo}
                style={{ height: "50px", width: "50px" }}
                alt="logo"
              ></img>
            </div>
          )}
          <p
            style={{
              textAlign: "center",
              fontSize: "24px",
              fontWeight: "900",
              marginBottom: "4px",
            }}
          >
            {this.props?.billingData[0]?.name}
          </p>
          <p
            style={{
              textAlign: "center",
              fontSize: "15px",
              fontWeight: "900",
              marginBottom: "3px",
              borderBottom: "1px solid black",
            }}
          >
            {this.props?.billingData[0]?.address} {". "}
            <Phone style={{ fontSize: "15px" }} />{" "}
            {this.props?.billingData[0]?.phone}
          </p>
          {this.props.customerDetails?.customer && (
            <h6 style={{ textAlign: "left" }}>
              Name : {this.props.customerDetails?.customer}
            </h6>
          )}
          <h6>Date: {this.state?.currentDateTime}</h6>
          {this.props.customerDetails?.phone && (
            <h6 style={{ textAlign: "left" }}>
              Phone No : {this.props.customerDetails?.phone}
            </h6>
          )}
          <h6 style={{ textAlign: "left", fontWeight: "900" }}>
            Bill/Token No:{" "}
            {parseInt(this.props?.updatedOrderResData?.token_no) ||
              parseInt(this.props?.printData?.token_no) ||
              parseInt(this.props?.printData?.token)}
            {" ("}
            {this.props?.printData?.order_type} {") "}
          </h6>
          <table className="mytable responsive" style={{ textAlign: "left" }}>
            <thead>
              <tr>
                {/* <th className="quantity">No</th> */}
                <th className="itemName">ITEM</th>
                <th className="quantity">QTY</th>
                <th className="price">AMT.</th>
              </tr>
            </thead>
            <tbody>
              {this.props.foodItemListArray.map((item, index) => {
                const addOns = JSON.parse(item?.addons || "[]");
                console.log(item, addOns, "AddOns")
                return (
                  <>
                  <tr
                    style={{
                      borderBottom: addOns.length ? "none" : "1px solid black",
                    }}
                    key={`print-${index}`}
                  >
                    <td className="itemName">
                      {item.fooditem?.name || item?.food_item_id?.label}
                      <span style={{ fontSize: "14px" }}>
                        {"("} {item?.fooditem?.category?.name || item?.category_id?.label} {")"}
                      </span>
                    </td>
                    <td className="quantity">{item?.qty}</td>
                    <td className="price">
                      {this.props.shopSettingData?.CURRENCY_CODE} {parseFloat(item?.qty * item?.price).toFixed(2)}
                    </td>
                  </tr>
                
                  {addOns?.map((addOne, addIndex) => (
                    <tr
                      key={`print-add-${addIndex}`}
                      className={addIndex === addOns.length - 1 ? "" : "no-border"}
                      style={{
                        borderBottom: addIndex === addOns.length - 1 ? "1px solid black" : "none",
                      }}
                    >
                      <td className="itemName">{addOne?.name} (AddOns)</td>
                      <td className="quantity">-</td>
                      <td className="price">
                        {this.props.shopSettingData?.CURRENCY_CODE} {addOne?.price}
                      </td>
                    </tr>
                  ))}
                </>                
                );
              })}
            </tbody>
          </table>
          {this.props.subTotal != this.props?.netTotal && (
            <h6 style={{ textAlign: "right" }}>
              Sub Total : {this.props.shopSettingData?.CURRENCY_CODE} {parseFloat(this.props.subTotal)}
            </h6>
          )}

          {parseFloat(this.props?.printData?.tax) > 0 && (
            <p style={{ textAlign: "right" }}>
              {" "}
              Tax : {this.props.shopSettingData?.CURRENCY_CODE} {parseFloat(this.props?.printData?.tax)}
            </p>
          )}
          {this.props.customerDetails?.discount && (
            <h6 style={{ textAlign: "right" }}>
              Discount :{" "}
              {this.props?.customerDetails?.discountSignValue == null ||
              this.props?.customerDetails?.discountSignValue === "INR"
                ? this.props.shopSettingData?.CURRENCY_CODE
                : this.props.customerDetails?.discount_sign}{" "}
              {this.props?.customerDetails?.discount}{" "}
            </h6>
          )}
          <h6
            style={{
              textAlign: "right",
              backgroundColor: "#eee",
              padding: "2px 4px",
            }}
          >
            Net Total : {this.props.shopSettingData?.CURRENCY_CODE} {parseFloat(this.props?.netTotal)}{" "}
          </h6>
          <h6
            style={{
              textAlign: "center",
              borderTop: "1px solid black",
              marginBottom: "2px",
            }}
          >
            <b> Thank you. Visit Again </b>
          </h6>
          {/* <p style={{textAlign:'center'}}><b>For any software related query <br></br> <Phone /> +91 7990488965, +91 9033033858 <br></br> <Language/> www.servofeat.com</b></p> */}
          {/* <h6 style={{textAlign:'center'}}><b> THANK YOU. VISIT AGAIN </b></h6>
                <h6 style={{textAlign:'center'}}>Phone: {this.props?.billingData[0]?.phone}</h6>
                <h6 style={{textAlign:'center'}}>Email: {this.props?.billingData[0]?.email}</h6>
                <img alt="" src={urlQRCode} style={{width:'50%'}}></img>                
                <p style={{textAlign:'center', fontSize: '11px'}}>{this.props?.billingData[0]?.website}</p>   
                <p style={{textAlign:'center'}}><b>For any software related query <br></br> <Phone /> +91 7990488965, +91 9033033858 <br></br> <Language/> www.servofeat.com</b></p>
                <p style={{textAlign:'center'}}><b>"Time to serving for smart india mission"</b></p>                           */}
        </div>
      </>
    ) : (
      <p>There's no billing data</p>
    );
  }
}
