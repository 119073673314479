import { useEffect } from "react";
import { Container, Tab, Box, Tabs } from "@mui/material";
import { PATH_DASHBOARD } from "../../routes/paths";
import useTabs from "../../hooks/useTabs";
import Page from "../../components/Page";
import Iconify from "../../components/Iconify";
// import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import AccountChangePassword from "sections/@dashboard/user/AccountChangePassword";
import AccountTheme from "sections/@dashboard/user/AccountTheme";
import { AccountGeneral } from "sections/@dashboard/user";
import UserProfile from "./UserProfile";
import ShopInfo from "./ShopInfo";
// import Settings from "sections/@dashboard/user/Settings";
import { Settings as SettingIcon, ColorLensOutlined } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
//context
import {
  useLayoutDispatch,
  addCurrentScreenTitle,
} from "context/LayoutContext";
import { Settings } from "sections/@dashboard/user";
import { useTranslation } from "react-i18next";

export default function UserAccount() {
  const { currentTab, onChangeTab } = useTabs("Shop");
  const { t } = useTranslation();
  const theme = useTheme();
  const layoutDispatch = useLayoutDispatch();

  useEffect(() => {
    addCurrentScreenTitle(layoutDispatch, { currentScreenTitle: "Settings" });
    return () => {
      addCurrentScreenTitle(layoutDispatch, { currentScreenTitle: "" });
    };
  }, []);

  const ACCOUNT_TABS = [
    {
      label: t("shop-profile-title"),
      value: "Shop",
      icon: (
        <Iconify
          icon={"mdi:store"}
          width={20}
          height={20}
          style={{ marginRight: "5px" }}
        />
      ),
      component: <ShopInfo />,
    },
    {
      label: t("billing-profile-title"),
      value: "Billing",
      icon: (
        <Iconify
          icon={"ic:round-account-box"}
          width={20}
          height={20}
          style={{ marginRight: "5px" }}
        />
      ),
      component: <UserProfile />,
    },
    {
      label: t("settings-profile-title"),
      value: "Settings",
      icon: (
        <SettingIcon
          icon={"eva:bell-fill"}
          width={20}
          height={20}
          style={{ marginRight: "5px" }}
        />
      ),
      component: <Settings />,
    },
    {
      label: t("change-password-title"),
      value: "ChangePassword",
      icon: (
        <Iconify
          icon={"ic:round-vpn-key"}
          width={20}
          height={20}
          style={{ marginRight: "5px" }}
        />
      ),
      component: <AccountChangePassword />,
    },
    {
      label: t("theme-settings-prfile-title"),
      value: "ThemeSetting",
      icon: (
        <ColorLensOutlined
          width={20}
          height={20}
          style={{ marginRight: "5px" }}
        />
      ),
      component: <AccountTheme />,
    },
  ];

  return (
    <Page title={t("serofeat-account-setting-title")}>
      <Container className="profile-container">
        <Tabs
          allowScrollButtonsMobile
          variant="scrollable"
          scrollButtons="auto"
          value={currentTab}
          iconPosition="start"
          onChange={onChangeTab}
        >
          {ACCOUNT_TABS.map((tab) => (
            <Tab
              disableRipple
              key={tab.value}
              label={tab.label}
              icon={tab.icon}
              value={tab.value}
              sx={{
                px: 2,
                marginRight: "20px",
                flexDirection: "row",
                color: theme.palette.grey[600],
                fontWeight: "400 !important",
                textTransform: "none",
                minHeight: "48px",
                padding: "0",
                // bgcolor: theme.palette.grey[200],
                fontFamily: 'Oxygen, "MuseoSansW01-Rounded500" !important',
                fontSize: "1rem !important"
              }}
            />
          ))}
        </Tabs>
        {ACCOUNT_TABS.map((tab) => {
          const isMatched = tab.value === currentTab;
          return isMatched && <Box key={tab.value}>{tab.component}</Box>;
        })}
      </Container>
    </Page>
  );
}
