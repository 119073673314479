import React, { useEffect, useState } from "react";
import { styled } from '@mui/material/styles';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Card } from "react-bootstrap";
import { Button } from "@mui/material";
import { Save as SaveIcon } from "@mui/icons-material";
import { toast } from "react-hot-toast";
import ListIcon from "@mui/icons-material/List";
import { updateFoodItemByOrder } from "services/foodItemMenu.service";
import { fetchFoodMealList } from "state/thunk-async";
import { useDispatch, useSelector } from "react-redux";

const PREFIX = 'PriorityFoodMenu';

const classes = {
  header: `${PREFIX}-header`,
  sticky: `${PREFIX}-sticky`,
  stickyHeader: `${PREFIX}-stickyHeader`
};

const StyledCard = styled(Card)({
  [`& .${classes.header}`]: {
    marginLeft: "20px",
    fontSize: "15px",
    fontFamily: "Roboto",
  },
  [`& .${classes.sticky}`]: {
    position: "sticky",
    padding: '10px'
    
  },
  [`& .${classes.stickyHeader}`]: {
    position: "sticky",
    top: 0,
    zIndex: "99999999999999",
    background: "#fff",
    padding: "14px",
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  }
});

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const grid = 12;

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: "none",
  color: isDragging ? "#f4f6f8" : "#637381",
  padding: "3px 12px",
  margin: `0px 0px 4px`,
  background: isDragging ? "#637381" : "#f4f6f8",
  ...draggableStyle,
});

const PriorityFoodMenu = (props) => {

  const dispatch = useDispatch();
  const { drawerClose, foodItemMenuDataList } = props;
  const [items, setItems] = useState([]);
  const shopSettingData = useSelector((state) => state.shopSettingReducer.shopSettingData);

  const [submittingBtnProcess, setSubmittingBtnProcess] = useState(false);
  useEffect(() => {
    setItems(foodItemMenuDataList);
  }, []);

  const onSubmit = () => {
    setSubmittingBtnProcess(true);
    updateFoodItemByOrder(items)
      .then((res) => {
        if (res) {
          setSubmittingBtnProcess(false);
          toast.success("FoodMenu display priority is changed successfully.");
          dispatch(fetchFoodMealList());
          drawerClose();
        } else {
          toast.success("Failed to change FoodMenu display priority.");
        }
      })
      .catch((err) => {
        setSubmittingBtnProcess(false);
        toast.success("Failed to change FoodMenu display priority.");
      });
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const reorderedItems = reorder(
      items,
      result.source.index,
      result.destination.index,
    );

    console.log({ reorderedItems });
    setItems(reorderedItems);
  };

  return (
    <StyledCard>
        <div className={classes.stickyHeader}>
          <div>
          <h5>Food-Menu</h5>
          </div>
            <div>
              <Button
                variant="contained"
                onClick={onSubmit}
                color="primary"
                type="submit"
                startIcon={<SaveIcon />}
              >
                {submittingBtnProcess ? "Processing.." : "Save"}
              </Button>
            </div>
        </div>
        <div className={classes.sticky}>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {items.map((item, index) => (
                    <Draggable
                      key={item?.name + item?.id}
                      draggableId={item?.name + item?.id}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style,
                          )}
                        >
                          <div className="d-flex align-items-center justify-content-between">
                            <div><ListIcon /> {item?.name}</div>
                            <div>{item?.category?.name}</div>
                            <div>{`${shopSettingData?.CURRENCY_CODE}` + parseFloat(item.price).toFixed(2)}</div>
                          </div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
    </StyledCard>
  );
};

export default PriorityFoodMenu;
