import CustomHtmlToolTip from 'components/Tooltip';
import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import OrderTotalAccordion from './OrderTotalAccordion';
import { styled } from '@mui/material/styles';
import { Checkbox, FormControl, FormControlLabel, Grid, InputAdornment, MenuItem, IconButton, Select } from '@mui/material';
import { Field } from 'react-final-form';
import TextInputMui from 'components/TextInputMui';
import clsx from "clsx";
import {
    Close,
    Edit,
    Info as InfoIcon,
} from "@mui/icons-material";
import { useTranslation } from 'react-i18next';
import Label from 'components/Label';
import { BootstrapTooltip } from 'utility/helpers';
import { toast } from 'react-hot-toast';
const PREFIX = 'OrderDetails';

const classes = {
    root: `${PREFIX}-root`,
    arrow: `${PREFIX}-arrow`,
    tooltip: `${PREFIX}-tooltip`,
    paper: `${PREFIX}-paper`,
    formcontrolwidth: `${PREFIX}-formcontrolwidth`
};
const Root = styled('div')((
    {
        theme
    }
) => ({
    [`&.${classes.root}`]: {
        height: '100%'
    },
    [`& .${classes.arrow}`]: {
        color: theme.palette.common.black,
    },

    [`& .${classes.tooltip}`]: {
        backgroundColor: theme.palette.common.black,
    },
    [`& .${classes.paper}`]: {
        padding: theme.spacing(2),
        textAlign: "center",
        color: theme.palette.text.secondary,
    },
    [`& .${classes.formcontrolwidth}`]: {
        width: "100%"
    }
}));

export default function TotalBillSettlementBox(props) {
    const {
        subTotal,
        parcelField,
        taxAmountValue,
        taxAmountPercentage,
        taxValue,
        discountSignValue,
        additionalCharge,
        values,
        finalBill,
        calculatedDiscount,
        discountValue,
        setAdditionalCharge,
        setDiscountValue,
        setDiscountSignValue,
        setParcelField,
        setTaxValue,
        setFinalBill,
        tableId
    } = props
    const billingData = useSelector((state) => state.api.billing);
    const shopSettingsData = useSelector((state) => state.shopSettingReducer.shopSettingData);
    const paymentTypeOption = useSelector((state) => state.api.paymentTypeData);
    const [editBill, setEditBill] = useState(false);

    const { t } = useTranslation();
    const editBillDetails = () => {
        setEditBill(!editBill);
    }

    return (
        <Root className={classes.root}>
            <div className="">
                <div className={classes.root}>


                    {
                      shopSettingsData?.ORDER_SUMMERY_POPUP_BILL &&  <div style={{
                                textAlign: 'right'
                            }}>
                                <IconButton aria-label="edit"
                                    style={{ fontSize: '15px' }}
                                    onClick={editBillDetails}>
                                    {
                                        editBill ?
                                            <>    <Close style={{ fontSize: '15px' }} />  Close</>
                                            :
                                            <>    <Edit style={{ fontSize: '15px' }} /> Edit Bill</>
                                    }

                                </IconButton>
                            </div>
                    }
                    {
                        editBill ?
                            <>
                                <Grid container spacing={1} sx={{ py: "5px" }}>
                                    <Grid item xs={6}>
                                        <Field name="bill_type">
                                            {({ input, meta }) => (
                                                <>
                                                    <FormControl
                                                        className={clsx(
                                                            classes.formcontrolwidth,
                                                            classes.selectBoxControl,
                                                        )}
                                                    >
                                                        <Select
                                                            native
                                                            {...input}
                                                            style={{ textAlign: "center" }}
                                                            onChange={(e) => {
                                                                input.onChange(e);
                                                            }}
                                                            variant="outlined"
                                                        >
                                                            {
                                                                paymentTypeOption && paymentTypeOption?.length > 0 ?
                                                                    paymentTypeOption?.map(data => <option value={data?.name}>{data?.name}</option>)
                                                                    :
                                                                    <option value={""}>No options. Please Add</option>
                                                            }
                                                        </Select>
                                                    </FormControl>
                                                </>
                                            )}
                                        </Field>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Field name="bill_note">
                                            {({ input, meta }) => (
                                                <FormControl
                                                    className={classes.formcontrolwidth}
                                                >
                                                    <TextInputMui
                                                        {...input}
                                                        variant="outlined"
                                                        label="Instruction Note"
                                                        placeholder={t(
                                                            "order-detail-example-label",
                                                        )}
                                                        SelectProps={{
                                                            native: true,
                                                        }}
                                                    />
                                                </FormControl>
                                            )}
                                        </Field>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={1}>
                                    {/* <Grid item xs={6}>
                                    <Field name="isParcel" type="checkbox">
                                        {({ input, meta }) => (
                                            <div
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "space-between",
                                                }}
                                            >
                                                <FormControlLabel
                                                    style={{ margin: "0px" }}
                                                    control={
                                                        <Checkbox
                                                            style={{
                                                                paddingLeft: 0,
                                                                margin: "0px",
                                                            }}
                                                            {...input}
                                                            checked={parcelField}
                                                            onChange={(e) => {
                                                                setParcelField(
                                                                    e.target.checked,
                                                                );
                                                            }}
                                                        />
                                                    }
                                                    label={t(
                                                        "sales-history-parcel-label",
                                                    )}
                                                />
                                            </div>
                                        )}
                                    </Field>
                                </Grid> */}
                                    <Grid item xs={6}>
                                        {billingData[0]?.gst_no && (
                                            <div
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <FormControlLabel
                                                    style={{
                                                        margin: "0px",
                                                        marginRight: "5px",
                                                    }}
                                                    control={
                                                        <Checkbox
                                                            style={{
                                                                paddingLeft: 0,
                                                                margin: "0px",
                                                            }}
                                                            checked={taxValue}
                                                            onChange={(e) => {
                                                                setTaxValue(
                                                                    e.target.checked,
                                                                );
                                                            }}
                                                        />
                                                    }
                                                    label={t(
                                                        "order-detail-gst-label",
                                                    )}
                                                />
                                                <BootstrapTooltip
                                                    classes={classes}
                                                    title={`Include ${taxAmountPercentage}% Tax on your sub total amount`}
                                                >
                                                    <InfoIcon />
                                                </BootstrapTooltip>
                                            </div>
                                        )}
                                    </Grid>
                                </Grid>
                                <Grid container spacing={1} sx={{ py: "5px" }}>
                                    <Grid item xs={6}>
                                        <Field name="additional_charge"
                                            type="number"
                                        >
                                            {({ input, meta }) => (
                                                <FormControl
                                                    className={classes.formcontrolwidth}
                                                >
                                                    <TextInputMui
                                                        {...input}
                                                        type="number"
                                                        variant="outlined"
                                                        label="Additional charge"
                                                        placeholder={`${shopSettingsData?.CURRENCY_CODE}10`}
                                                        SelectProps={{
                                                            native: true,
                                                        }}
                                                        onChange={(event) => {
                                                            if (parseFloat(event?.target?.value || 0) >= 0) {
                                                                input.onChange(event);
                                                                setAdditionalCharge(event.target.value);
                                                            } else {
                                                                toast.error("Additional charge should be greater than or equal to 0");
                                                            }
                                                        }}
                                                    />
                                                </FormControl>
                                            )}
                                        </Field>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Field name="additional_charge_reason">
                                            {({ input, meta }) => (
                                                <FormControl
                                                    className={classes.formcontrolwidth}
                                                >
                                                    <TextInputMui
                                                        {...input}
                                                        variant="outlined"
                                                        label="Additional charge reason"
                                                        placeholder={"parcel charge"}
                                                        SelectProps={{
                                                            native: true,
                                                        }}
                                                    />
                                                </FormControl>
                                            )}
                                        </Field>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={1} sx={{ py: "5px" }}>
                                    <Grid item xs={3}>
                                        <Field
                                            name="discount"
                                            type="number"
                                            className={classes.formcontrolwidth}
                                        >
                                            {({ input, meta }) => (
                                                <FormControl
                                                    className={classes.formcontrolwidth}
                                                >
                                                    <TextInputMui
                                                        {...input}
                                                        type="number"
                                                        label={t("order-detail-disc-label")}
                                                        variant="outlined"
                                                        onChange={(event) => {
                                                            if (parseFloat(event?.target?.value || 0) > -1) {
                                                                if (parseFloat(event?.target?.value || 0) < parseFloat(finalBill || 0)) {
                                                                    input.onChange(event);
                                                                    setDiscountValue(event.target.value);
                                                                } else {
                                                                    input.onChange(event);
                                                                    setDiscountValue(event.target.value);
                                                                    toast.error("Discount should be less then final bill");
                                                                }
                                                            } else {
                                                                toast.error("Discount should be greater then 0");
                                                            }
                                                        }}
                                                        error={meta.error && meta.touched ? true : false}
                                                    />
                                                    <br />
                                                </FormControl>
                                            )}
                                        </Field>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Field
                                            name="discount_sign"
                                            className={classes.formcontrolwidth}
                                            defaultValue={discountSignValue}
                                        >
                                            {({ input, meta }) => (
                                                <FormControl
                                                    className={classes.formcontrolwidth}
                                                >
                                                    <Select
                                                        native
                                                        {...input}
                                                        style={{ textAlign: "center" }}
                                                        onChange={(e) => {
                                                            input.onChange(e);
                                                            setDiscountSignValue(
                                                                e.target.value,
                                                            );
                                                        }}
                                                        variant="outlined"
                                                    >
                                                        <option value="INR">{shopSettingsData?.CURRENCY_CODE}</option>
                                                        <option value="%">%</option>
                                                    </Select>
                                                    <br />
                                                </FormControl>
                                            )}
                                        </Field>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormControl
                                            className={classes.formcontrolwidth}
                                        >
                                            <TextInputMui
                                                value={finalBill}
                                                onChange={(event) => {
                                                    if (
                                                        parseFloat(
                                                            event?.target?.value || 0,
                                                        ) > -1
                                                    ) {
                                                        setFinalBill(
                                                            event.target.value,
                                                        );
                                                    } else if (
                                                        parseFloat(
                                                            event?.target?.value,
                                                        ) < 0
                                                    ) {
                                                        toast.error(
                                                            "Final bill should be greater than 0",
                                                        );
                                                    }
                                                }}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            {shopSettingsData?.CURRENCY_CODE}
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                type="number"
                                                label={t(
                                                    "order-detail-new-amount-label",
                                                )}
                                                variant="outlined"
                                            />
                                            <br />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </>
                            : <div className="order-total-section">
                                <div className="d-flex align-item-center justify-content-end">
                                    {t("sub-total-title")}:
                                    <div style={{ width: "25%" }}>
                                        {shopSettingsData?.CURRENCY_CODE} {subTotal}
                                    </div>
                                </div>
                                <div className="d-flex align-item-center justify-content-end" style={{ color: 'green' }}>
                                    {t("discount-title")} {discountSignValue === "%" && (values?.discount ? "(" + values?.discount + " " + discountSignValue + ")" : "")}:
                                    <div style={{ width: "25%" }}>
                                        {discountSignValue === "%"
                                            ? `${shopSettingsData?.CURRENCY_CODE} ${calculatedDiscount || 0.0} `
                                            : `${shopSettingsData?.CURRENCY_CODE} ${discountValue || 0.0}`}
                                    </div>
                                </div>
                                <div className="d-flex align-item-center justify-content-end">
                                    {t("additional-charge-title")} <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                        }}
                                    >
                                        <CustomHtmlToolTip
                                            interactive
                                            placement="top"
                                            arrow
                                            enterDelay={0}
                                            title={
                                                <>
                                                    <div>
                                                        {values?.additional_charge_reason}
                                                    </div>
                                                </>
                                            }
                                        >
                                            <InfoIcon
                                                style={{ fontSize: "1rem" }}
                                            />
                                        </CustomHtmlToolTip>
                                    </div>:
                                    <div style={{ width: "25%" }}>
                                        {shopSettingsData?.CURRENCY_CODE} {parseFloat(additionalCharge).toFixed(2)}
                                    </div>
                                </div>
                                {billingData[0]?.gst_no && taxValue && (
                                    <div className="d-flex align-item-center justify-content-end">
                                        {/* {t("tax-title")} <CustomHtmlToolTip
                                            interactive
                                            placement="top"
                                            arrow
                                            enterDelay={0}
                                            title={
                                                <>
                                                    <div>
                                                        <b>CGST(2.5%) : </b>
                                                    </div>
                                                    <div>
                                                        <b>SGST(2.5%) : </b>
                                                    </div>
                                                </>
                                            }
                                        >
                                            <InfoIcon
                                                style={{ fontSize: "1rem" }}
                                            />
                                        </CustomHtmlToolTip> : */}
                                        <div style={{ width: "25%" }}>
                                            {shopSettingsData?.CURRENCY_CODE}  {taxAmountValue}
                                            {/* {(parseFloat(subTotal) * 2.5) / 100 + (parseFloat(subTotal) * 2.5) / 100} DHARMIK 25th JAN*/}
                                        </div>
                                    </div>
                                )}
                               
                                <div
                                    className="total-payble-text"
                                    style={{
                                        display: "flex",
                                        fontSize: "18px",
                                        alignItems: "center",
                                        justifyContent: "right",
                                    }}
                                >
                                    <b>
                                        {t(
                                            "order-detail-total-payable-amount-label",
                                        )}
                                        : &nbsp;
                                    </b>
                                    <Label
                                        variant={"ghost"}
                                        sx={{ fontSize: "15px" }}
                                        color={"info"}
                                    >
                                        {shopSettingsData?.CURRENCY_CODE} {parseFloat(finalBill).toFixed(2)}
                                    </Label>

                                </div>
                                {shopSettingsData?.ROUND_OFF_PRICE && <span className='round-off-price-text'>(Round Off Price)</span>}
                            </div>
                    }
                </div>
            </div>
        </Root>
    )
}
