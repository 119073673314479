import React, { useEffect, useState } from "react";
import { styled } from '@mui/material/styles';
// Importing toastify module
import { Row, Col } from "react-bootstrap";
import MaterialTable, {MTableToolbar} from "material-table";
import {
  FormControl,
  TextField,
  MenuItem,
  Button,
  Stack,
} from "@mui/material";
import jsPDF from "jspdf";
import "jspdf-autotable";
import PageLoaderBlock from "components/PageLoaderBlock";
import { getSalesReportData } from "services/report.service";
import noFoundImg from "../../assets/img/no-data-found.jpg";
// API Calls
import { useSelector } from "react-redux";
import moment from "moment";
import TextInputMui from "components/TextInputMui";
import SkeletonLoader from "components/Loader/SkeletonLoader";
import NotFoundComponent from "components/NotFoundComponent";
import { RESOURCE } from "common/constant";
//context
import {
  useLayoutDispatch,
  addCurrentScreenTitle,
} from "context/LayoutContext";
import { useTheme } from "@mui/material/styles";
import Label from "components/Label";
import { useTranslation } from "react-i18next";
import CalendarWise from "./CalendarWise";

const PREFIX = 'OrderReport';

const classes = {
  cardCategoryWhite: `${PREFIX}-cardCategoryWhite`,
  cardTitleWhite: `${PREFIX}-cardTitleWhite`,
  root: `${PREFIX}-root`,
  tabel: `${PREFIX}-tabel`,
  header: `${PREFIX}-header`,
  searchbtn: `${PREFIX}-searchbtn`
};

const Root = styled('div')({
  [`& .${classes.cardCategoryWhite}`]: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  [`& .${classes.cardTitleWhite}`]: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    // fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontFamily: '"Oxygen", sans-serif',
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  [`& .${classes.root}`]: {
    width: "100%",
  },
  [`& .${classes.tabel}`]: {
    margin: "10px",
    marginTop: "0",
    "& .MuiPaper-root": {
      padding: 10,
      paddingBottom: 0,
    },
    "& tr": {
      height: 50,
      "& .MuiTableCell-root": {
        paddingLeft: "15px",
        fontSize: "14px !important",
      },
    },
  },
  [`&.${classes.header}`]: {
    backgroundColor: "white",
    boxShadow:
      "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
    padding: "10px",
    marginBottom: "10px",
  },
  [`& .${classes.searchbtn}`]: {
    fontWeight: 500,
    fontSize: "1rem",
    marginTop: "10px",
    height: "50px",
    lineHeight: "2px",
  },
});

const OrderReport = () => {
  const unit = "pt";
  const size = "A4";
  const orientation = "portrait";

  const marginLeft = 40;
  const doc = new jsPDF(orientation, unit, size);

  require("jspdf-autotable");
  const theme = useTheme();

  const [salesReportData, setSalesReportData] = useState([]);
  const [initialReportDate, setInitialReportDate] = useState({
  });

  const [filterQueryParam, setFilterQueryParam] = useState({
    order_type: "ALL",
    bill_type:  "ALL"
  });

  const [loading, setLoading] = useState(false);
  const foodCategoryLoader = useSelector(
    (state) => state.api.foodCategoryLoader,
  );
  const userPermission = useSelector((state) => state.user.userPermission);
  const shopSettingData = useSelector((state) => state.shopSettingReducer.shopSettingData);

  const layoutDispatch = useLayoutDispatch();
  const { t } = useTranslation();
  const hasExportPermission =
    userPermission.length > 0
      ? JSON.parse(
        userPermission?.find((a) => a.resource === RESOURCE.SALES_REPORT)
          ?.permission,
      )?.DOWNLOAD
      : true;

  const shopSettingListData = useSelector(
    (state) => state.shopSettingReducer.shopSettingData,
  );
  const paymentTypeOption = useSelector((state) => state.api.paymentTypeData);

  useEffect(() => { 
    addCurrentScreenTitle(layoutDispatch, {
      currentScreenTitle: t("sales-report-title-mobile-screen"),
    });
    return () => {
      addCurrentScreenTitle(layoutDispatch, { currentScreenTitle: "" });
    };
  }, []);

  const filterQueryParamFun = (filterType, filterValue) => {
      switch (filterType) {
        case "order_type":
          setFilterQueryParam({ ...filterQueryParam, order_type: filterValue })
          break;
        case "bill_type":
          setFilterQueryParam({ ...filterQueryParam, bill_type: filterValue })
          break;
        default:
          setFilterQueryParam({ order_type: "ALL", bill_type: "ALL" })
          break;
      }
  }

  const exportPDF = () => {
    doc.setFontSize(15);

    const title = `${t("sales-report-title-mobile-screen")} of ${initialReportDate?.startDate} to ${initialReportDate?.endDate}. Total Amount ${shopSettingData?.CURRENCY_CODE}  ${getCountFromOrderKey('amount').toFixed(2)}`;
    const headers = [
      [
        "Order No",
        "Customer",
        "Phone",
        "Order Date",
        "Payment Type",
        "Order Type",
        "Sub Total",
        "Tax",
        "Discount",
        "Amount"
      ],
    ];

    const data = salesReportData.map((item) => [
      item?.bill_no,
      item?.name_of_customer,
      item?.phone,
      item?.bill_date && moment(item?.bill_date).format("DD/MM/YYYY hh:mm"),
      item?.bill_types,
      item?.order_type,
      item?.sub_total,
      item?.tax || 0.0,
      item?.discount || 0.0,
      item?.amount,
    ]);

    let content = {
      startY: 50,
      head: headers,
      body: data,
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("Sales-Report.pdf");
  };


  const getCountFromOrderKey = (orderKey) => {
    const initialValue = 0;
 
    const sumWithInitial = salesReportData.map(item => item?.bill_types !== "Total amount" ? item?.[orderKey] || 0 : 0).reduce(
      (accumulator, currentValue) => parseFloat(accumulator) + parseFloat(currentValue),
      initialValue
    );
    return sumWithInitial;
  }

  const getTotalDiscount = () => {
    const initialValue = 0;
    //parseFloat(item?.tax).toFixed(2))*parseFloat(item?.discount).toFixed(2))/100
    const sumWithInitial = salesReportData.map(item =>  {
      if(item?.bill_types !== "Total amount")
      {
        if(item?.discount_sign === "%" )
        {
          return ((parseFloat(item?.sub_total || 0) + parseFloat(item?.tax || 0)) * parseFloat(item?.discount || 0).toFixed(2))/100
        }
        else {
            return parseFloat(item?.discount || 0).toFixed(2)
        }
      }
      else {
        return 0
      }
    }).reduce(
      (accumulator, currentValue) => parseFloat(accumulator) + parseFloat(currentValue),
      initialValue
    );
    console.log(sumWithInitial);
    return sumWithInitial;
  }

  return (
    <Root className={classes.header}>
      <PageLoaderBlock 
          processing={loading}
          message="Please wait, we are fetching the record">
            <CalendarWise
                dataFetchingFunction={getSalesReportData}
                setReportData={setSalesReportData}
                queryParams={filterQueryParam}
              />
            <Row>
              <Col md={2} sm={3}> 
              <FormControl style={{ width: "100%", marginTop: "10px" }}>
                <TextInputMui
                  select
                  label={t("sales-report-order-type")}
                  variant="outlined"
                  defaultValue = ""
                  labelId="demo-simple-select-label"
                  value={filterQueryParam.order_type}
                  onChange={(event) => filterQueryParamFun("order_type", event.target.value)}
                >
                  <MenuItem value="ALL">{t("all-label")}</MenuItem>
                  <MenuItem value="DINE_IN">{t("dine-in-label")}</MenuItem>
                  <MenuItem value="PICK_UP">{"Pick Up"}</MenuItem>
                  <MenuItem value="PARCEL">
                    {t("parcel-label")}
                  </MenuItem>
                </TextInputMui>
              </FormControl>
            </Col>
            <Col md={2} sm={3}>
              <FormControl style={{ width: "100%", marginTop: "10px" }}>
                <TextInputMui
                  select
                  label={t("sales-report-bill-type")}
                  variant="outlined"
                  labelId="demo-simple-select-label"
                  value={filterQueryParam.bill_type}
                  onChange={(event) => filterQueryParamFun("bill_type", event.target.value)}
                >
                    <MenuItem value={"ALL"}>ALL</MenuItem>
                    {
                      paymentTypeOption && paymentTypeOption?.length > 0 ?
                        paymentTypeOption?.map(data => <MenuItem value={data?.name}>{data?.name}</MenuItem>) 
                        :
                        <MenuItem value={""}>No options. Please Add</MenuItem>
                    }
                </TextInputMui>
              </FormControl>
            </Col>
            </Row>
            <MaterialTable
              title={
                <h5>
                  <b>{`${t("sales-report-title-mobile-screen")} Total Amount ${shopSettingData?.CURRENCY_CODE}  ${getCountFromOrderKey('amount').toFixed(2)}`} </b> 
                </h5>
              }
              columns={[
                {
                  title: "Order No",
                  field: "bill_no",
                  cellStyle: {
                    width: "5%",
                  },
                },
                {
                  title: "Customer",
                  field: "name_of_customer",
                  cellStyle: {
                    width: "12%",
                  },
                },
                {
                  title: "Phone No",
                  field: "phone",
                  cellStyle: {
                    width: "10%",
                  },
                },
                {
                  title: "Order Date",
                  field: "bill_date",
                  cellStyle: {
                    width: "10%",
                  },
                },
                {
                  title: "Payment Type",
                  field: "bill_types",
                  cellStyle: {
                    width: "10%",
                  },
                },
                {
                  title: t("sales-report-order-type"),
                  field: "order_type",
                  cellStyle: {
                    width: "10%",
                  },
                  render: (rowData) => rowData?.order_type ?
                        <div>
                          <Label
                            variant={
                              theme.palette.mode === "light" ? "ghost" : "filled"
                            }
                            color={
                              rowData?.order_type === "Dine In" ? "info" : "success"
                            }
                          >
                            {rowData?.order_type}
                          </Label>
                        </div>
                        : ""
                },
                {
                  title: "Sub total",
                  field: "sub_total",
                  cellStyle: {
                    width: "10%"
                  },
                  //render: rowData =>     <Stack>{"₹ " + rowData?.sub_total}</Stack>
                },
                {
                  title: "Tax",
                  field: "tax",
                  cellStyle: {
                    width: "7%"
                  },
                  //render: rowData =>    <Stack>{"₹ " + (rowData?.tax || 0.0)}</Stack>
                },
                {
                  title: "Discount",
                  field: "discount",
                  cellStyle: {
                    width: "7%"
                  },
                  render: (rowData) => <Stack>{rowData.bill_types !== "Total amount" && (rowData?.discount || 0.0) + "  "  + (rowData?.discount_sign ? rowData?.discount_sign : "")}</Stack>
                },
                {
                  title: "Discount Sign",
                  field: "discount_sign",
                  cellStyle: {
                    width: "7%"
                  },
                  render: (rowData) => <Stack>{rowData.bill_types !== "Total amount" && (rowData?.discount_sign ? rowData?.discount_sign : "")}</Stack>
                },
                {
                  title: t("sales-report-amount"),
                  field: "amount",
                  cellStyle: {
                    width: "25%",
                  },
                  render: (rowData) =>
                    rowData.bill_types === "Total amount" ? (
                      <Label
                        variant={
                          theme.palette.mode === "light" ? "ghost" : "filled"
                        }
                        sx={{ fontSize: '18px'}}
                        color={"info"}
                      >
                        {`${shopSettingData?.CURRENCY_CODE} ` +
                          (parseFloat(rowData?.amount) > 0
                            ? parseFloat(rowData?.amount).toFixed(2)
                            : "0.0")}
                      </Label>
                    ) : (
                      <Stack width={'130px'}>
                        {/* <Stack>Sub Total: {"₹ " + rowData?.sub_total}</Stack>
                        <Stack>Tax: {"₹ " + (rowData?.tax || 0.0)}</Stack>
                        <Stack>Discount: {rowData?.discount}</Stack> */}
                        <Stack> {`${shopSettingData?.CURRENCY_CODE} ` +
                          (parseFloat(rowData?.amount) > 0
                            ? parseFloat(rowData?.amount).toFixed(2)
                            : "0.0")}</Stack>
                      
                      </Stack>
                    ),
                },
              ]}
              data={
                salesReportData &&
                salesReportData?.map((item, index) => {
                  return {
                    bill_no: item.bill_no,
                    bill_date:
                      item?.bill_date &&
                      moment(item?.bill_date).format("DD/MM/YYYY hh:mm"),
                    bill_types: item.bill_types,
                    amount: item.amount,
                    name_of_customer: item?.name_of_customer,
                    phone: item?.phone,
                    sub_total: item?.sub_total,
                    discount:  item?.discount || 0.0,
                    tax: item?.tax,
                    discount_sign: item?.discount_sign,
                    order_type: item.order_type 
                  };
                })
              }
              options={{
                maxBodyHeight: 'calc(100vh - 240px)',
                rowStyle:  (rowData)=> rowData.bill_types === "Total amount" && {
                  color: 'green',
                  background: '#eee'
                },
                exportButton: hasExportPermission
                  ? {
                    pdf: false,
                    csv: true,
                  }
                  : false,
                exportFileName: `salesReport-${moment().format("DD-MM-YYYY")}`,
                searchFieldStyle: {
                  color: theme.palette.grey[500],
                  borderColor: "rgba(0, 0, 0, 0.23)",
                  border: "0.8px solid",
                  borderRadius: "6px",
                  padding: "10px",
                  "&:before": {
                    borderBottom: "0px!important",
                  },
                  "&:after": {
                    borderBottom: "0px!important",
                  },
                },
                headerStyle: {
                  color: theme.palette.grey[600],
                  backgroundColor: theme.palette.grey[200],
                },
                pageSize: 10,
                paging: false,
                emptyRowsWhenPaging: false, //to make page size fix in case of less data rows
                pageSizeOptions: [10, 20, 30],
                sorting: true,
                actionsColumnIndex: -1,
              }}
              localization={{
                toolbar: {
                  searchPlaceholder: t("search"),
                },
                body: {
                  emptyDataSourceMessage: foodCategoryLoader ? (
                    <SkeletonLoader />
                  ) : (
                    <NotFoundComponent imgUrl={noFoundImg}>
                      <p style={{ fontWeight: 600 }}>
                        {t("record-not-found-message")}
                      </p>
                    </NotFoundComponent>
                  ),
                },
              }}
              components={{
                Toolbar: (props) => (
                  <>
                    <div className="custom-material-table-toolbar">
                    <MTableToolbar {...props} />
                  </div>
                    <div className="ml-4 "> Approx Calculation:</div>
                    <div className="d-flex align-item-center ml-4 justify-content-between pb-2">
                      <div><b>Sub Total for All:</b>{shopSettingData?.CURRENCY_CODE} {getCountFromOrderKey("sub_total").toFixed(2)}</div>
                      <div><b>Total Tax Collected:</b>{shopSettingData?.CURRENCY_CODE} {getCountFromOrderKey("tax").toFixed(2)}</div>
                      <div><b>Total Discount Given:</b>{shopSettingData?.CURRENCY_CODE} {getTotalDiscount().toFixed(2)}</div>
                      <div><b>Total Amount:</b>{shopSettingData?.CURRENCY_CODE} {getCountFromOrderKey("amount").toFixed(2)}</div>
                  </div>
                  </>
                ),
              }}
            />
      </PageLoaderBlock>
    </Root>
  );
};

export default OrderReport;
