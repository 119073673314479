import React, { useRef, useState, useCallback } from "react";
import { styled } from '@mui/material/styles';
import { Modal, Tabs, Tab, ListGroup, Table } from "react-bootstrap";
import moment from "moment";
import { useSelector } from "react-redux";
import {
  getShopSettingFromLocalStorage,
  checkIfElectronLoaded,
  getBase64StringFromDataURL,
  checkPrinterJson, isJson
} from "utility/helpers";
import { toast } from "react-hot-toast";
import ReactToPrint from "react-to-print";
import html2canvas from "html2canvas";
import { Print as PrintIcon, Info as InfoIcon, CallToAction, Cancel, Done } from "@mui/icons-material";
import ButtonIcon from "components/ButtonComponent/ButtonIcon";
import { useTheme } from "@mui/material/styles";
import Label from "components/Label";
import { Box, Button } from "@mui/material";
import ElectronPrintFromOrder from "pages/PrintSection/ElectronPrintFromOrder";
import { PRINTER_TYPE, ORDER_DETAILS_BUTTON } from "common/constant";
import PrintComponent from "../PrintSection/PrintComponent";
import KOTPrintComponent from "../PrintSection/KOTPrintComponent";
import {
  usbPrinter,
  bluetoothPrinter,
  tcpPrinter,
  usbPrinterForKOT,
  bluetoothPrinterForKOT,
  mobileBillPrintViaImage,
  tcpPrinterForKOT,
  getKOTHtmlTemplate,
  getHtmlTemplate
} from "utility/posPrinterReceipt";
// import { getHtmlTemplate, getKOTHtmlTemplate } from "../../utility/posPrinterReceipt";
import { isAndroid, isBrowser, isDesktop, isElectron, isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import configData from "config";
import RoleBasedGuard from "guards/RoleBasedGuard";
import { RESOURCE } from "common/constant";
import { ACTION } from "common/constant";

const PREFIX = 'OrderViewPopup';

const classes = {
  badge: `${PREFIX}-badge`,
  listitem: `${PREFIX}-listitem`,
  phonetitle: `${PREFIX}-phonetitle`,
  phonenumber: `${PREFIX}-phonenumber`,
  billbtn: `${PREFIX}-billbtn`,
  newbadge: `${PREFIX}-newbadge`,
  tablehead: `${PREFIX}-tablehead`,
  item: `${PREFIX}-item`,
  avatar: `${PREFIX}-avatar`,
  billDetail: `${PREFIX}-billDetail`
};

const StyledModal = styled(Modal)((
  {
    theme
  }
) => ({
  [`& .${classes.badge}`]: {
    fontSize: "12px",
    width: "auto",
    height: "20px",
    paddingTop: "5px",
    marginLeft: "6px",
  },

  [`& .${classes.listitem}`]: {
    display: "flex !important",
    justifyContent: "space-between",
    alignItem: "center",
  },

  [`& .${classes.phonetitle}`]: {
    display: "flex !important",
    justifyContent: "start",
    alignItem: "center",
  },

  [`& .${classes.phonenumber}`]: {
    marginLeft: "70px",
  },

  [`& .${classes.billbtn}`]: {
    float: "right",
    marginRight: "10px",
  },

  [`& .${classes.newbadge}`]: {
    display: "flex",
  },

  [`& .${classes.tablehead}`]: {
    color: theme.palette.grey[600],
    backgroundColor: theme.palette.grey[200],
  },

  [`& .${classes.item}`]: {
    fontSize: "14px",
  },

  [`& .${classes.avatar}`]: {
    margin: 8,
    display: "inline-block",
    cursor: "pointer",
    zIndex: "1000",
  },

  [`& .${classes.billDetail}`]: {
    backgroundColor: "rgba(46, 125, 50, 0.16)",
    borderRadius: "10px",
    padding: "12px",
  }
}));

const OrderViewPopup = (props) => {
  const { mobileBillPrint } = props;
  const { t } = useTranslation();
  const theme = useTheme();
  const orderData = props.orderDetails;
  const billingData = useSelector((state) => state.api.billing);
  const printComponentRef = useRef();
  const KOTprintComponentRef = useRef();

  const shopSettingListData = useSelector(
    (state) => state.shopSettingReducer.shopSettingData,
  );
  const addOnDetails = (foodMealData) => {
    const addOnData = foodMealData?.addons;
    if (addOnData && addOnData !== "") {
      if (isJson(addOnData)) {
        return JSON.parse(addOnData).map((data) => {
          return (
            <div>
              {data?.name} - {data?.price}
            </div>
          );
        });
      }
    }

    return "";
  };


  return (
    <StyledModal
      show={props.show}
      onHide={props.onHide}
      aria-labelledby="contained-modal-title-vcenter"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {t("order-view-popup-order-view-details-titile")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="show-grid">
        <Tabs
          defaultActiveKey="home"
          transition={false}
          id="noanim-tab-example"
        >
          <Tab eventKey="home" title={t("order-view-popup-about-order-tab-title")}>
            <br />
            <div>
              <div className={classes.listitem}>
                <b>{t("customer-name-label")} :</b>
                <div className={classes.item}>
                  {orderData?.name_of_customer || "-"}
                </div>
              </div>
              <div className={classes.listitem}>
                <b>{t("order-number-label")} :</b>
                <div className={classes.item}>{orderData?.id}</div>
                <b>{t("phone-label")} :</b>
                <div className={classes.item}>{orderData?.phone || "-"}</div>
              </div>
              <div className={classes.listitem}>
                <b>{"Order Created By"} :</b>
                <div className={classes.item}>
                  {orderData?.created_by}
                </div>
              </div>
              <div className={classes.listitem}>
                <b>{t("order-view-popup-ordered-date")} :</b>
                <div className={classes.item}>
                  {moment(orderData?.created_date).format("DD/MM/YYYY hh:mm A")}
                </div>
              </div>
              <div className={classes.listitem}>
                <b>{"Last Order Update On"} :</b>
                <div className={classes.item}>
                  {moment(orderData?.updated_date).format("DD/MM/YYYY hh:mm A")}
                </div>
              </div>
            </div>
            <br />
            {orderData && orderData?.order_meal_items?.length > 0 ? (
              <>
                <Table bordered>
                  <thead className={classes.tablehead}>
                    <tr>
                      <th>#</th>
                      <th>{t("food-menu-label")}</th>
                      <th>{t("qty-label")}</th>
                      <th>{t("price-label")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {orderData &&
                      orderData?.order_meal_items?.length > 0 &&
                      orderData?.order_meal_items.map((ele, index) => (
                        <tr key={index}>
                          <td
                            style={{
                              padding: 0,
                              paddingLeft: 6,
                            }}
                          >
                            <p style={{ margin: 0 }}>{index + 1}</p>
                          </td>
                          <td
                            style={{
                              padding: 0,
                              paddingLeft: 6,
                            }}
                          >
                            <Box className={classes.avatar + 'sf-word-break-all'}>
                              {ele?.fooditem?.name}
                              {
                                (ele?.fooditem?.category?.name.length > 0 ? "( " + ele?.fooditem?.category?.name + " )" : "")
                              }
                              {ele?.food_variant?.name}
                              {addOnDetails(ele)}
                              {isJson(ele?.instruction_note)
                                ? JSON.parse(ele?.instruction_note)?.map(
                                  (data) => data?.label + ", ",
                                )
                                : ele?.instruction_note}
                              {/* <CustomHtmlToolTip
                                interactive
                                placement="top"
                                arrow
                                enterDelay={0}
                                title={
                                  <div>
                                    <ListGroup
                                      style={{
                                        color: "black",
                                      }}
                                    >
                                      <ListGroup.Item
                                        className={classes.listitem}
                                      >
                                        <div>
                                          <b>{t("category-tab-label")} :</b>
                                        </div>
                                        <div>
                                          {ele?.fooditem?.category?.name
                                            .length > 0 ? (
                                            ele?.fooditem?.category?.name
                                          ) : (
                                            <p>{t("no-data-message")}</p>
                                          )}
                                        </div>
                                      </ListGroup.Item>
                                      <ListGroup.Item
                                        className={classes.listitem}
                                      >
                                        <div>
                                          <b>{t("food-menu-variants")} :</b>
                                        </div>
                                        <div>
                                          {ele?.food_variant?.name ||
                                            t("regular-label")}
                                        </div>
                                      </ListGroup.Item>
                                      <ListGroup.Item
                                        className={classes.listitem}
                                      >
                                        <div>
                                          <b>{t("addons-title")} :</b>
                                        </div>
                                        <div>{addOnDetails(ele)}</div>
                                      </ListGroup.Item>
                                    </ListGroup>
                                  </div>
                                }
                              >
                                <InfoIcon
                                  style={{
                                    fontSize: "1rem",
                                    marginLeft: "10px",
                                  }}
                                />
                              </CustomHtmlToolTip> */}
                            </Box>
                          </td>
                          <td
                            style={{
                              padding: 0,
                              paddingLeft: 6,
                            }}
                          >
                            <p style={{ margin: 0 }}>{ele?.qty}</p>
                          </td>
                          <td
                            style={{
                              padding: 0,
                              paddingLeft: 6,
                            }}
                          >
                            <p style={{ margin: 0 }}>{shopSettingListData?.CURRENCY_CODE} {ele?.price}</p>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </>
            ) : (
              <p>{t("record-not-found-message")}</p>
            )}
            <hr />
            <div className={classes.billDetail}>
              <div className={classes.listitem}>
                <b>{t("order-history-payment-type-label")} </b>
                <div className={classes.item}>
                  {orderData?.bill_type || "-"}
                </div>
              </div>
              <div className={classes.listitem}>
                <b>{t("sub-total-title")} </b>
                <div className={classes.item}>{shopSettingListData?.CURRENCY_CODE} {orderData?.sub_total}</div>
              </div>
              <div className={classes.listitem}>
                <b>{t("discount-title")} </b>
                <div className={classes.item}>
                  {orderData?.discount_sign === "INR" ? `${shopSettingListData?.CURRENCY_CODE} ` : "% "}
                  {orderData?.discount === null ? "0" : orderData?.discount}
                </div>
              </div>
              <div className={classes.listitem}>
                <b>{t("tax-title")} </b>
                <div className={classes.item}>{shopSettingListData?.CURRENCY_CODE} {orderData?.tax}</div>
              </div>
              <div className={classes.listitem}>
                <b>{t("order-history-total-bill-label")} </b>
                <div className={classes.item}>{shopSettingListData?.CURRENCY_CODE} {orderData?.total_bill}</div>
              </div>
            </div>
          </Tab>
          {shopSettingListData && shopSettingListData?.KITCHAN_SERVICE && (
            <Tab eventKey="kitchen" title={t("order-view-popup-kitchen-info-tab-title")}>
              {orderData && orderData?.order_meal_items?.length > 0 ? (
                <Table bordered>
                  <thead className={classes.tablehead}>
                    <tr>
                      <th>{t("food-menu-label")}</th>
                      <th>{t("staus-type-title")}</th>
                      <th>{t("qty-label")}</th>
                      <th>{t("price-label")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {orderData &&
                      orderData?.order_meal_items?.length > 0 &&
                      orderData?.order_meal_items.map((orderMeal, index) => {
                        return (
                          orderMeal &&
                          orderMeal?.kitchan_queues?.length > 0 &&
                          orderMeal?.kitchan_queues.map((kitchen) => (
                            <tr key={index}>
                              <td>{orderMeal?.fooditem?.name}</td>
                              <td>
                                <div className={classes.newbadge}>
                                  {kitchen?.status === "IN_PROGRESS" ? (
                                    <Label
                                      variant={
                                        theme.palette.mode === "light"
                                          ? "ghost"
                                          : "filled"
                                      }
                                      color={"success"}
                                    >
                                      {t("sales-history-in-progress-label")}
                                    </Label>
                                  ) : kitchen?.status === "DELIVERED" ? (
                                    <Label
                                      variant={
                                        theme.palette.mode === "light"
                                          ? "ghost"
                                          : "filled"
                                      }
                                      color={"success"}
                                    >
                                      {t("delivered-title")}
                                    </Label>
                                  ) : kitchen?.status === "DONE" ? (
                                    <Label
                                      variant={
                                        theme.palette.mode === "light"
                                          ? "ghost"
                                          : "filled"
                                      }
                                      color={"info"}
                                    >
                                      {t("complete-status-flag-label")}
                                    </Label>
                                  ) : (
                                    kitchen?.status
                                  )}

                                  {kitchen?.types === "NEW" ? (
                                    ""
                                  ) : kitchen?.types === "ADD_ON" ? (
                                    <Label
                                      variant={
                                        theme.palette.mode === "light"
                                          ? "ghost"
                                          : "filled"
                                      }
                                      color={"info"}
                                    >
                                      {t("item-add-status-flag-label")}
                                    </Label>
                                  ) : kitchen?.types === "DELETE" ? (
                                    <Label
                                      variant={
                                        theme.palette.mode === "light"
                                          ? "ghost"
                                          : "filled"
                                      }
                                      color={"error"}
                                    >
                                      {t("deleted-status-flag-label")}
                                    </Label>
                                  ) : (
                                    kitchen?.status
                                  )}
                                </div>
                              </td>
                              <td>{kitchen?.qty}</td>
                              <td>{shopSettingListData?.CURRENCY_CODE} {orderMeal?.price}</td>
                            </tr>
                          ))
                        );
                      })}
                  </tbody>
                </Table>
              ) : (
                <p>{t("record-not-found-message")}</p>
              )}
            </Tab>
          )}
        </Tabs>
        <br />

        <div>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            {
              (!isElectron && !isMobile) && <>
                {/* BILL COMPONENT ADDED */}
                <div style={{ display: "none" }}>
                  <PrintComponent
                    ref={printComponentRef}
                    shopSettingData={shopSettingListData}
                    foodItemListArray={orderData?.order_meal_items}
                    customerDetails={billingData.length > 0 && billingData}
                    printData={orderData}
                    // updatedOrderResData={updatedOrderResData}
                    subTotal={parseFloat(orderData?.sub_total)}
                    netTotal={parseFloat(orderData?.total_bill)}
                    billingData={billingData.length > 0 && billingData}
                  />
                </div>
                {/* KOT COMPONENT */}
                <div className="d-none">
                  <KOTPrintComponent
                    ref={KOTprintComponentRef}
                    shopSettingData={shopSettingListData}
                    foodItemListArray={orderData?.order_meal_items}
                    customerDetails={billingData.length > 0 && billingData}
                    printData={orderData}
                    updatedOrderResData={orderData?.order_meal_items}
                    subTotal={parseFloat(orderData?.sub_total)}
                    netTotal={parseFloat(orderData?.total_bill)}
                    billingData={billingData.length > 0 && billingData}
                  />
                </div>
                <RoleBasedGuard
                  resource={RESOURCE.ORDER_PRINT_BILL_BTN}
                  action={ACTION.VIEW}
                >
                  <ReactToPrint
                    bodyClass={"print-container"}
                    trigger={() => (
                      <ButtonIcon
                        id="kot-print"
                        // isLoading={submittingBtnProcess}
                        buttonLabel="BILL"
                        variant="outline-dark"
                        icon={<PrintIcon />}
                      />
                    )}
                    content={() => printComponentRef.current}
                  />
                </RoleBasedGuard>
                <RoleBasedGuard
                  resource={RESOURCE.ORDER_PRINT_KOT_BTN}
                  action={ACTION.VIEW}
                >
                  <ReactToPrint
                    bodyClass={"print-container"}
                    trigger={() => (
                      <ButtonIcon
                        id="kot-print"
                        buttonLabel="KOT"
                        variant="outline-dark"
                        icon={<PrintIcon />}
                      />
                    )}
                    content={() => KOTprintComponentRef.current}
                  />
                </RoleBasedGuard>

              </>
            }

            {checkIfElectronLoaded() ? (
              <>
                <ElectronPrintFromOrder
                  orderData={orderData}
                  printType={ORDER_DETAILS_BUTTON.KOT_ORDER_BUTTON}
                  billingData={billingData.length > 0 && billingData[0]}
                  printerSize={checkPrinterJson(getShopSettingFromLocalStorage("printing_setting"))?.PRINTER_SIZE}
                  printerName={checkPrinterJson(getShopSettingFromLocalStorage("printing_setting"))?.PRINTER_NAME}
                  buttonLabel={"KOT"}
                />
                <ElectronPrintFromOrder
                  orderData={orderData}
                  printType={ORDER_DETAILS_BUTTON.BILL_ORDER_BUTTON}
                  billingData={billingData.length > 0 && billingData[0]}
                  printerSize={checkPrinterJson(getShopSettingFromLocalStorage("printing_setting"))?.PRINTER_SIZE}
                  printerName={checkPrinterJson(getShopSettingFromLocalStorage("printing_setting"))?.PRINTER_NAME}
                  buttonLabel={t("bill-button-label")}
                />
              </>
            ) : (
              isMobile && (
                <>
                    <RoleBasedGuard
                      resource={RESOURCE.ORDER_PRINT_BILL_BTN}
                      action={ACTION.VIEW}
                    >
                      <ButtonIcon
                        id="kot-print"
                        // isLoading={submittingBtnProcess}
                        buttonLabel={t("bill-button-label")}
                        variant="outline-dark"
                        icon={<PrintIcon />}
                        onClick={() => {
                          mobileBillPrint(ORDER_DETAILS_BUTTON.BILL_ORDER_BUTTON, orderData);
                        }}
                      />
                    </RoleBasedGuard>
                  <div style={{ marginLeft: 10, marginRight: 10 }}>
                      <RoleBasedGuard
                        resource={RESOURCE.ORDER_PRINT_KOT_BTN}
                        action={ACTION.VIEW}
                      >
                        <ButtonIcon
                          id="kot-print"
                          buttonLabel={t("order-detail-kot-label")}
                          variant="outline-dark"
                          icon={<CallToAction />}
                          onClick={() => {
                            mobileBillPrint(ORDER_DETAILS_BUTTON.KOT_ORDER_BUTTON, orderData);
                          }}
                        />
                      </RoleBasedGuard>
                  </div>
                </>
              )
            )}
           
              {
                  !orderData?.is_complete &&
                  <>
                    <ButtonIcon
                      // isLoading={submittingBtnProcess}
                      buttonLabel= {orderData?.is_complete ?  "Settled"  : "Settle & Print"}
                      variant="success"
                      icon={<Done />}
                      disabled = {orderData?.is_complete}
                      onClick = {() => props.settleOrderById(orderData?.id)}
                    />
                    <Button
                          variant="contained"
                          color="info"
                          sx={{ marginLeft: '10px'}}
                          startIcon={<Done />}
                          disabled = {orderData?.is_complete}
                          onClick={async () => {
                            await props.settleOrderById(orderData?.id, ORDER_DETAILS_BUTTON?.SETTLE_ORDER_WITH_PRINT);
                          }}
                          //onClick={() => orderSettleMethod(ORDER_DETAILS_BUTTON.FINISH_ORDER_WITHOUT_PRINT)}
                        >
                            {
                              orderData?.is_complete ?  "Settled"  : "Settle & Print" 
                            }
                        </Button>
                  </>
                
              }
            
          </div>
        </div>

        {/* Bill Print Button Start */}

        {/* <div className={classes.billbtn}>
          <ButtonIcon
            disabled
            type="button"
            // onClick={() => saveAndPrintOrderBtnClick(values)}
            variant="outline-info"
            buttonLabel="BILL"
            icon={<PrintIcon />}
          />
        </div> */}
        {/* Bill Print Button End */}
      </Modal.Body>
    </StyledModal>
  );
};

export default OrderViewPopup;
