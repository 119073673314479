import React, { useEffect, useMemo, useState, useRef, useCallback } from "react";
import ReactDOM from "react-dom";
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Avatar, Badge, Box, IconButton, FormControl, Autocomplete, TextField, Button as MuiButton, Stack } from "@mui/material";
import { Table, Button, Spinner, Modal } from "react-bootstrap";
import {
  kitchenOrderDone,
  kitchenOrderItemDone,
} from "services/kitchen.service";
import { getAllSettingList } from "services/setting.service";
import { deleteOrderById } from "services/order.service";
import PageLoaderBlock from "components/PageLoaderBlock";
import PopUpModel from "components/Popup/PopUpModel";
import _ from 'lodash';
import { Form, Field } from "react-final-form";
import Swal from "sweetalert2";
import { finishOrder, updateOrder } from "services/order.service";
import { toast } from "react-hot-toast";
import { getExcitingOrderById } from "services/order.service";
import { electronPrintFormatData } from "pages/PrintSection/PrintFormatOne";
import { fetchAllCustomer } from "state/thunk-async";
import { isAndroid, isDesktop, isElectron, isMobile, isTablet } from "react-device-detect";

import {
  getShopSettingFromLocalStorage,
  checkIfElectronLoaded,
  getBase64StringFromDataURL,
  checkPrinterJson, isJson
} from "utility/helpers";
import "assets/css/order.css";
import { OrderStatus, OrderType } from "common/constant";
import vegImg from "../../assets/img/vegetarian-food-symbol.png";
import nonVegImg from "../../assets/img/non-vegetarian-food-symbol.png";
import RoleBasedGuard from "guards/RoleBasedGuard";
import { RESOURCE } from "common/constant";
import { ACTION } from "common/constant";
import { useTranslation } from "react-i18next";
import Label from "components/Label";
import { PRINTER_TYPE, ORDER_DETAILS_BUTTON } from "common/constant";
import html2canvas from "html2canvas";
import { getHtmlTemplate, getKOTHtmlTemplate, getPrintFormatJSONFromData, triggerPrintToElectron } from "../../utility/posPrinterReceipt";
import {
  usbPrinter,
  bluetoothPrinter,
  tcpPrinter,
  usbPrinterForKOT,
  bluetoothPrinterForKOT,
  mobileBillPrintViaImage,
  tcpPrinterForKOT,
} from "utility/posPrinterReceipt";
import TotalBillSettlementBox from "./TotalBillSettlementBox";
import { PersonAdd, Print } from "@mui/icons-material";
import AddEditCustomer from "../AdditionalSetting/Customers/AddEditCustomer";
import { checkTheCurrentLoginRoleType } from "utility/helpers";

const PREFIX = 'CommonOrderItemSummeryModel';

const classes = {
  badge: `${PREFIX}-badge`,
  listitem: `${PREFIX}-listitem`,
  phonetitle: `${PREFIX}-phonetitle`,
  phonenumber: `${PREFIX}-phonenumber`,
  billbtn: `${PREFIX}-billbtn`,
  newbadge: `${PREFIX}-newbadge`,
  tablehead: `${PREFIX}-tablehead`,
  item: `${PREFIX}-item`,
  avatar: `${PREFIX}-avatar`,
  billDetail: `${PREFIX}-billDetail`,
  modelHeader: `${PREFIX}-modelHeader`
};

const CustomPopUpModel = styled(PopUpModel)`
  .modal-body {
    text-align: left !important;
    min-height: 450px;
  }
  > .food-catfix-footer {
    justify-content: center !important;
  }
`;

const AutocompleteMui = styled(Autocomplete)`
  .MuiAutocomplete-root,
  .MuiFormControl-root,
  .MuiAutocomplete-inputRoot {
    height: inherit !important;
  }
  input {
    // padding: 5px 10px !important;
  }
`;

const StyledModal = styled(Modal)((
  {
    theme
  }
) => ({
  [`& .${classes.badge}`]: {
    fontSize: "12px",
    width: "auto",
    height: "20px",
    paddingTop: "5px",
    marginLeft: "6px",
  },
  [`& .${classes.modelHeader}`]: {
    padding: 0,
    position: "sticky!important",
    bottom: 0,
    background: "#fff!important"
  },

  [`& .${classes.listitem}`]: {
    display: "flex !important",
    justifyContent: "space-between",
    alignItem: "center",
  },

  [`& .${classes.phonetitle}`]: {
    display: "flex !important",
    justifyContent: "start",
    alignItem: "center",
  },

  [`& .${classes.phonenumber}`]: {
    marginLeft: "70px",
  },

  [`& .${classes.billbtn}`]: {
    float: "right",
    marginRight: "10px",
  },

  [`& .${classes.newbadge}`]: {
    display: "flex",
  },

  [`& .${classes.tablehead}`]: {
    color: theme.palette.grey[600],
    backgroundColor: theme.palette.grey[200],
  },

  [`& .${classes.item}`]: {
    fontSize: "14px",
  },

  [`& .${classes.avatar}`]: {
    margin: 8,
    display: "inline-block",
    cursor: "pointer",
    zIndex: "1000",
  },

  [`& .${classes.billDetail}`]: {
    backgroundColor: "rgba(46, 125, 50, 0.16)",
    borderRadius: "10px",
    padding: "12px",
  }
}));

export default function CommonOrderItemSummeryModel(props) {
  const { selectedOrderId, show, onHide, orderDetailsData, refetchData } = props;
  const { t } = useTranslation();

  // USE SELECTORS
  const shopSettingsData = useSelector((state) => state.shopSettingReducer.shopSettingData);
  const billingData = useSelector((state) => state.api.billing);
  const customers = useSelector((state) => state.api.customers);

  // ALL STATE
  const mobilePrintRef = useRef(null);
  const mobileKOTPrintRef = useRef(null);
  const dispatch = useDispatch();

  const [selectedItem, setSelectedItem] = useState(null);
  const [orderData, setOrderData] = useState(null);
  const [kitchenOrderData, setKitchenOrderData] = useState([]);
  const [orderProcessing, setOrderProcessing] = useState(false);
  const [mobilePrintImageDataFormat, setMobilePrintImageDataFormat] = useState(null)
  const [isKitchenOrderLoading, setIsKitchenOrderLoading] = useState({
    oneCompleted: false,
    allCompleted: false,
  });
  const [initialFormValue, setInitialFormValue] = useState({
    discount: 0
  });
  const [subTotal, setSubTotal] = useState(0);
  const [finalBill, setFinalBill] = useState(0);
  const [discountValue, setDiscountValue] = useState(0);
  const [discountSignValue, setDiscountSignValue] = useState("%");
  const [taxValue, setTaxValue] = useState(false);
  const [parcelField, setParcelField] = useState(false);
  const [additionalCharge, setAdditionalCharge] = useState(0)
  const [calculatedDiscount, setCalculatedDiscount] = useState(0);
  const [taxAmountValue, setTaxAmountValue] = useState(0);
  const [taxAmountPercentage, setTaxAmountPercentage] = useState(0);
  const [printBtnProcess, setPrintBtnProcess] = useState(false);
  const [customerAddToggle, setCustomerAddToggle] = useState(false);

  useEffect(() => {
    if (selectedOrderId && !Object.keys(orderDetailsData || {}).length > 0) {
      callExcitingData(selectedOrderId);
    }
  }, [selectedOrderId])

  useEffect(() => {
    if (orderData?.order_meal_items?.length > 0) {
      const kitchenOrderDataArray = [];
      if(shopSettingsData?.ORDER_SUMMERY_POPUP_KITCHEN)
      {
        orderData?.order_meal_items?.map(data => {
          data?.kitchan_queues?.length > 0 && (
            data?.kitchan_queues?.map(kitchenData => (
              kitchenOrderDataArray.push({
                ...kitchenData,
                fooditem: data?.fooditem,
                instruction_note: data?.instruction_note,
                addons: data?.addons,
                price: data?.price
              })))
          )
        })
      }
      
      setKitchenOrderData(kitchenOrderDataArray);
    }


    if (Object.keys(orderData || {}).length > 0) {
      setInitialFormValue({
        discount: orderData.discount,
        sub_total: orderData.sub_total,
        discount_sign: orderData.discount_sign,
        final_bill: parseFloat(orderData?.total_bill),
        table_id: orderData?.table_id,
        order_type: orderData?.order_type,
        customer: `${orderData?.name_of_customer} - ${orderData?.phone}`,
        additional_charge: orderData.additional_charge || 0,
        additional_charge_reason: orderData.additional_charge_reason,
        bill_note: orderData?.bill_note,
        bill_type: orderData?.bill_type
      })

      // setInitialFormValue({
      //   ...orderData,
      //   customer: `${orderData?.name_of_customer} - ${orderData?.phone}`,
      // });
      //setPrintData(orderData);
      setSubTotal(orderData?.sub_total || 0);
      setAdditionalCharge(orderData?.additional_charge || 0);
      setDiscountValue(orderData.discount);
      setDiscountSignValue(orderData.discount_sign);
      setFinalBill(orderData.total_bill);
      setTaxValue(parseFloat(orderData.tax) > 0 ? true : false);
    }
  }, [orderData])

  useEffect(() => {
    getAllSettingList()
      .then((res) => {
        setTaxAmountPercentage(parseFloat(res.data[0]?.tax_percentage));
        // setInitialBillingData(res.data[0]);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    if (orderDetailsData) {
      setOrderData(orderDetailsData);
    }
  }, [orderDetailsData])

  const resetFormData = () => {
    setInitialFormValue({});
    //setPrintData(orderData);
    setAdditionalCharge(0);
    setDiscountValue(0);
    setDiscountSignValue(0);
    setFinalBill(0);
    setSubTotal(0);
    setTaxValue(false);
    setPrintBtnProcess(false);
    //setOrderData({});
    onHide();
  }

  const orderSubmit = async (formData, billPrintType) => {

    let print_type = "";
    if(billPrintType === ORDER_DETAILS_BUTTON.BILL_ORDER_BUTTON)
    {
      print_type = "BILL"
    }
    else if(billPrintType === ORDER_DETAILS_BUTTON.KOT_ORDER_BUTTON)
    {
      print_type = "KOT"
    }
    
    const orderModel = {
      discount: formData?.discount,
      discount_sign: formData?.discount_sign,
      additional_charge: parseFloat(formData?.additional_charge || 0).toFixed(2),
      additional_charge_reason: formData?.additional_charge_reason,
      bill_note: formData?.bill_note,
      bill_type: formData?.bill_type,
      tax: taxValue ? taxAmountValue : 0,
      sub_total: subTotal,
      total_bill: finalBill,
      name_of_customer: formData?.customer ? formData?.customer?.split("-")[0]?.trim() : "",
      phone: formData?.customer ? formData?.customer?.split("-")[1]?.trim() : "",
      print_type: checkTheCurrentLoginRoleType() === "STAFF" ? print_type : ""
      //order_type: formData?.order_type,
      //name_of_customer: formData?.name_of_customer,
      // table_id: table_id,
      // phone: phone,
    };

    console.log('%cCommonOrderItemSummeryModel.js line:199 formData', 'color: #007acc;', orderModel);
    const response = await updateOrder(parseInt(orderData?.id), orderModel);
    if (typeof billPrintType !== "object") {
      console.log(response);
      return response;
    }
    else {
      toast.success(response?.message);
    }
    refetchData();
    resetFormData();
    onHide();
  }

  useEffect(() => {
    if (mobilePrintImageDataFormat) {
      printLogic(mobilePrintImageDataFormat);
    }
  }, [mobilePrintImageDataFormat])

  const printLogic = async (printModal) => {

    let isKOT = printModal?.isKOT;
    const PRINTER_NAME = checkPrinterJson(getShopSettingFromLocalStorage("mobile_printing_setting"))?.PRINTER_NAME;
    const KITCHEN_IP = getShopSettingFromLocalStorage("mobile_printing_setting")?.KITCHEN_IP;
    // const PRINTER_SIZE = getShopSettingFromLocalStorage("PRINTER_SIZE");
    const printerType = checkPrinterJson(getShopSettingFromLocalStorage("mobile_printing_setting"))?.PRINTER_TYPE;
    const PRINTER_END_SPACE = checkPrinterJson(getShopSettingFromLocalStorage("mobile_printing_setting"))?.PRINTER_END_SPACE;
    const BILL_IP = getShopSettingFromLocalStorage("mobile_printing_setting")?.BILL_IP;

    if (!printerType) {
      toast.error("Printer type is not specified.");
    }
    else {
      if (isKOT) {
        if (printerType === PRINTER_TYPE.USB) {
          if (shopSettingsData?.printing_setting?.IS_MOBILE_PRINT_VIA_IMAGE) {
            await hexaDecimalPrinter("usb", PRINTER_NAME, isKOT)
          }
          else {
            await usbPrinterForKOT(PRINTER_NAME, PRINTER_END_SPACE, printModal);
          }

        } else if (printerType === PRINTER_TYPE.BLUETOOTH) {
          if (shopSettingsData?.printing_setting?.IS_MOBILE_PRINT_VIA_IMAGE) {
            await hexaDecimalPrinter("bluetooth", PRINTER_NAME, isKOT)
          }
          else {
            await bluetoothPrinterForKOT(
              PRINTER_NAME,
              PRINTER_END_SPACE,
              printModal
            );
          }

        } else if (printerType === PRINTER_TYPE.IP) {
          // HERE KOT Logic
          if (KITCHEN_IP?.split(":")?.length > 0) {
            const billKotPrinter = KITCHEN_IP?.split(":");
            if (shopSettingsData?.printing_setting?.IS_MOBILE_PRINT_VIA_IMAGE) {
              await hexaDecimalPrinter(billKotPrinter[0], billKotPrinter[1], isKOT)
            }
            else {
              await tcpPrinterForKOT(
                billKotPrinter[0],
                billKotPrinter[1],
                PRINTER_END_SPACE,
                printModal,
              );
            }

          } else if (BILL_IP?.split(":")?.length > 0) {
            const billPrinter = BILL_IP?.split(":");
            if (shopSettingsData?.printing_setting?.IS_MOBILE_PRINT_VIA_IMAGE) {
              await hexaDecimalPrinter(billPrinter[0], billPrinter[1], isKOT)
            }
            else {
              await tcpPrinterForKOT(
                billPrinter[0],
                billPrinter[1],
                PRINTER_END_SPACE,
                printModal,
              );
            }

          }
        }
      } else {
        // BILL PRINT LOGICS
        isKOT = false
        if (printerType === PRINTER_TYPE.USB) {
          if (shopSettingsData?.printing_setting?.IS_MOBILE_PRINT_VIA_IMAGE) {
            await hexaDecimalPrinter("usb", PRINTER_NAME, isKOT)
          }
          else {
            await usbPrinter(PRINTER_NAME, PRINTER_END_SPACE, printModal);
          }

        } else if (printerType === PRINTER_TYPE.BLUETOOTH) {
          if (shopSettingsData?.printing_setting?.IS_MOBILE_PRINT_VIA_IMAGE) {
            await hexaDecimalPrinter("bluetooth", PRINTER_NAME, isKOT)
          }
          else {
            await bluetoothPrinter(PRINTER_NAME, PRINTER_END_SPACE, printModal);
          }
        } else if (printerType === PRINTER_TYPE.IP) {
          if (BILL_IP?.split(":")?.length > 0) {
            const billPrinter = BILL_IP?.split(":");
            if (shopSettingsData?.printing_setting?.IS_MOBILE_PRINT_VIA_IMAGE) {
              await hexaDecimalPrinter(billPrinter[0], billPrinter[1], isKOT)
            }
            else {
              await tcpPrinter(
                billPrinter[0],
                billPrinter[1],
                PRINTER_END_SPACE,
                printModal,
              );
            }
          }
        }
      }
    }

    setMobilePrintImageDataFormat(null);
    refetchData();
    onHide();
    resetFormData();
    setPrintBtnProcess(false);
  }

  const callExcitingData = (order_id) => {
    getExcitingOrderById(order_id)
      .then((data) => {
        setOrderData(data?.data)
      })
      .catch((err) => console.log(err));
  }

  const handleKitchanOrderItemCompleted = async (itemID) => {
    try {
      setSelectedItem(itemID);
      setIsKitchenOrderLoading((state) => ({ ...state, oneCompleted: true }));
      const response = await kitchenOrderItemDone(itemID);
      if (response.status === 200) {
        await callExcitingData(orderData?.id);
        setIsKitchenOrderLoading({ allCompleted: false, oneCompleted: true });
        setSelectedItem(null);
      }
    } catch (err) {
      setIsKitchenOrderLoading((state) => ({ ...state, oneCompleted: true }));
      setSelectedItem(null);
    }
  };

  const addOnDetails = (foodMealData) => {
    const addOnData = foodMealData?.addons;
    if (addOnData && addOnData !== "") {
      if (isJson(addOnData)) {
        return JSON.parse(addOnData).map((data) => {
          return data?.name + " - " + data?.price
        });
      }
    }

    return ""
  };

  const itemBackgroundColor = (status, types) => {
    if (status === OrderStatus.DELIVERED) {
      return "rgb(199 203 196 / 85%)";
    } else if (types === OrderType.NEW) {
      return "";
    } else if (types === OrderType.ADD_ON) {
      return "orange";
    } else if (types === OrderType.DELETE) {
      return "red";
    }
    return "#FFF";
  };

  const labelColor = (status, types) => {
    if (status === OrderStatus.DELIVERED) {
      return "primary";
    }
    if (status === OrderStatus.IN_PROGRESS) {
      return "warning";
    }
    if (status === OrderStatus.DONE) {
      return "success";
    }
    return "default";
  }

  const itemFontColor = (status, types) => {
    if (status === OrderStatus.DELIVERED) {
      return "#000";
    } else if (types === OrderType.NEW) {
      return "";
    } else if (types === OrderType.ADD_ON) {
      return "#000";
    } else if (types === OrderType.DELETE) {
      return "#FFF";
    }
    return "";
  };

  const isOnlyVegStore = () => {
    return shopSettingsData?.IS_SHOP_VEGETARIAN;
  };

  const getOrderData = () => {
    if (kitchenOrderData?.length > 0) {
      return kitchenOrderData
    }
    return orderData?.order_meal_items;
  }

  const getBase64StringFromHtml = useCallback(async (isKOT) => {
    const htmlBillObject = isKOT ? mobileKOTPrintRef.current : mobilePrintRef.current;
    if (htmlBillObject === null) {
      return
    }

    return html2canvas(htmlBillObject).then(function (canvas) {
      const dataURL = canvas.toDataURL('image/jpeg', 1);
      const base64 = getBase64StringFromDataURL(dataURL);
      return base64;
    }).catch(error => {
      console.log(JSON.stringify(error));
    });
  }, [mobilePrintRef, mobileKOTPrintRef])

  const hexaDecimalPrinter = async (type, printerId, isKOT) => {
    try {
      const base64String = await getBase64StringFromHtml(isKOT);
      if (base64String) {
        await mobileBillPrintViaImage(type, printerId, "", base64String);
      }
      else {
        toast.error("printer image not found or not loaded properly")
        console.log('%cOrderDetails.js line:787 printer image not found or not loaded proper', 'color: #007acc;', "printer image not found or not loaded proper");
      }
    }
    catch (error) {
      console.log(error?.message);
      toast.error(error?.message);
    }
  }

  const mobileBillPrint = async (billPrintType, printModal) => {
   
    // const billing = billingData && billingData.length > 0 ? billingData[0] : {};

    // let subTotalWithTax = parseFloat(printData?.sub_total) + parseFloat(printData?.tax);
    // let discountInRs = printData?.discount_sign === "%" ? (subTotalWithTax * parseFloat(printData?.discount)) / 100 : parseFloat(printData?.discount);

    // const printModal = {
    //   isKOT: isKOT,
    //   header: shopSettingsData?.printing_setting?.PRINT_BILL_HEADER ? shopSettingsData?.printing_setting?.PRINT_BILL_HEADER : "",
    //   footer: shopSettingsData?.printing_setting?.PRINT_BILL_FOOTER ? shopSettingsData?.printing_setting?.PRINT_BILL_FOOTER : "",
    //   printingTemplate: shopSettingsData?.printing_setting?.PRINT_BILL_TEMPLATE,
    //   logo: billing?.logo ? configData?.S3_BUCKET + billing?.logo : "",
    //   shopTagLine: billing?.about,
    //   shopName: billing?.name,
    //   shopAddress: billing?.address ? billing?.address + "- " + billing?.phone : (billing?.phone ? billing?.phone : ""),
    //   customerName: printData?.customer ? printData?.customer?.name : " ",
    //   date: new Date().toDateString() + " " + new Date().toLocaleTimeString(),
    //   orderNumber: parseInt(printData?.id || 0),
    //   tokenNo: parseInt(printData?.token_no || printData?.token),
    //   orderType: " (" + printData?.order_type + ")",
    //   tableName: printData?.diningtable?.name_of_table || "",
    //   printFoodItemArray: printFoodItemArray,
    //   subTotal: printData?.sub_total,
    //   tax:
    //     parseFloat(printData?.tax) > 0
    //       ? `${parseFloat(printData?.tax)}`
    //       : " ",
    //   CGST: parseFloat(printData?.tax) / 2,
    //   SGST: parseFloat(printData?.tax) / 2,
    //   discount: printData?.discount ? `Discount (${printData?.discount_sign == null || printData?.discount_sign === "INR" ? "₹"
    //     : printData?.discount_sign + " " + printData?.discount
    //     }): ₹` + parseFloat(discountInRs).toFixed(2)
    //     : "",
    //   netTotal: printData?.total_bill,
    //   additionalCharge: printData?.additional_charge,
    //   additionalChargeReason: printData?.additional_charge_reason || "Additional Charge"
    // }; DHARMIK

    if (shopSettingsData?.printing_setting?.IS_MOBILE_PRINT_VIA_IMAGE) {
      setMobilePrintImageDataFormat(printModal);
    }
    else {
      printLogic(printModal)
    }
  };

  const checkTheFormValuesChangedOrNot = (oldFormValue, changedFormValue) => {
    const changedOrderFormModel = {
      discount: changedFormValue?.discount,
      discount_sign: changedFormValue?.discount_sign,
      additional_charge: changedFormValue?.additional_charge,
      additional_charge_reason: changedFormValue?.additional_charge_reason,
      bill_note: changedFormValue?.bill_note,
      bill_type: changedFormValue?.bill_type,
      tax: parseFloat(taxValue ? taxAmountValue : 0).toFixed(2),
      sub_total: parseFloat(subTotal).toFixed(2),
      total_bill: parseFloat(finalBill).toFixed(2),
    };

    const newFormModel = {
      discount: oldFormValue?.discount,
      discount_sign: oldFormValue?.discount_sign,
      additional_charge: oldFormValue?.additional_charge,
      additional_charge_reason: oldFormValue?.additional_charge_reason,
      bill_note: oldFormValue?.bill_note,
      bill_type: oldFormValue?.bill_type,
      tax: parseFloat(oldFormValue?.tax || 0).toFixed(2),
      sub_total: parseFloat(oldFormValue.sub_total).toFixed(2),
      total_bill: parseFloat(oldFormValue.total_bill).toFixed(2),
    }

    return _.isEqual(changedOrderFormModel, newFormModel);
  }

  const saveAndPrintOrder = async (orderId, billPrintType, formValues) => {
    console.log(formValues);
    setPrintBtnProcess(true);
    if (!checkTheFormValuesChangedOrNot(orderData, formValues)) {
      const orderSubmitRes = await orderSubmit(formValues, billPrintType);
    }


    // TODO: Add a logic if the order values is updated or old one. and call the api based on it. - DHARMIK 
    switch (billPrintType) {
      case ORDER_DETAILS_BUTTON.BILL_ORDER_BUTTON:
        await getExcitingOrderById(orderId)
          .then((data) => {
            printOrderBasedOnDevice(data, billPrintType)
          })
          .catch((err) => console.log(err));
        break;
      case ORDER_DETAILS_BUTTON.KOT_ORDER_BUTTON:
        await getExcitingOrderById(orderId)
          .then((data) => {
            printOrderBasedOnDevice(data, billPrintType)
          })
          .catch((err) => console.log(err));
        break;
      case ORDER_DETAILS_BUTTON.FINISH_ORDER_WITHOUT_PRINT:
        settleOrderWithoutPrint(orderId)
        break;
      case ORDER_DETAILS_BUTTON.SETTLE_ORDER_WITH_PRINT:
        settleAndPrintOrder(orderId, billPrintType)
        break;
      default:
        getExcitingOrderById(orderId)
          .then((data) => {
            printOrderBasedOnDevice(data, billPrintType)
          })
          .catch((err) => console.log(err));
        break;
    }
  };

  const printOrderBasedOnDevice = async (printData, billPrintType) => {
    let printFoodItemArray = [];
    let orderData = printData?.data;
    let isKOT = (billPrintType === ORDER_DETAILS_BUTTON.KOT_ORDER_BUTTON) ? true : false;
    if (isKOT) {
      const printOBJItems = orderData?.print_obj?.length > 0 ? orderData?.print_obj : orderData?.order_meal_items;
      printFoodItemArray = printOBJItems?.length > 0 ? printOBJItems?.map(item => {

        let foodFullName = "";
        let foodItemName = item?.fooditem?.name ? item.fooditem?.name : item?.food_item_id?.label
        let foodVariantName = "";
        let foodAddOnObj = isJson(item?.addons) ? JSON.parse(item?.addons)?.map(addOn => addOn.name).join(', ') : ""
        if (item?.varient_id > 0 && item?.fooditem?.food_variants?.length > 0) {
          foodVariantName = item?.fooditem?.food_variants.find(({ id }) => id === item?.varient_id)?.name
        }

        // foodFullName = `<span style=text-decoration:${item?.kot_print?.type === 'DELETE' ? 'line-through' : ''}>${foodItemName + (foodVariantName ? "( " + foodVariantName + ") " : "") + foodAddOnObj}</span>`;
        foodFullName = `${item?.kot_print?.type === 'DELETE' ? '(DELETE)' : ''}${foodItemName + (foodVariantName ? "( " + foodVariantName + ")" : '') + foodAddOnObj}`;
        return {
          foodName: foodFullName,
          qty: item?.kot_print?.qty || item?.qty,
          price: `${parseFloat(item?.sub_total).toFixed(2)}`,
        }
      }) : [];
    } else {
      printFoodItemArray =
      orderData?.order_meal_items?.length > 0 &&
      orderData?.order_meal_items?.map((item) => {
          let foodFullName = "";
          let foodItemName = item?.fooditem?.name
            ? item.fooditem?.name
            : item?.food_item_id?.label;
          let foodVariantName = "";
          let foodAddOnObj = isJson(item?.addons)
            ? JSON.parse(item?.addons)
              ?.map((addOn) => addOn.name)
              .join(", ")
            : "";
          if (item?.varient_id > 0 && item?.fooditem?.food_variants?.length > 0) {
            foodVariantName = item?.fooditem?.food_variants.find(
              ({ id }) => id === item?.varient_id,
            )?.name;
          }

          foodFullName = `${foodItemName +
            (foodVariantName ? "( " + foodVariantName + ") " : "") +
            foodAddOnObj
            }`;

          return {
            foodName: foodFullName,
            qty: item?.qty,
            price: `${parseFloat(item?.sub_total).toFixed(2)}`,
          };
        });
    }
    const printModal = getPrintFormatJSONFromData(shopSettingsData, billingData, orderData, billPrintType, printFoodItemArray)
    console.log(printModal)
    if (checkIfElectronLoaded()) {
      await triggerPrintToElectron(billPrintType, printModal, shopSettingsData)
      // await electronPrintFormatData(
      //   billingData?.length > 0 && billingData[0],
      //   printData?.data,
      //   checkPrinterJson(getShopSettingFromLocalStorage("printing_setting"))?.PRINTER_NAME,
      //   checkPrinterJson(getShopSettingFromLocalStorage("printing_setting"))?.PRINTER_SIZE,
      //   shopSettingsData
      // ); Dharmik

    }
    else if (isMobile) {
      // android print logic will be here
      await mobileBillPrint(billPrintType, printModal)
    }
    //setKitchenProgressModalShow(false);
    //resetSelectedTableData();
    setPrintBtnProcess(false);
  }

  const finishOrderInternalFun = async (orderId, billPrintType) => {
    await finishOrder(orderId)
      .then(async (res) => {
        if (res.status === 200 || res?.data?.status === 200) {
          toast.success(res.message);
        } else if (res.status === 400) {
          toast.error(res?.message);
        }

        if (billPrintType) {
          await printOrderBasedOnDevice(res, billPrintType)
        }
      })
      .catch((err) => {
        toast.error(err?.message);
      });
    document.body.click();
    refetchData();
    onHide();
    resetFormData();
    setPrintBtnProcess(false);
  }

  const settleAndPrintOrder = async (orderId, billPrintType) => {
    if (checkPrinterJson(getShopSettingFromLocalStorage("printing_setting"))?.PRINT_ON_SETTLE || billPrintType === ORDER_DETAILS_BUTTON.SETTLE_ORDER_WITH_PRINT) {
      finishOrderInternalFun(orderId, billPrintType);
    }
    else {
      settleOrderWithoutPrint(orderId)
    }
  };

  const settleOrderWithoutPrint = (orderId) => {
    resetFormData();
    onHide();
    if (shopSettingsData?.ENABLE_CONFIRM_SETTLE_BTN) {
      setOrderProcessing(false);
      Swal.fire({
        title: t("swal-title"),
        text: t("settle-order-swal-text"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: t("popup-cancel-button-label"),
        confirmButtonText: t("swal-order-settle-confirmation-text"),
      }).then((result) => {
        if (result?.isConfirmed) {
          finishOrderInternalFun(orderId, "");
        }
      });
    }
    else {
      finishOrderInternalFun(orderId, "");
    }
  }

  const deleteOrder = (orderId) => {
    onHide();
    Swal.fire({
      title: t("swal-title"),
      text: t("dining-table-swal-text"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: t("popup-cancel-button-label"),
      confirmButtonText: t("dining-table-swal-confirmation-text"),
    }).then((result) => {
      if (result.isConfirmed) {
        deleteOrderById(orderId)
          .then((res) => {
            toast.success(res.message);
            refetchData();
          })
          .catch((err) => {
            toast.error(err?.message);
          });
      }
    });
  };

  useEffect(() => {
    finalBillCalculate();
  }, [subTotal, discountValue, discountSignValue, taxValue, additionalCharge, orderData, taxAmountPercentage]);

  useEffect(() => {
    if (billingData && billingData[0]?.gst_no) {
      setTaxValue(true);
    } else {
      setTaxValue(false);
    }
  }, [billingData]);

  const finalBillCalculate = () => {
    if (subTotal === 0) {
      setFinalBill(0.0);
    }
    //ADD GST TO bill
    var taxAmount = 0;
    var taxPercent = taxAmountPercentage / 100; // SGST 2.5% + CGST 2.5%
    if (taxValue && billingData[0]?.gst_no) {
      taxAmount = subTotal * taxPercent;
    }
    setTaxAmountValue(parseFloat(taxAmount).toFixed(2));
    // add tax in subtotal

    let subTotalWithTax = parseFloat(taxAmount) + parseFloat(subTotal);

    if (discountSignValue === "%") {
      let discountPrice = 0;
      if (parseFloat(discountValue) > 0) {
        discountPrice = (subTotalWithTax * discountValue) / 100;
      }
      setCalculatedDiscount(parseFloat(discountPrice).toFixed(2));
      let finalBillPrice = parseFloat(subTotalWithTax - discountPrice + parseFloat(additionalCharge)).toFixed(2);
      if (shopSettingsData?.ROUND_OFF_PRICE) {
        setFinalBill(Math.round(finalBillPrice));
      }
      else {
        setFinalBill(finalBillPrice);
      }
    } else {
      let finalBillPrice = parseFloat(subTotalWithTax - discountValue + parseFloat(additionalCharge)).toFixed(2);
      if (shopSettingsData?.ROUND_OFF_PRICE) {
        setFinalBill(Math.round(finalBillPrice));
      }
      else {
        setFinalBill(finalBillPrice);
      }
    }
  };

  let submit;
  return (
    <>
      <StyledModal
        show={show}
        onHide={resetFormData}
        size={"lg"}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <PageLoaderBlock
          processing={printBtnProcess}
          message={
            <div>
              <Print /><br></br>
              <b>Please wait, we are processing order</b></div>}>
          <Modal.Header className={classes.modelHeader} closeButton>
            <Modal.Title as={"h5"} id="contained-modal-title-vcenter">
              Order Summery
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="sf-kitchen-card sf-kitchen-item">
              <Form
                onSubmit={orderSubmit}
                initialValues={initialFormValue}
                validate={(values) => {
                  const errors = {};

                  if (
                    values?.discount_sign === "INR" &&
                    parseFloat(values?.discount) >= parseFloat(subTotal)
                  ) {
                    errors.discount = t("order-detail-discount-validation-message");
                  } else if (
                    values?.discount_sign === "%" &&
                    parseInt(values?.discount) > 100
                  ) {
                    errors.discount = t(
                      "order-detail-discount-max-limit-validation-message",
                    );
                  }
                  return errors;
                }}
                mutators={{
                  setFinal_Bill: (args, state, { setIn, changeValue }) => {
                    changeValue(state, "final_bill", () => 50);
                  },
                  setNewlyCreatedCustomerToForm: (args, state, { setIn, changeValue }) => {
                    console.log(args, state)
                    changeValue(state, "customer", () => args.length > 0 ? (args[0]?.name + '-' + args[0]?.mobile) : {});
                  },
                }}
                render={({
                  handleSubmit,
                  form,
                  submitting,
                  pristine,
                  touched,
                  errors,
                  values,
                }) => {
                  submit = handleSubmit
                  return (
                    <form
                      onSubmit={handleSubmit}
                      id="order-cart-form"
                      style={{ height: "100%" }}
                    >
                      <div>
                        <div className={classes.listitem}>
                              {
                                shopSettingsData?.ORDER_SUMMERY_POPUP_CUSTOMER ? 
                                  <Stack direction={'row'} sx={{ flex: 'auto', width: '100%' }}>
                                  <Field name="customer">
                                      {({ input, meta }) => (
                                        <FormControl
                                          sx={{ width: '100%' }}
                                        >
                                            <AutocompleteMui
                                              size="small"
                                              disablePortal
                                              includeInputInList={true}
                                              getOptionLabel={(option) => option ? option : ''}
                                              onChange={(event, value) => input.onChange(value)}
                                              value={input?.value || null}
                                              noOptionsText={"No option available"}
                                              options={customers
                                                .filter((a) => !a?.isBlocked)
                                                .map(
                                                  (a) => `${a?.name} - ${a?.mobile}`,
                                                )}
                                              renderInput={(params) => (
                                                <TextField
                                                  {...params}
                                                  type="text"
                                                  label={"Select customer"}
                                                  variant="outlined"
                                                  error={
                                                    meta.error && meta.touched
                                                      ? true
                                                      : false
                                                  }
                                                />
                                              )}
                                            />
                                          {meta.error && meta.touched && (
                                            <span style={{ color: "red" }}>
                                              {meta.error}
                                            </span>
                                          )}
                                        </FormControl>
                                      )}
                                    </Field>
                                     <IconButton
                                     aria-label={t("order-detail-widget-label")}
                                     onClick={() => setCustomerAddToggle(!customerAddToggle)}
                                     size="small">
                                     <PersonAdd />
                                   </IconButton>
                                   <CustomPopUpModel
                                       show={customerAddToggle}
                                       heading={t("customer-add-label")}
                                       onHide={() => setCustomerAddToggle(false)}
                                       body={
                                       <AddEditCustomer
                                         customerId={0}
                                         drawerClose={(data) => {
                                           setCustomerAddToggle(false);
                                         if (data && data.name && data.mobile) {
                                           dispatch(fetchAllCustomer());
                                           form.mutators.setNewlyCreatedCustomerToForm(data);
                                         }
                                       }}
                                       customerList={() => {
                                         return customers;
                                       }}
                                     />
                                     }
                                   />
                                   </Stack>
                                    :
                                    <>
                                  <b>{t("customer-name-label")} :</b> {orderData?.name_of_customer || "-"}
                                    </>
                              }
                        </div>
                        <div className={classes.listitem}>
                          <b>{t("order-number-label")} :</b>
                          <div className={classes.item}>{orderData?.id}</div>
                          <b>{t("phone-label")} :</b>
                          <div className={classes.item}>{orderData?.phone || "-"}</div>
                        </div>
                        <div className={classes.listitem}>
                          <b>{t("order-view-popup-ordered-date")} :</b>
                          <div className={classes.item}>
                            {moment(orderData?.created_date).format("DD/MM/YYYY hh:mm A")}
                          </div>
                        </div>
                        <div className={classes.listitem}>
                          <b>{"Order Created By"} :</b>
                          <div className={classes.item}>
                            {orderData?.created_by}
                          </div>
                        </div>
                        <div className={classes.listitem}>
                          <b>{"Last Order Update On"} :</b>
                          <div className={classes.item}>
                            {moment(orderData?.updated_date).format("DD/MM/YYYY hh:mm A")}
                          </div>
                        </div>
                      </div>
                      <br />
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <h6>
                          {orderData &&
                            orderData?.order_type === "DINE_IN"
                            ? `Table - ${orderData?.diningtable &&
                            orderData?.diningtable?.name_of_table
                            }`
                            : orderData?.order_type === "PARCEL"
                              ? `Parcel`
                              : `Table(QR-Code) - ${orderData?.diningtable &&
                              orderData?.diningtable.name_of_table
                              }`}
                        </h6>
                        {/* <RoleBasedGuard
                          resource={RESOURCE.KITCHEN_QUEUE}
                          action={ACTION.UPDATE}
                          >
                          <Button
                              onClick={() =>
                              handleAllKitchanOrderCompleted(orderData?.id)
                              }
                              variant="outline-info"
                              className="sf-button-icon sf-button-icon-md"
                          >
                              {isKitchenOrderLoading?.allCompleted &&
                              selectedOrder === orderData?.id ? (
                              <Spinner
                                  as="span"
                                  animation="border"
                                  size="sm"
                                  role="status"
                                  aria-hidden="true"
                              />
                              ) : (
                              <CheckCircleIcon />
                              )}
                          </Button>
                          </RoleBasedGuard> DHARMIK*/}
                      </div>
                      <hr />
                      <Table
                        bordered
                        hover
                        responsive
                        className="sf-kitchen-table"
                      >
                        <thead>
                          <tr
                            style={{
                              backgroundColor: "#0c3765",
                              color: "#fff",
                            }}
                          >
                            <th>{t("item-label")}</th>
                            {!isOnlyVegStore() && (
                              <th>{t("is-veg-flag-label")}</th>
                            )}
                            <th className="text-center">
                              qty
                            </th>
                            <th className="text-center">
                              Total
                            </th>
                            {
                              kitchenOrderData.length > 0 && <th className="text-center">
                                {t("serve-label")}
                              </th>
                            }
                          </tr>
                        </thead>
                        <tbody>
                          {
                            getOrderData()?.map((item) => (
                              <tr
                                style={{
                                  backgroundColor: itemBackgroundColor(
                                    item?.status,
                                    item?.types,
                                  ),
                                  color: itemFontColor(
                                    item?.status,
                                    item?.types,
                                  ),
                                }}
                              >
                                <td>
                                  <div className="d-flex justify-content-between">
                                    <div>
                                      <div>
                                        <b>  {item && item?.fooditem && item?.fooditem?.name}-
                                          {item?.food_variant?.name ||
                                            t("regular-label")}
                                          {addOnDetails(item)} (
                                          {item?.fooditem?.category?.name
                                            .length > 0 && (
                                              item?.fooditem?.category?.name
                                            )})
                                        </b>
                                      </div>
                                    </div>
                                  </div>
                                  {
                                    item?.status && <div
                                      style={{
                                        fontSize: '10px',
                                        height: '16px'
                                      }}>
                                      <Label
                                        sx={{
                                          fontSize: '10px',
                                          height: '16px'
                                        }}
                                        color={labelColor(item?.status, item?.types)}>{item.status}</Label>
                                    </div>
                                  }
                                </td>
                                {!isOnlyVegStore() && (
                                  <td className="text-center">
                                    {item?.is_non_veg ? (
                                      <img
                                        src={nonVegImg}
                                        style={{
                                          width: 25,
                                          objectFit: "contain",
                                        }}
                                        alt="non veg"
                                      />
                                    ) : (
                                      <img
                                        src={vegImg}
                                        style={{
                                          width: 25,
                                          objectFit: "contain",
                                        }}
                                        alt="veg"
                                      />
                                    )}
                                  </td>
                                )}
                                <td className="text-center">
                                  {item?.qty} * {item?.price} 
                                </td>
                                <td className="text-center">
                                {parseInt(item?.qty) * parseFloat(item?.price)}
                                </td>
                                {
                                  kitchenOrderData.length > 0 && <td className="text-center">
                                    {
                                      (item?.status === "DELIVERED" ? (
                                        <CheckCircleIcon />
                                      ) : (
                                        <RoleBasedGuard
                                          resource={RESOURCE.KITCHEN_QUEUE}
                                          action={ACTION.UPDATE}
                                        >
                                          <Button
                                            onClick={() =>
                                              handleKitchanOrderItemCompleted(
                                                item.id,
                                              )
                                            }
                                            variant="outline-info"
                                            className="sf-button-icon sf-button-icon-md"
                                          >
                                            {isKitchenOrderLoading?.oneCompleted &&
                                              selectedItem === item.id ? (
                                              <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                              />
                                            ) : (
                                              <CheckCircleIcon />
                                            )}
                                          </Button>
                                        </RoleBasedGuard>
                                      ))
                                    }

                                  </td>
                                }

                              </tr>
                            ))}
                        </tbody>

                      </Table>
                      <hr />
                      {/* <div className={classes.billDetail}>
                      <div className={classes.listitem}>
                          <b>{t("order-history-payment-type-label")} </b>
                          <div className={classes.item}>
                          {orderData?.bill_type || "-"}
                          </div>
                      </div>
                      <div className={classes.listitem}>
                          <b>{t("sub-total-title")} </b>
                          <div className={classes.item}>₹ {orderData?.sub_total}</div>
                      </div>
                      <div className={classes.listitem}>
                          <b>{t("discount-title")} </b>
                          <div className={classes.item}>
                          {orderData?.discount_sign === "INR" ? "₹ " : "% "}
                          {orderData?.discount === null ? "0" : orderData?.discount}
                          </div>
                      </div>
                      <div className={classes.listitem}>
                          <b>{t("tax-title")} </b>
                          <div className={classes.item}>₹ {orderData?.tax}</div>
                      </div>
                      <div className={classes.listitem}>
                          <b>{t("order-history-total-bill-label")} </b>
                          <div className={classes.item}>₹ {orderData?.total_bill}</div>
                      </div>
                      </div> DHARMIK*/}


                      <TotalBillSettlementBox
                        subTotal={subTotal}
                        values={values}
                        taxAmountPercentage={taxAmountPercentage}
                        taxAmountValue={taxAmountValue}
                        calculatedDiscount={calculatedDiscount}
                        discountValue={discountValue}
                        setDiscountValue={setDiscountValue}
                        finalBill={finalBill}
                        setFinalBill={setFinalBill}
                        discountSignValue={discountSignValue}
                        setDiscountSignValue={setDiscountSignValue}
                        parcelField={parcelField}
                        setParcelField={setParcelField}
                        taxValue={taxValue}
                        setTaxValue={setTaxValue}
                        additionalCharge={additionalCharge}
                        setAdditionalCharge={setAdditionalCharge}
                      />

                      <Modal.Footer style={{
                        justifyContent: "center",
                        position: "sticky",
                        bottom: 0,
                        padding: "0!important",
                        background: "#fff"
                      }}>
                        <Stack gap={1} direction={'row'} flexWrap="wrap" >
                          <RoleBasedGuard
                            resource={RESOURCE.ORDER_DELETE_BTN}
                            action={ACTION.VIEW}
                          >
                            <MuiButton
                              variant="contained"
                              color="error"
                              onClick={() => deleteOrder(selectedOrderId)}
                            >
                              Delete Order
                            </MuiButton>
                          </RoleBasedGuard>
                          <MuiButton
                            variant="contained"
                            color="info"
                            type="submit"
                          >
                            save
                          </MuiButton>
                          {/* PRINTER BUTTON START HERE */}
                          <RoleBasedGuard
                            resource={RESOURCE.ORDER_PRINT_BILL_BTN}
                            action={ACTION.VIEW}
                          >
                            <MuiButton
                              variant="contained"
                              color="primary"
                              onClick={() => saveAndPrintOrder(selectedOrderId, ORDER_DETAILS_BUTTON.BILL_ORDER_BUTTON, values)}
                            >
                              Bill
                            </MuiButton>
                          </RoleBasedGuard>
                          <RoleBasedGuard
                            resource={RESOURCE.ORDER_PRINT_KOT_BTN}
                            action={ACTION.VIEW}
                          >
                            <MuiButton
                              variant="contained"
                              color="secondary"
                              onClick={() => saveAndPrintOrder(selectedOrderId, ORDER_DETAILS_BUTTON.KOT_ORDER_BUTTON, values)}
                            >
                              KOT
                            </MuiButton>
                          </RoleBasedGuard>
                          <RoleBasedGuard
                            resource={RESOURCE.ORDER_SETTLE_BTN}
                            action={ACTION.VIEW}
                          >
                            <MuiButton
                              variant="contained"
                              color="info"
                              disabled={orderData?.is_complete ? true : false}
                              onClick={() => saveAndPrintOrder(selectedOrderId, ORDER_DETAILS_BUTTON.FINISH_ORDER_WITHOUT_PRINT, values)}
                            >
                              Settle
                            </MuiButton>
                          </RoleBasedGuard>
                          <RoleBasedGuard
                            resource={RESOURCE.ORDER_SETTLE_AND_PRINT_BTN}
                            action={ACTION.VIEW}
                          >
                            <MuiButton
                              variant="contained"
                              color="warning"
                              disabled={orderData?.is_complete ? true : false}
                              onClick={() => saveAndPrintOrder(selectedOrderId, ORDER_DETAILS_BUTTON.SETTLE_ORDER_WITH_PRINT, values)}
                            >
                              Settle & Print
                            </MuiButton>
                          </RoleBasedGuard>
                          {/* PRINTER BUTTON END HERE */}
                        </Stack>
                      </Modal.Footer>
                    </form>
                  )
                }}
              />

            </div>
          </Modal.Body>
        </PageLoaderBlock>
      </StyledModal>
      {
        ReactDOM.createPortal(<div className="invoice-pos-print-div">
          <div ref={mobilePrintRef}>
            {
              getHtmlTemplate(mobilePrintImageDataFormat)
            }
          </div>
          <div ref={mobileKOTPrintRef}>
            {
              getKOTHtmlTemplate(mobilePrintImageDataFormat)
            }
          </div>
        </div>, document.getElementById("root"))

      }
    </>
  )
}
