import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Modal, Button, ListGroup, Form } from "react-bootstrap";
import { Add, Remove } from "@mui/icons-material";
import { TextField, IconButton, Typography } from "@mui/material";
import { toast } from "react-hot-toast";
import BlockUi from "react-block-ui";
import MultiValueInputSelect from "components/MultiValueInputSelect";

import { QuantityType, OrderDetailCommonMessage } from "common/constant";
import "react-block-ui/style.css";

import vegImg from "../../assets/img/vegetarian-food-symbol.png";
import nonVegImg from "../../assets/img/non-vegetarian-food-symbol.png";
import { getShopSettingFromKey } from "utility/helpers";
import { useTranslation } from "react-i18next";

function CustomVariantModel(props) {
  const {
    foodItemData,
    show,
    isEdit,
    currentFoodIndex,
    editableFoodItem,
    foodItemsArrayAdded,
    onHide,
    selectedFoodItemData,
  } = props;
  const [foodItemAddOnsArray, setFoodItemAddOnsArray] = useState([]);
  const [foodItemVariantArray, setFoodItemVariantArray] = useState([]);
  const [regularFoodItem, setRegularFoodItem] = useState({});
  const [selectedFoodAddOns, setSelectedFoodAddOns] = useState([]);
  const [selectedFoodVariant, setSelectedFoodVariant] = useState(null);
  const [selectedFoodQuantity, setSelectedFoodQuantity] = useState(1);
  const [calculateFoodPrice, setCalculatedFoodPrice] = useState(0);
  const foodItemOptions = useSelector((state) => state.api.food_meal_list);
  const [instructionNote, setInstructionNote] = useState("");
  const { t } = useTranslation();

  useEffect(() => {
    if (show) {
      if (foodItemData?.food_item_addons) {
        setFoodItemAddOnsArray(foodItemData?.food_item_addons);
        setFoodItemVariantArray(foodItemData?.food_variants);
        setRegularFoodItem(foodItemData);
      } else if (
        foodItemData?.food_item_id?.value ||
        foodItemData?.food_item_id
      ) {
        // SET DATA FOR EDIT OPTION
        let selectedFoodItemData = foodItemOptions?.find(
          (data) =>
            data?.id ===
            (foodItemData?.food_item_id?.value || foodItemData?.food_item_id),
        );
        if (selectedFoodItemData?.food_item_addons) {
          setFoodItemAddOnsArray(selectedFoodItemData?.food_item_addons);
          setFoodItemVariantArray(selectedFoodItemData?.food_variants);
          setRegularFoodItem(selectedFoodItemData);
        }
      }
    }
  }, [foodItemData, foodItemOptions, show]);

  useEffect(() => {
    if (Array.isArray(foodItemAddOnsArray) && foodItemAddOnsArray.length > 0) {
      const defaultSelectedAddOns = foodItemAddOnsArray.filter(
        (foodAddons) => foodAddons.is_default
      );
      setSelectedFoodAddOns(defaultSelectedAddOns);
    }
  }, [foodItemAddOnsArray, setSelectedFoodAddOns, show]);
  

  useEffect(() => {
    if (show) {
      if (foodItemData) {
        if (foodItemData?.food_variants?.length > 0) {
          const is_default_variant = foodItemData?.food_variants.filter(
            (f) => f.is_default === true,
          );
          if (is_default_variant && is_default_variant.length > 0) {
            setSelectedFoodVariant(is_default_variant[0].id);
          } else {
            setSelectedFoodVariant(foodItemData?.food_variants?.[0].id);
          }
        } else {
          setSelectedFoodVariant(0);
        }
      }
    } else {
      resetStateData();
    }
  }, [foodItemData, show]);

  useEffect(() => {
    getProductPrice();
  }, [selectedFoodAddOns, selectedFoodVariant, selectedFoodQuantity]);

  useEffect(() => {
    if (
      isEdit &&
      editableFoodItem &&
      Object.keys(editableFoodItem).length > 0
    ) {
      const { addons, varient_id, qty, instruction_note } = editableFoodItem;
      if (addons !== "") {
        setSelectedFoodAddOns(JSON.parse(addons));
      }
      setSelectedFoodVariant(varient_id);
      setSelectedFoodQuantity(parseInt(qty) || 1);
      setInstructionNote(instruction_note);
    }
  }, [isEdit, foodItemData, editableFoodItem]);

  const shopSettingListData = useSelector(
    (state) => state.shopSettingReducer.shopSettingData,
  );

  const isOnlyVegStore = () => {
    return shopSettingListData?.IS_SHOP_VEGETARIAN;
  };

  const commonVariantCheck = (checkedVariantData) => {
    const foundIndex = selectedFoodAddOns.findIndex(
      ({ id }) => id === checkedVariantData?.id,
    );
    if (foundIndex > -1) {
      setSelectedFoodAddOns((prevItems) =>
        prevItems.filter(({ id }) => id !== checkedVariantData.id),
      );
    } else {
      setSelectedFoodAddOns((prevItems) => [...prevItems, checkedVariantData]);
    }
  };

  const handleFoodCategory = (quantityType) => {
    if (QuantityType.INCREMENT === quantityType) {
      setSelectedFoodQuantity(parseInt(selectedFoodQuantity) + 1);
    } else if (QuantityType.DECREMENT === quantityType) {
      if (selectedFoodQuantity > 1) {
        setSelectedFoodQuantity(parseInt(selectedFoodQuantity) - 1);
      } else {
        toast.info(OrderDetailCommonMessage.MIN_QUANTITY);
      }
    }
  };

  const addFoodItemToCart = () => {
    if (selectedFoodVariant >= 0) {
      addFoodItem(
        foodItemData,
        selectedFoodVariant,
        selectedFoodAddOns,
        selectedFoodQuantity,
      );
      onHide();
    } else {
      toast.info(OrderDetailCommonMessage.MIN_ONE_FOODITEM_SELECTED);
    }
  };

  const resetStateData = () => {
    setCalculatedFoodPrice(0);
    setSelectedFoodQuantity(1);
    setSelectedFoodVariant(null);
    setSelectedFoodAddOns([]);
    setInstructionNote("");
  };

  const getProductPrice = () => {
    let addOns = selectedFoodAddOns;

    let selectedFoodItemPrice = 0;

    if (selectedFoodVariant > 0 && foodItemData?.food_variants?.length > 0) {
      const is_default_variant_price = foodItemData?.food_variants.filter(
        (f) => f.id === selectedFoodVariant,
      );
      if (is_default_variant_price && is_default_variant_price.length > 0) {
        selectedFoodItemPrice = is_default_variant_price[0].price;
      } else {
        selectedFoodItemPrice = foodItemData?.food_variants?.[0].price;
      }
    } else if (selectedFoodVariant <= 0) {
      // Original item price
      selectedFoodItemPrice = parseFloat(foodItemData?.price);
    }

    let sumOfGenaralVariantPrice = 0;
    if (addOns?.length > 0) {
      sumOfGenaralVariantPrice = addOns?.reduce((prev, curr) => {
        return prev + parseFloat(curr?.price);
      }, 0);
    }

    let totalFoodPrice =
      (parseFloat(selectedFoodItemPrice) +
        parseFloat(sumOfGenaralVariantPrice)) *
      selectedFoodQuantity;
    setCalculatedFoodPrice(totalFoodPrice);
  };

  const addFoodItem = (
    selectFood,
    foodVariant,
    selectedGeneralVariant,
    selectedFoodQuantity,
  ) => {
    const { price, food_variants, category, id, food_image, is_non_veg } =
      selectFood;
    let foodPrice = 0.0;

    if (
      parseFloat(calculateFoodPrice / (selectedFoodQuantity || 1)) ===
      parseFloat(foodVariant?.price || price)
    ) {
      foodPrice = parseFloat(foodVariant?.price || price);
    } else {
      foodPrice = parseFloat(calculateFoodPrice / (selectedFoodQuantity || 1));
    }
    let sumOfGenaralVariantPrice = 0;
    if (selectedGeneralVariant?.length > 0) {
      sumOfGenaralVariantPrice = selectedGeneralVariant?.reduce(
        (prev, curr) => {
          return prev + parseFloat(curr?.price);
        },
        0,
      );
    }

    let foodItemPrice = 0;
    if (food_variants?.length > 0) {
      const is_default_variant_price = foodItemData?.food_variants.filter(
        (f) => f.id === selectedFoodVariant,
      );
      if (is_default_variant_price && is_default_variant_price.length > 0) {
        foodItemPrice = parseFloat(is_default_variant_price[0].price);
      } else {
        foodItemPrice = parseFloat(foodItemData?.food_variants?.[0].price);
      }
    } else {
      foodItemPrice = parseFloat(price);
    }

    const formatFoodItem = {
      fooditem: selectFood,
      qty: selectedFoodQuantity || 1,
      category_id: category?.id,
      food_item_id: id,
      price: foodItemPrice,
      total_qty_price:
        parseFloat(foodItemPrice) * parseFloat(selectedFoodQuantity || 1),
      addon_price: parseFloat(sumOfGenaralVariantPrice),
      total_addon_qty_price:
        parseFloat(sumOfGenaralVariantPrice) *
        parseFloat(selectedFoodQuantity || 1),
      sub_total: calculateFoodPrice,
      food_image: food_image,
      varient_id: selectedFoodVariant || 0,
      is_non_veg: is_non_veg || false,
      instruction_note: instructionNote || "",
      addons:
        (selectedGeneralVariant.length > 0 &&
          JSON.stringify(selectedGeneralVariant)) ||
        "",
    };

    /* if (currentFoodIndex === undefined) {
      let isAvailableOnfoodItemData = false;
      if (
        formatFoodItem.varient_id > 0 &&
        selectedGeneralVariant &&
        selectedGeneralVariant.length > 0
      ) {
        // TODO variants
        console.log(
          "JSON.stringify(selectedGeneralVariant)",
          JSON.stringify(selectedGeneralVariant),
        );
        isAvailableOnfoodItemData = selectedFoodItemData.some(
          (item) =>
            item?.varient_id === formatFoodItem.varient_id &&
            (item?.addons === JSON.stringify(selectedGeneralVariant) || "") &&
            item?.food_item_id === formatFoodItem?.food_item_id,
        );
      } else if (selectedGeneralVariant && selectedGeneralVariant.length > 0) {
        isAvailableOnfoodItemData = selectedFoodItemData.some(
          (item) =>
            (item?.addons === JSON.stringify(selectedGeneralVariant) || "") &&
            item?.food_item_id === formatFoodItem?.food_item_id,
        );
      } else if (formatFoodItem.varient_id > 0) {
        isAvailableOnfoodItemData = selectedFoodItemData.some(
          (item) =>
            item?.varient_id &&
            item?.varient_id === formatFoodItem.varient_id &&
            item?.food_item_id === formatFoodItem?.food_item_id,
        );
      } else {
        isAvailableOnfoodItemData = selectedFoodItemData.some(
          (item) => item?.food_item_id === formatFoodItem?.food_item_id,
        );
      }
      if (isAvailableOnfoodItemData) {
        toast.warning("The Food Item is already in your Order List.");
        return true;
      }
    } */
    foodItemsArrayAdded(formatFoodItem, currentFoodIndex);
    resetStateData();
  };

  return (
    <div>
      <Modal
        backdrop="static"
        keyboard={false}
        {...props}
        size={props?.size ? props?.size : "md"}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title as={"h5"} id="contained-modal-title-vcenter">
            <div>
              {foodItemData?.name ||
                foodItemData?.food_item_id?.label ||
                foodItemData?.fooditem?.name}
              <span style={{ fontSize: "13px", marginLeft: "5px" }}>
                (
                {foodItemData?.category?.name ||
                  foodItemData?.category_id?.label ||
                  (foodItemData?.fooditem?.category?.name &&
                    `(${foodItemData?.category?.name ||
                    foodItemData?.category_id?.label ||
                    foodItemData?.fooditem?.category?.name
                    })`)}
                )
              </span>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ textAlign: "center" }}>
          {/* Customizable Food Variants */}
          <BlockUi
            tag="div"
            // blocking={isEdit}
            loader={() => {
              return false;
            }}
            renderChildren={true}
          >
            <ListGroup
              className="multiselect-list overflow-auto mt-20 mb-20 ml-0 mr-0"
              style={{
                padding: "4px 12px 12px 12px",
                backgroundColor: "aliceblue",
              }}
            >
              <div className="h5">
                <span style={{ fontSize: "14px" }}>
                  <b>{t("custom-variant-model-food-variants-title")}</b>
                </span>
              </div>
              {/* FOR FOOD VARIANT SECTION HERE */}
              <div key={regularFoodItem?.id}>
                {/* FOR REGULAR FOOD ITEM DISPLAY HERE */}
                {/* {foodItemVariantArray?.length <= 0 && ( */}
                <Form.Label className="d-flex align-items-center">
                  <div
                    style={{
                      flex: "1 0 auto",
                      textAlign: "left",
                      fontSize: "14px",
                    }}
                  >
                    {t("regular-label")}
                  </div>
                  <div className="d-flex align-items-center">
                    <span className="mr-2">
                      {" "}
                      {shopSettingListData?.CURRENCY_CODE} {parseFloat(regularFoodItem?.price)}
                    </span>
                    <input
                      type="radio"
                      className="sf-custom-radio"
                      checked={
                        selectedFoodVariant !== null && selectedFoodVariant <= 0
                          ? true
                          : false
                      }
                      onChange={() => setSelectedFoodVariant(0)}
                    />
                  </div>
                </Form.Label>
              </div>
              {foodItemVariantArray?.length > 0 &&
                foodItemVariantArray?.map((foodVariant, index) => (
                  <>
                    {foodVariant.is_active && (
                      <div key={foodVariant?.name + foodVariant?.id}>
                        <Form.Label className="d-flex align-items-center cursor-pointer">
                          <div
                            style={{
                              flex: "1 0 auto",
                              textAlign: "left",
                              fontSize: "14px",
                            }}
                          >
                            {foodVariant?.name}
                          </div>
                          <div className="d-flex align-items-center">
                            <span className="mr-2">
                              {" "}
                              {shopSettingListData?.CURRENCY_CODE} {foodVariant?.price}
                            </span>
                            <input
                              type="radio"
                              className="sf-custom-radio"
                              checked={selectedFoodVariant === foodVariant?.id}
                              onChange={() =>
                                setSelectedFoodVariant(foodVariant?.id)
                              }
                            />
                          </div>
                        </Form.Label>
                      </div>
                    )}
                  </>
                ))}
            </ListGroup>

            {/* {foodItemVariantArray?.length > 0 && (
              <div className="text-right">
                <Button
                  variant="link"
                  className="clear-food-item"
                  onClick={() => setSelectedFoodVariant(null)}
                >
                  clear
                </Button>
              </div>
            )} */}

            <hr style={{ marginTop: 10, marginBottom: 10 }} />
            <div className="h5">
              <span style={{ fontSize: "14px" }}>
                <b>{t("custom-variant-model-popup-message")}</b>
              </span>
            </div>
            {foodItemAddOnsArray &&
              foodItemAddOnsArray.length > 0 &&
              foodItemAddOnsArray.filter((food) => food.is_active === true)
                .length === 0 && (
                <Typography style={{ fontWeight: 400, fontSize: 13 }}>
                  {t("no-data-found-message")}
                </Typography>
              )}
            {foodItemAddOnsArray && foodItemAddOnsArray?.length > 0 ? (
              <ListGroup className="multiselect-list overflow-auto mt-20 mb-20 ml-0 mr-0">
                {foodItemAddOnsArray?.map((variantData, index) => (
                  <>
                    {variantData?.is_active && (
                      <div key={variantData.name + variantData.id}>
                        <Form.Label className="pt-2 d-flex align-items-center cursor-pointer">
                          <div style={{ flex: "1 0 auto", textAlign: "left" }}>
                            {!isOnlyVegStore() &&
                              (variantData?.is_non_veg ? (
                                <img
                                  src={nonVegImg}
                                  style={{
                                    width: 20,
                                    objectFit: "contain",
                                    marginRight: 5,
                                  }}
                                  alt="non veg"
                                />
                              ) : (
                                <img
                                  src={vegImg}
                                  style={{
                                    width: 20,
                                    objectFit: "contain",
                                    marginRight: 5,
                                  }}
                                  alt="veg"
                                />
                              ))}
                            {variantData?.name}
                          </div>
                          <div className="d-flex align-items-center">
                            <span className="mr-2"> {shopSettingListData?.CURRENCY_CODE}{variantData?.price}</span>
                            <input
                              type="checkbox"
                              className="sf-custom-checkbox"
                              checked={selectedFoodAddOns?.some(
                                ({ id }) => id === variantData?.id,
                              )}
                              onChange={() => commonVariantCheck(variantData)}
                            />
                          </div>
                        </Form.Label>
                      </div>
                    )}
                  </>
                ))}
              </ListGroup>
            ) : (
              <Typography style={{ fontWeight: 400, fontSize: 13 }}>
                 {t("no-data-found-message")}
              </Typography>
            )}
          </BlockUi>
          <hr style={{ marginTop: 10, marginBottom: 10 }} />
          {/* <TextField
            style={{ width: "100%" }}
            id="instruction-note"
            label="Order Note (Special instruction)"
            value={instructionNote}
            onChange={(event) => setInstructionNote(event?.target?.value)}
            placeHolder="eg. spicy, medium, extra cheezze..."
            variant="outlined"
          /> */}
          <MultiValueInputSelect
             label={t("custom-variant-model-order-note-title")}
            initialValue={instructionNote}
            onChange={setInstructionNote}
            placeHolder={t("order-detail-example-label")}
            />
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex align-items-center justify-content-between w-100">
              <div className="d-flex align-items-center sf-variant-footer-cart">
                <IconButton
                  aria-label={t("remove-label")}
                  size="small"
                  onClick={() => handleFoodCategory(QuantityType.DECREMENT)}
                >
                  <Remove fontSize="inherit" />
                </IconButton>
                <span className="food-qty">{selectedFoodQuantity}</span>
                <IconButton
                  aria-label={t("add-button-label")}
                  size="small"
                  onClick={() => handleFoodCategory(QuantityType.INCREMENT)}
                >
                  <Add fontSize="inherit" />
                </IconButton>
              </div>
              <div>
              <Button variant="outline-primary" onClick={() => addFoodItemToCart()}>
                {isEdit ? t("custom-variant-model-save-order-label") : t("custom-variant-model-add-to-order-label")} - {calculateFoodPrice?.toFixed(2)}
              </Button>
              </div>
              {/* <div>
              <Button variant="outline-primary" onClick={() => onHide()}>
                Close
              </Button>
              </div> */}
            
             
          </div>
         
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default CustomVariantModel;
