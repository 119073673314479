// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Container } from '@mui/material';
import { _analyticPost, _analyticOrderTimeline, _analyticTraffic } from '../../_mock';
import { useSelector } from 'react-redux';
// components
import Page from '../../components/Page';
import { CHART_COLORS } from '../../configV2'

// sections
import {
  AnalyticsCurrentVisits,
  AnalyticsWebsiteVisits,
  AnalyticsWidgetSummary,
  AnalyticsConversionRates,
} from '../../sections/@dashboard/general/analytics';
import { useOrderAnalytics } from '../../hooks/react-query/useReport';
import AnalyticsWebsiteVisitsNew from '../../sections/@dashboard/general/analytics/AnalyticsWebsiteVisitsNew';

import { useInMobile } from "themes/Device";
import PageLoader from "components/Loader/PageLoader";
import moment from 'moment';
import { useEffect, useState } from 'react';
import SelectionToolBar from './SelectionToolBar';
export default function OrderAnalytics() {
  const theme = useTheme();
  const [selectedDate, setSelectedDate] = useState(moment().format("YYYY-MM-DD"));
  const [month, setMonth] = useState(moment().format('M'));
  const [year, setYear] = useState(moment().format('YYYY'));
  const shopSettingData = useSelector((state) => state.shopSettingReducer.shopSettingData);

  useEffect(() => {
    if (month && year) {
      setSelectedDate(year + "-" + month + "-" + 1)
    }
  }, [year, month])
  const { data, isLoading } = useOrderAnalytics(selectedDate)
  const themeStretch = true;
  const isMobileView = useInMobile();
  return (
    <Page title="Order Analytics">
      {!isMobileView ? <hr /> : <br />}
      {!isLoading ?
        <Container maxWidth={themeStretch ? false : 'xl'}>
          <Grid container spacing={3}>
          <Grid item xs={12} ml={'auto'}>
              <SelectionToolBar
                  month={month}
                  year={year}
                  setMonth={setMonth}
                  setYear={setYear}
                ></SelectionToolBar>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <AnalyticsWidgetSummary shopSettingData={shopSettingData} title="Total Orders" total={data?.tableData?.filter((d) => d.type == "DINE_IN")[0]?.sum} icon={'fa6-solid:table-tennis-paddle-ball'} />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <AnalyticsWidgetSummary
                title="Total SWIGGY Order" shopSettingData={shopSettingData} total={data?.tableData?.filter((d) => d.type == "SWIGGY")[0]?.sum} color="info" icon={'simple-icons:swiggy'} />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <AnalyticsWidgetSummary
                shopSettingData={shopSettingData}
                title="Total Zomato Order"
                total={data?.tableData?.filter((d) => d.type == "ZOMATO")[0]?.sum}
                color="warning"
                icon={'arcticons:zomato'}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <AnalyticsWidgetSummary shopSettingData={shopSettingData} title="Total Parcel Order" total={data?.tableData?.filter((d) => d.type == "PARCEL")[0]?.sum} color="error" icon={'fa6-solid:bowl-food'} />
            </Grid>

            <Grid item xs={12} md={6} lg={8}>
              <AnalyticsWebsiteVisits
                title="Every Day Order Count"
                subheader="Day wise Order Count"
                chartLabels={[
                  ...data?.monthOrderTimeSeries?.date
                ]}
                chartData={[
                  {
                    name: 'Total Order',
                    type: 'area',
                    fill: 'gradient',
                    data: [...data?.monthOrderTimeSeries?.count],
                  },
                  {
                    name: 'Dinin Order',
                    type: 'area',
                    fill: 'gradient',
                    data: [...data?.dininTimeSeries?.count],
                  },
                  {
                    name: 'Parcel Order',
                    type: 'line',
                    fill: 'solid',
                    data: [...data?.parcelTimeSeries?.count],
                  },
                ]}
              />
            </Grid>

            <Grid item xs={12} md={6} lg={4}>
              <AnalyticsCurrentVisits
                shopSettingData={shopSettingData}

                title="Order Type Wise Statistics"
                chartData={[
                  { label: 'Dine In', value: data?.tableData?.filter((d) => d.type == "DINE_IN")[0]?.sum },
                  { label: 'SWIGGY', value: data?.tableData?.filter((d) => d.type == "SWIGGY")[0]?.sum },
                  { label: 'ZOMATO', value: data?.tableData?.filter((d) => d.type == "ZOMATO")[0]?.sum },
                  { label: 'ONLINE', value: data?.tableData?.filter((d) => d.type == "ONLINE")[0]?.sum },
                ]}
                chartColors={[
                  theme.palette.primary.main,
                  CHART_COLORS.blue[0],
                  CHART_COLORS.violet[0],
                  CHART_COLORS.yellow[0],
                ]}
              />
            </Grid>

            <Grid item xs={12} md={6} lg={6}>
              <AnalyticsWebsiteVisitsNew
                title="Top Order"
                subheader="Top Order Based on Amount"
                chartLabels={
                  [...data?.topOrder?.map((e) => `${e.order_id}`)]
                }
                chartData={data?.topOrder}
              />
            </Grid>

            <Grid item xs={12} md={6} lg={6}>
              <AnalyticsConversionRates
                title="Top Category"
                subheader="Top Category Based on Count"
                chartData={[...data?.categroryData?.map((o) => {
                  return {
                    label: `${o.name}`,
                    value: parseFloat(o.sum)
                  }
                })]}
              />
            </Grid>
          </Grid>
        </Container>
        : <>
          <PageLoader></PageLoader>
        </>}

    </Page>
  );
}
