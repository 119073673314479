import React, { Fragment, useCallback, useEffect, useState } from "react";
import { styled } from '@mui/material/styles';
import { useHistory } from "react-router-dom";
import moment from "moment";
import { toast } from "react-hot-toast";
import { Button, CircularProgress, IconButton, TablePagination } from "@mui/material";
import MaterialTable, { MTableToolbar } from "material-table";
import {
  AddCircleOutline as AddCircleOutlineIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Group,
  Cancel,
  Delete,
  DeleteForever,
} from "@mui/icons-material";
import SkeletonLoader from "components/Loader/SkeletonLoader";
import AddEditCustomer from "./AddEditCustomer";
import { Link as RouterLink } from "react-router-dom";
import PhoneIcon from "@mui/icons-material/Phone";
import { useSelector } from "react-redux";

//context
import {
  useLayoutState,
  useLayoutDispatch,
  addCurrentScreenTitle,
} from "context/LayoutContext";

// API Calls
import { getAllCustomer, deleteCustomer, deleteCustomerbulk } from "services/customers.service";
import NotFoundComponent from "components/NotFoundComponent";
import noFoundImg from "../../../assets/img/no-data-found.jpg";

import TemporaryDrawer from "components/TemporaryDrawer";
import { DeskTopContainer } from "themes/Device";
import { MobileContainer } from "themes/Device";
import MobileCustomerAccordian from "components/MobileAccordian/MobileCustomerAccordian";
import SearchBarInput from "components/SearchBarInput";
import MobileTitle from "components/MobileTitle";
import RoleBasedGuard from "guards/RoleBasedGuard";
import { RESOURCE } from "common/constant";
import { ACTION } from "common/constant";
import PopUpModel from "components/Popup/PopUpModel";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import CheckPermission from "guards/CheckPermission";

const PREFIX = 'CustomerList';

const classes = {
  cardCategoryWhite: `${PREFIX}-cardCategoryWhite`,
  cardTitleWhite: `${PREFIX}-cardTitleWhite`,
  root: `${PREFIX}-root`,
  tabel: `${PREFIX}-tabel`,
  mobileCard: `${PREFIX}-mobileCard`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.cardCategoryWhite}`]: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },

  [`& .${classes.cardTitleWhite}`]: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    // fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontFamily: '"Oxygen", sans-serif',
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },

  [`& .${classes.root}`]: {
    width: "100%",
  },

  [`& .${classes.tabel}`]: {
    margin: "10px",
    marginTop: "0",
    "& .MuiPaper-root": {
      padding: 10,
      paddingBottom: 0,
    },
    "& tr": {
      height: 50,
      "& .MuiTableCell-root": {
        paddingLeft: "15px",
        fontSize: "14px !important",
      },
    },
  },

  [`& .${classes.mobileCard}`]: {
    padding: "2px",
    paddingBottom: '80px'
  }
}));

const CustomerList = () => {

  const theme = useTheme();
  const history = useHistory();
  const [deletePopupshow, setDeletePopupshow] = useState(false);
  const [deleteId, setDeleteId] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [customersDataList, setCustomersDataList] = useState([]);
  const [selectedCustomerList, setSelectedCustomerList] = useState([]);
  const shopSettingListData = useSelector((state) => state.shopSettingReducer.shopSettingData);

  const [drawerShow, setDrawerShow] = useState(false);
  const [customerId, setCustomerId] = useState(0);
  const [searchCustomer, setSearchCustomer] = useState("");
  const [filterCustomerList, setFilterCustomerList] = useState([]);
  const layoutState = useLayoutState();
  const layoutDispatch = useLayoutDispatch();
  const [paginationParam, setPaginationParam] = useState({
    type: 'DESC',
    search: '',
    page: 1,
    limit: 20,
    column: 'id'
  })
  const [customerPagerData, setCustomerPagerData] = useState({});
  const { t } = useTranslation();


  useEffect(() => {
    setHeader()
  }, [layoutState])

  const setHeader = useCallback(() => {
    if (layoutState?.currentScreenTitle !== "Customers") {
      addCurrentScreenTitle(layoutDispatch, {
        currentScreenTitle: t("customer-screen-title"),
      });
    }
    return () => {
      // if (layoutState?.currentScreenTitle === "Customers") {
      //   addCurrentScreenTitle(layoutDispatch, { currentScreenTitle: "" });
      // }
    };
  }, [layoutState, layoutDispatch])
  
  
  useEffect(() => {
    customerList(paginationParam);
  }, [paginationParam])
  

  const customerList = (paginationParam) => {
    setIsLoading(true);
    getAllCustomer(paginationParam)
      .then(function (response) {
        setIsLoading(false);
        setCustomerPagerData(response?.pager);
        setCustomersDataList(response?.data);
      })
      .catch(function (error) {
        setIsLoading(false);
        console.log(error);
      });
  };

  const deleteCustomerData = () => {
    if (deleteId > 0) {
      setIsDeleting(true);
      deleteCustomer(deleteId)
        .then((res) => {
          setIsDeleting(false);
          setDeletePopupshow(false);
          if (res?.data) {
            customerList();
            toast.success(res?.message);
          } else {
            toast.error(res?.message);
          }
        })
        .catch((err) => {
          setIsDeleting(false);
          setDeletePopupshow(false);
          toast.error(err?.message);
        });
    }
  };

  const deleteDataConfirm = (id) => {
    setDeleteId(id);
    setDeletePopupshow(true);
  };

  const selectedCustomerListFun = (data) => {
    setSelectedCustomerList([...data]);
  };

  const drawerClose = () => {
    setDrawerShow(false);
    setCustomerId(0);
  };

  const deleteData = (deleteid) => {
    setDeleteId(deleteid);
    setDeletePopupshow(true);
  };

  const editData = (editid) => {
    // setCustomerId(editid);
    // setDrawerShow(true);
    history.push({
      pathname: `/app/customer/edit/${editid}`,
      state: {
        customerId: editid,
        backToMain: () => history.push(`/app/customers`),
      },
    });
  };

  const addDrawerShow = () => {
    // setDrawerShow(true);
    history.push({
      pathname: `/app/customer/add`,
      state: {
        customerId: 0,
        backToMain: () => history.push(`/app/customers`),
      },
    });
  };

  const clearTxt = () => {
    setSearchCustomer("");
  };

  const sortFunction = (a, b) => {
    var dateA = new Date(a.date).getTime();
    var dateB = new Date(b.date).getTime();
    return dateA > dateB ? 1 : -1;
  };
  useEffect(() => {
    const data = customersDataList.sort((a, b) => new Date(b.updated_date) - new Date(a.updated_date));
    setFilterCustomerList(data);
  }, [customersDataList]);

  useEffect(() => {
    if (searchCustomer) {
      let filterCustomer = customersDataList.filter(
        (item) =>
          item.name &&
          item.name?.toLowerCase()?.includes(searchCustomer.toLowerCase()),
      );
      setFilterCustomerList(filterCustomer);
    } else {
      setFilterCustomerList(customersDataList);
    }
  }, [searchCustomer]);
  const [bulkDeleteIds, setbulkDeleteIds] = useState([]);
  const [deletebulkPopupshow, setDeletebulkPopupshow] = useState(false);
  const handleDeleteRows = async () => {
    setDeletebulkPopupshow(false)
    setbulkDeleteIds([]);
    const result = await deleteCustomerbulk({
      ids: bulkDeleteIds
    });
    if (result?.data?.status === 200) {
      toast.success(result?.data?.message);
    } else {
      toast.error(result?.message);
    }
    customerList();
  };
  const handleDeleteRowsConformation = async (event, rowData) => {
    const ids = rowData.map((d) => d.id);
    setDeletebulkPopupshow(true)
    setbulkDeleteIds(ids);
  };
  return (
    <Root>
      {/* <DeleteConfirmPopup
        deletePopupshow={deletePopupshow}
        handleClose={() => setDeletePopupshow(false)}
        title="Customer Remove"
        isDeletingFlag={isDeleting}
        content="Are you sure to remove this customer ?"
        deleteAction={deleteCustomerData}
      /> */}
      <PopUpModel
        show={deletePopupshow}
        heading={t("customer-remove-popup-title")}
        body={t("customer-remove-popup-body")}
        footer={
          <>
            <Button
              variant="contained"
              color="primary"
              className="mr-3"
              onClick={() => setDeletePopupshow(false)}
            >
              {" "}
              <Cancel /> {t("popup-cancel-button-label")}
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={deleteCustomerData}
            >
              {" "}
              <Delete /> {isDeleting ? <CircularProgress /> : t("delete")}{" "}
            </Button>
          </>
        }
        onHide={() => setDeletePopupshow(false)}
      />
      <PopUpModel
        show={deletebulkPopupshow}
        heading={"Are you sure you want to delete selected customers?"}
        body={"Please Conform Selected Opration."}
        footer={
          <>
            <Button
              variant="contained"
              color="primary"
              className="mr-3"
              onClick={() => setDeletebulkPopupshow(false)}
            >
              {" "}
              <Cancel /> {t("popup-cancel-button-label")}
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleDeleteRows}
            >
              {" "}
              <Delete /> {isDeleting ? <CircularProgress /> : t("delete")}{" "}
            </Button>
          </>
        }
        onHide={() => setDeletebulkPopupshow(false)}
      />
      <TemporaryDrawer
        isOpen={drawerShow}
        drawerClose={drawerClose}
        icon={<Group />}
        title={t("customer-screen-title")}
      >
        <AddEditCustomer
          customerId={customerId}
          drawerClose={drawerClose}
          customerList={customerList}
        />
      </TemporaryDrawer>
      <DeskTopContainer>
        <div className={classes.tabel}>
          <MaterialTable
            title={
              <h5>
                <b className="ml-3">{t("customer-screen-title")}</b>
              </h5>
            }
            columns={[
              {
                title: t("customer-name-label"),
                field: "customerName",
                cellStyle: {
                  width: "15%",
                },
                render: (params) => {
                  return (
                    <>
                      <RouterLink
                        to={`/app/customers/history/${params.id}`}
                        state={{ from: params.id }}
                      >
                        {params.customerName}
                      </RouterLink>
                    </>
                  );
                },
              },
              {
                title: t("phone-label"),
                field: "phone",
                cellStyle: {
                  width: "15%",
                },
                render: (rowData) => {
                  return (
                    <>
                      <PhoneIcon color="success" />
                      <>{rowData?.phone}</>
                    </>
                  );
                },
              },
              {
                title: t("email-label"),
                field: "email",
                cellStyle: {
                  width: "20%",
                },
              },
              {
                title: t("credit-label"),
                field: "credit",
                cellStyle: {
                  width: "10%",
                },
                render: (rowData) => {
                  if (
                    rowData?.credit !== null &&
                    rowData?.credit !== undefined
                  ) {
                    return `${shopSettingListData?.CURRENCY_CODE}  ${rowData?.credit}`;
                  } else {
                    return "";
                  }
                },
              },
              {
                title: t("debt-label"),
                field: "debt",
                cellStyle: {
                  width: "10%",
                },
                render: (rowData) => {
                  if (rowData?.debt !== null && rowData?.debt !== undefined) {
                    return `${shopSettingListData?.CURRENCY_CODE} ${rowData?.debt}`;
                  } else {
                    return "";
                  }
                },
              },
              {
                title: t("visit-date-label"),
                field: "active",
                cellStyle: {
                  width: "15%",
                },
                render: (rowData) =>
                  rowData?.visitDate &&
                  moment(rowData?.visitDate).format("DD/MM/YYYY hh:mm"),
              },
              {
                title: t("action"),
                field: "action",
                cellStyle: {
                  width: "10%",
                },
                render: (rowData) => (
                  <Fragment>
                    <RoleBasedGuard
                      resource={RESOURCE.CUSTOMERS}
                      action={ACTION.UPDATE}
                    >
                      <IconButton
                        color="primary"
                        title={t("customer-edit-label")}
                        aria-label={t("customer-edit-label")}
                        onClick={() => {
                          setCustomerId(rowData.id);
                          setDrawerShow(true);
                        }}
                        size="large">
                        <EditIcon />
                      </IconButton>
                    </RoleBasedGuard>
                    <RoleBasedGuard
                      resource={RESOURCE.CUSTOMERS}
                      action={ACTION.DELETE}
                    >
                      <IconButton
                        color="secondary"
                        title={t("customer-delete-label")}
                        aria-label={t("customer-delete-label")}
                        onClick={() => deleteDataConfirm(rowData.id)}
                        size="large">
                        <DeleteIcon />
                      </IconButton>
                    </RoleBasedGuard>
                  </Fragment>
                ),
              },
            ]}
            data={
              customersDataList &&
              customersDataList.map((item, index) => {
                return {
                  customerName: item?.name,
                  phone: item?.mobile,
                  email: item?.email,
                  active: item?.is_active,
                  id: item?.id,
                  visitDate: item?.updated_date,
                  credit: item?.credit,
                  debt: item?.dept,
                };
              })
            }
            options={{
              paging: false,
              pageSize: 10,
              emptyRowsWhenPaging: false,
              pageSizeOptions: [10, 20, 30],
              sorting: true,
              selection: CheckPermission(RESOURCE.CUSTOMERS, ACTION.DELETE) ? true : false,
              maxBodyHeight: 'calc(100vh - 240px)',
              actionsColumnIndex: -1,
              searchFieldAlignment: "left",
              searchFieldStyle: { color: theme.palette.grey[500] },
              headerStyle: {
                color: theme.palette.grey[600],
                backgroundColor: theme.palette.grey[200],
              }
            }}
            actions={
              CheckPermission(RESOURCE.CUSTOMERS, ACTION.DELETE) ? [
                {
                  icon: () => <DeleteForever />,
                  tooltip: "Delete Rows",
                  onClick: handleDeleteRowsConformation
                }
              ] : []
            }
            localization={{
              toolbar: {
                searchPlaceholder: t("search"),
              },
              body: {
                emptyDataSourceMessage: isLoading ? (
                  <SkeletonLoader />
                ) : (
                  <NotFoundComponent imgUrl={noFoundImg}>
                    <p style={{ fontWeight: 600 }}>
                      {t("record-not-found-message")}
                    </p>
                  </NotFoundComponent>
                ),
              },
            }}
            // other props
            components={{
              Toolbar: (props) => (
                <div className="custom-material-table-toolbar ">
                  <MTableToolbar {...props} />
                  <div style={{ padding: "0px 10px" }}>
                    <RoleBasedGuard
                      resource={RESOURCE.CUSTOMERS}
                      action={ACTION.ADD}
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        startIcon={<AddCircleOutlineIcon />}
                        style={{ background: "", color: "#fff" }}
                        onClick={() => {
                          setDrawerShow(true);
                        }}
                      >
                        {t("customer-add-label")}
                      </Button>
                    </RoleBasedGuard>
                  </div>
                </div>
              ),
            }}
          />
        </div>
        <TablePagination
              rowsPerPageOptions={[10, 20, 30, 50]}
              component="div"
              count={parseInt(customerPagerData?.total || 1)}
              rowsPerPage={parseInt(customerPagerData?.take || 20)}
              page={parseInt(customerPagerData?.page - 1)}
              onPageChange={(event, newPage) => {
                setPaginationParam({
                  ...paginationParam,
                  page: newPage +1
                })
              }}
              onRowsPerPageChange={(event) => {
                setPaginationParam({
                  ...paginationParam,
                  limit: event.target.value
                })
              }}
            />
      </DeskTopContainer>
      <MobileContainer>
        <div className={classes.mobileCard}>
          <MobileTitle
            resource={RESOURCE.CUSTOMERS}
            title={t("customer-screen-title")}
            addButton={addDrawerShow}
          />
          <SearchBarInput
            onChange={(e) => {
              setSearchCustomer(e.target.value);
            }}
            value={searchCustomer}
            placeholder={t("customer-search-label")}
            clearTxt={clearTxt}
          />
          {isLoading ? (
            <SkeletonLoader count={3} height="10rem" />
          ) : (
            <div>
              {filterCustomerList &&
                filterCustomerList.map((item, index) => (
                  <div key={index} style={{ marginTop: index === 0 ? 4 : 0, marginBottom: 6 }}>
                    <MobileCustomerAccordian
                      resource={RESOURCE.CUSTOMERS}
                      customerID={item?.id}
                      Phone={item?.mobile || "-"}
                      customerName={item?.name}
                      customerEmail={item?.email || "-"}
                      visitDate={moment(item?.visitDate).format(
                        "DD/MM/YYYY hh:mm",
                      )}
                      deleteData={deleteData}
                      editData={editData}
                      itemId={item?.id}
                    />
                  </div>
                ))}
            </div>
          )}
          {filterCustomerList.length < 1 && !isLoading && (
            <NotFoundComponent imgUrl={noFoundImg}>
              <p style={{ fontWeight: 600 }}>{t("record-not-found-message")}</p>
            </NotFoundComponent>
          )}
        </div>
      </MobileContainer>
    </Root>
  );
};

export default CustomerList;
